export const personChangeRequestWhitelist = [
  'academicDegree', 'consultationHour', 'email', 'firstName', 'lastName', 'phone', 'remark', 'title'
]

export const officeChangeRequestWhitelist = [
  'name', 'openingHours', 'email', 'phone'
]

export const studentUnionChangeRequestWhitelist = [
  'email', 'phone', 'name', 'url', 'urlTwo', 'meeting'
]

export const generalChangeRequestWhitelist = [
  'name', 'openingHours', 'email', 'phone', 'url'
]
