import { throwError as observableThrowError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Inject, Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

import { LoginService } from '../../login/shared/login.service';
import { StudentUnion } from './student-union';
import { HttpClient, HttpResponse } from '@angular/common/http';

@Injectable()
export class StudentUnionService {
  constructor(@Inject(HttpClient) private readonly http: HttpClient, private loginService: LoginService) {}

  getAllStudentUnions(): Observable<any> {
    return this.http.get<any>(environment.url + `/poi/studentunions`).pipe(catchError(this.handleError));
  }

  getStudentUnionById(id: number): Observable<StudentUnion> {
    return this.http.get<any>(environment.url + `/poi/studentunions/${id}`).pipe(catchError(this.handleError));
  }

  getStudentUnionByName(name: string) {
    return this.http.get<any>(environment.url + `/poi/studentunions/page?name=${name}`);
  }

  updateStudentUnion(studentUnion: StudentUnion, id: number) {
    const updateStudentUnion = {
      id: studentUnion.id,
      name: studentUnion.name,
      url: studentUnion.url,
      urlTwo: studentUnion.urlTwo,
      meeting: studentUnion.meeting,
      phone: studentUnion.phone,
      email: studentUnion.email,
      room: studentUnion.room
    };
    const httpOptions = {
      headers: this.loginService.getAuthorizationHeader()
    };
    return this.http
      .put<any>(environment.url + `/poi/studentunions/${id}`, JSON.stringify(updateStudentUnion), httpOptions)
      .pipe(catchError(this.handleError));
  }

  createStudentUnion(studentUnion: StudentUnion) {
    const newStudentUnion = {
      name: studentUnion.name,
      url: studentUnion.url,
      urlTwo: studentUnion.urlTwo,
      meeting: studentUnion.meeting,
      phone: studentUnion.phone,
      email: studentUnion.email,
      room: studentUnion.room
    };
    const httpOptions = {
      headers: this.loginService.getAuthorizationHeader()
    };
    return this.http
      .post<any>(environment.url + `/poi/studentunions`, JSON.stringify(newStudentUnion), httpOptions)
      .pipe(catchError(this.handleError));
  }

  deleteStudentUnionById(id: number) {
    const httpOptions = {
      headers: this.loginService.getAuthorizationHeader()
    };
    return this.http
      .delete<any>(environment.url + `/poi/studentunions/${id}`, httpOptions)
      .pipe(catchError(this.handleError));
  }

  private toStudentUnion(response: HttpResponse<any>) {
    //TODO: hier habe ich json() auf body geändert, da .json() nicht mehr Teil der neuen HttpResponse ist und scheinbar 
    // den Body zurückgegeben hat. Hier muss geprüft werden, ob sich die Funktion weiterhin richtig verhält!
    const jsonObject = response.body as StudentUnion;
    return StudentUnion.fromServer(jsonObject);
  }

  private handleError(error: any) {
    let errorMessage: string;
    errorMessage = error.message ? error.message : error.toString();
    return observableThrowError(errorMessage);
  }
}
