import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { LoginService } from 'app/login/shared/login.service';
import { environment } from 'environments/environment';
import { throwError as observableThrowError, Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { FacultyResponseDto } from '../model/faculty-response.dto';

@Injectable()
export class FacultyService {
  constructor(@Inject(HttpClient) private readonly http: HttpClient, private loginService: LoginService) {}

  public getAllFacultys(): Observable<FacultyResponseDto[]> {
    return this.http
      .get<FacultyResponseDto[]>(environment.url + `/departments?onlyFaculty=true`)
      .pipe(catchError(this.handleError));
  }

  private handleError(error: any) {
    let errorMessage: string;
    errorMessage = error.message ? error.message : error.toString();
    return observableThrowError(errorMessage);
  }
}
