import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-searchform-room',
  templateUrl: './searchform-room.component.html',
  styleUrls: ['./searchform-room.component.scss'],
})
export class SearchformRoomComponent {
  @Output()
  id: EventEmitter<string | number> = new EventEmitter<string | number>();
  pid: string | number;
  public searchText: string = null;

  constructor() {}

  onSearch() {
    this.id.next(this.pid);
  }
}
