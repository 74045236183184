import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { importPKCS8, SignJWT } from 'jose';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {
  private apiUrl = environment.analytics.apiUrl;
  private credentials = {
    client_email: environment.analytics.client_email,
    private_key: environment.analytics.private_key
  };

  constructor(private readonly http: HttpClient) {
  }

  private async createJWT(): Promise<string> {
    const now = Math.floor(Date.now() / 1000);
    const payload = {
      iss: this.credentials.client_email,
      scope: 'https://www.googleapis.com/auth/analytics.readonly',
      aud: 'https://oauth2.googleapis.com/token',
      exp: now + 3600, // Token valid for 1 hour
      iat: now
    };

    const privateKey = await importPKCS8(this.credentials.private_key, 'RS256');
    return await new SignJWT(payload)
      .setProtectedHeader({ alg: 'RS256' })
      .sign(privateKey);
  }

  private async getAccessToken(): Promise<string> {
    const jwtToken = await this.createJWT();
    const body = new URLSearchParams({
      grant_type: 'urn:ietf:params:oauth:grant-type:jwt-bearer',
      assertion: jwtToken
    });

    const response: any = await this.http
      .post('https://oauth2.googleapis.com/token', body.toString(), {
        headers: new HttpHeaders({
          'Content-Type': 'application/x-www-form-urlencoded'
        })
      })
      .toPromise();

    return response.access_token;
  }

  async fetchAnalyticsData(propertyId: string,
                           dateRanges: Array<{ startDate: string, endDate: string }>,
                           metrics: Array<{ name: string }>,
                           dimensions: Array<{ name: string }>,
                           dimensionFilter?: { filter: { fieldName: string, stringFilter: { value: string } } }) {
    const token = await this.getAccessToken(); // Get the token from Google
    const headers = new HttpHeaders({
      Authorization: `Bearer ${token}`
    });

    const body: any = {
      dimensions: dimensions,
      metrics: metrics,
      dateRanges: dateRanges
    };

    dimensionFilter && (body.dimensionFilter = dimensionFilter);

    return this.http.post(`${this.apiUrl}/${propertyId}:runReport`, body, {
      headers
    });
  }

}

export type AnalyticsData = Object;

export interface AnalyticsEnvironment {
  label: string;
  propertyId: string;
}

export enum DateRange {
  Today = 'today',
  Yesterday = 'yesterday',
  TwoDays = '2daysAgo',
  SevenDays = '7daysAgo',
  OneMonth = '30daysAgo'
}

