export interface Room {
  name: string;
  building?: Building;
  id?: number;
  updatedAt?: number;
  isDeleted?: boolean;
}

export interface Building {
  id: number;
}

export class Room implements Room {
  static fromServer(roomServer: Room) {
    const room = new Room(
      roomServer.name,
      roomServer.building,
      roomServer.id,
      roomServer.updatedAt,
      roomServer.isDeleted
    );
    return room;
  }
  constructor(
    public name: string,
    public building?: Building,
    public id?: number,
    public updatedAt?: number,
    public isDeleted?: boolean
  ) {
    if (building) {
      this.building = new Building(building.id);
    }
  }
}

export class Building {
  constructor(public id: number, public name?: string) {
    this.id = id;
  }
}
