import { Component, OnInit } from '@angular/core';
import { ChangeRequestService } from '../../change-requests/shared/change-requests.service';
import { ChangeRequestsV1Service, GetChangeRequestDto } from '../../../_generated/hka-app-service';

@Component({
  selector: 'app-changerequest',
  templateUrl: './dashboard-change-requests.component.html',
  styleUrls: ['./dashboard-change-requests.component.scss'],
  providers: [ChangeRequestService]
})
export class DashboardChangeRequestsComponent implements OnInit {
  changereq: GetChangeRequestDto[] = [];
  changeReqCount: number;
  errorMessage: string;

  loading = true;
  constructor(private readonly changeRequestsService: ChangeRequestsV1Service) {}

  ngOnInit() {
    this.changeRequestsService.getAllChangeRequests1().subscribe({
      next: (data: any) => {
        this.changereq = data;
        this.changeReqCount = this.changereq.length;
        this.loading = false;
      },
      error: error => {
        this.errorMessage = error as any;
        this.loading = false;
      }
    });
  }
}
