import { Routes } from '@angular/router';
import { SemesterScheduleComponent } from './component/semester-schedule/semester-schedule.component';
import { TaskPlanComponent } from './component/task-plan/task-plan.component';
import { ModifyCoursesComponent } from './component/modify-courses/modify-courses.component';
import { LockedPlansComponent } from './component/locked-plans/locked-plans.component';
import { IgnoreSemestersComponent } from './component/ignore-semesters/ignore-semesters.component';

export const PLANS_ROUTES: Routes = [
  {
    path: 'semesterschedule',
    component: SemesterScheduleComponent,
  },
  {
    path: 'scsl',
    component: TaskPlanComponent,
  },
  {
    path: 'modifycourses',
    component: ModifyCoursesComponent,
  },
  {
    path: 'locked-plans',
    component: LockedPlansComponent,
  },
  {
    path: 'ignoreSemesters',
    component: IgnoreSemestersComponent,
  },
];
