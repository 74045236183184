<div id="crUpdateCampus" class="col-12" *ngIf="form">
  <h1 id="pageHeadline">{{ pageTitle }}</h1>
  <form [formGroup]="form" role="form">
    <div class="form-group row">
      <label for="idInput" class="col-9 col-form-label"> ID<span style="color: red">*</span> </label>
      <div class="col-9">
        <input
          id="idInput"
          placeholder="Not set"
          class="form-control"
          formControlName="id"
          [readonly]="true"
          required
        />
      </div>
    </div>

    <div class="form-group row">
      <label for="nameInput" class="col-9 col-form-label"> Name<span style="color: red">*</span> </label>
      <div class="col-9">
        <input id="nameInput" placeholder="Name" class="form-control" formControlName="name" required />
      </div>
    </div>
    <div class="form-group row">
      <label for="streetInput" class="col-9 col-form-label">
        Straße
      </label>
      <div class="col-9">
        <input id="streetInput" placeholder="Straße" class="form-control" formControlName="street" />
      </div>
    </div>

    <div class="form-group row">
      <label for="houseNumberInput" class="col-9 col-form-label">
        Hausnummer
      </label>
      <div class="col-9">
        <input
          id="houseNumberInput"
          placeholder="Hausnummer"
          class="form-control"
          formControlName="houseNumber"
        />
      </div>
    </div>

    <div class="form-group row">
      <label for="postcodeInput" class="col-9 col-form-label">
        Postleitzahl
      </label>
      <div class="col-9">
        <input id="postcodeInput" placeholder="Postleitzahl" class="form-control" formControlName="postcode" />
      </div>
    </div>
    <div class="form-group row">
      <label for="coordinates" class="col-9 col-form-label">
        Koordinaten
      </label>
      <div class="col-9">
        <input id="coordinates" placeholder="Koordinaten" class="form-control" formControlName="coordinates" />
      </div>
    </div>
    <div class="form-group row">
      <label for="coordinatesCenter" class="col-9 col-form-label">
        Koordinaten Zentriert
      </label>
      <div class="col-9">
        <input
          id="coordinatesCenter"
          placeholder="Koordinaten Zentriert"
          class="form-control"
          formControlName="coordinatesCenter"
        />
        <div class="pt-2">
          <app-gmap
            coordinates="{{ campus.coordinatesCenter }}"
            [draggable]="true"
            (updateCoordinatesEvent)="updateCoordinatesFromMap($event)"
          ></app-gmap>
        </div>
      </div>
    </div>

    <div class="buttons col-9">
      <button id="cancelBtn" class="btn btn-danger" (click)="cancelEdit()">Abbrechen</button>
      <button id="updateBtn" (click)="onUpdate()" [disabled]="!form.valid" class="btn btn-danger">
        Update
      </button>
    </div>
  </form>
</div>
