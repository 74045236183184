import { Component } from '@angular/core';
import { environment } from 'environments/environment';
import { LoginService } from '../login/shared/login.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  public isCollapsed: boolean = true;

  public isProduction = environment.production;

  constructor(public loginService: LoginService) {}

  public logout() {
    this.loginService.logout();
  }

  protected readonly localStorage = localStorage;
}
