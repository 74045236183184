import { Component, OnInit } from '@angular/core';
import { AnalyticsEnvironment } from './api/analytics.service';

@Component({
  selector: 'app-analytics',
  templateUrl: './analytics.component.html',
  styleUrls: ['./analytics.component.scss']
})
export class AnalyticsComponent implements OnInit {

  selectedEnvironment?: AnalyticsEnvironment;
  environments: AnalyticsEnvironment[] = [
    {
      label: 'Produktion',
      propertyId: '465127152'
    }
  ];

  ngOnInit() {
    this.selectEnvironment(this.environments[0]);
  }

  selectEnvironment(env: AnalyticsEnvironment) {
    this.selectedEnvironment = env;
  }
}
