import { Component, OnInit } from '@angular/core';
import { ToastrServiceInt } from '../../shared/util/toastr.service';
import { Router } from '@angular/router';
import { Faculty } from '../../shared/models/faculty';
import { IdWrapper, NewsV1Service } from '../../../_generated/hka-app-service';
import { ConfirmationDialogService } from '../../confirmation-dialog/confirmation-dialog.service';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-createnewscategory',
  templateUrl: './createnewscategory.component.html',
  styleUrls: ['./createnewscategory.component.scss']
})
export class CreatenewscategoryComponent implements OnInit {
  bildBase64textString = '';
  angelegt = true;

  constructor(
    private newsService: NewsV1Service,
    private toastrService: ToastrServiceInt,
    private router: Router,
    private dialog: ConfirmationDialogService
  ) {
  }

  ngOnInit() {
  }

  createNewsCategory(value: any) {
    let faculty: Faculty = null;
    if (value.facultyInput != null && value.facultyInput.trim() !== '') {
      faculty = {
        id: value.facultyInput
      } as Faculty;
    }

    const courses: IdWrapper[] = faculty?.courses?.map((course) => ({ id: Number(course.id) } as IdWrapper)) || [];
    const newsCategories: IdWrapper[] =
      faculty?.newsCategories?.map((course) => ({ id: Number(course.id) } as IdWrapper)) || [];
    const poiCourses: IdWrapper[] =
      faculty?.poiCourses?.map((course) => ({ id: Number(course.id) } as IdWrapper)) || [];
    this.newsService
      .createNewsCategory1({
        faculty: {
          alias: faculty?.alias,
          courses: courses,
          id: faculty?.id,
          newsCategories: newsCategories,
          poiCourses: poiCourses
        },
        feed: value.feedUrl,
        format: value.feedFormat,
        image: this.bildBase64textString,
        name: value.categoryName
      })
      .subscribe(
        () => {
          this.angelegt = true;
          this.toastrService.setToastrSuccess('Erfolgreich!', 'News Category erfolgreich angelegt!', {
            progreparameterssBar: true
          });
          setTimeout(() => {
            this.router.navigate(['/newscategory']);
          }, 2000);
        },
        (error) => {
          console.error(error);
          this.angelegt = false;
          this.toastrService.setToastrError('Fehlgeschlagen!', 'Ein Fehler ist aufgetreten!');
        }
      );
  }

  handleFileSelect(evt) {
    const files = evt.target.files;
    const file = files[0];

    if (files && file) {
      const reader = new FileReader();

      reader.onload = this._handleReaderLoaded.bind(this);

      reader.readAsBinaryString(file);
    } else {
      this.bildBase64textString = '';
    }
  }

  _handleReaderLoaded(readerEvt) {
    const binaryString = readerEvt.target.result;
    this.bildBase64textString = btoa(binaryString);
  }

  askForConfirmation() {
    this.dialog
      .askForConfirmation({
        message: 'Möchten Sie die Ansicht wirklich verlassen? Es könnten Änderungen verloren gehen.',
        dialogOptions: ['CANCEL', 'CONFIRM']
      })
      .pipe(filter((result) => result == 'CONFIRM'))
      .subscribe(() => {
        this.router.navigate(['/newscategory']);
      });
  }
}
