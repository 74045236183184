import { Room } from '../../room/shared/room';

export interface General {
  id: number;
  name: string;
  openingHours: string;
  email: string;
  phone: string;
  updatedAt?: string;
  url: string;
  room: Room;
}

export class General implements General {
  static fromServer(generalServer: General) {
    const general = new General(
      generalServer.email,
      generalServer.id,
      generalServer.name,
      generalServer.openingHours,
      generalServer.phone,
      generalServer.url,
      generalServer.room,
      generalServer.updatedAt
    );
    return general;
  }

  constructor(
    public email: string,
    public id: number,
    public name: string,
    public openingHours: string,
    public phone: string,
    public url: string,
    public room: Room,
    public updatedAt?: string
  ) {}

  updatePerson(id: number, email: string, updatedAt: string, openingHours: string, phone: string, room: any) {
    this.openingHours = openingHours;
    this.email = email;
    this.id = id;
    this.phone = phone;
    this.openingHours = openingHours;
    this.room = room;
  }
}
