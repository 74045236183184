<mat-card class='w-100 mx-2'>
  <mat-card-title>
    <div class='d-flex justify-content-between align-items-center w-100'>
      <div>{{ this.label }}</div>
      <div class='d-flex justify-content-end align-items-center gap-2'>
        <div class='menu-text'>
          <div class='font-weight-bold'>
            Zeitraum:
          </div>
          <div>
            {{ selectedTimeLineOption | timeRange }}
          </div>
        </div>
        <button mat-icon-button [matMenuTriggerFor]='menu' aria-label='Example icon-button with a menu'>
          <mat-icon>date_range</mat-icon>
        </button>
      </div>
      <mat-menu #menu='matMenu'>
        <button mat-menu-item *ngFor='let option of timeLineOptions' (click)='selectTimeLineOption(option)'>
          <span>{{ option | timeRange }}</span>
        </button>
      </mat-menu>
    </div>
  </mat-card-title>
  <mat-card-content>
    <div echarts
         *ngIf='!isLoading; else loading'
         [options]='chartOptions'
         [loading]='isLoading'
         theme='macarons'
         class='demo-chart'>
    </div>
  </mat-card-content>
</mat-card>

<ng-template #loading>
  <div class='loading d-flex justify-content-center align-items-center w-100 h-100'>
    <mat-spinner [diameter]='32'></mat-spinner>
  </div>
</ng-template>
