import { Component, OnInit } from '@angular/core';
import { ToastrServiceInt } from 'app/shared/util/toastr.service';
import {
  BuildingV1Service,
  GetBuildingDto,
  GetPoiGeneralDto,
  POIV1Service,
  RoomsV1Service
} from '../../../_generated/hka-app-service';
import { filter } from 'rxjs/operators';
import { ConfirmationDialogService } from '../../confirmation-dialog/confirmation-dialog.service';

@Component({
  selector: 'app-search-general',
  templateUrl: './search-general.component.html',
  styleUrls: ['./search-general.component.scss']
})
export class SearchGeneralComponent implements OnInit {
  pageTitle = 'Allgemein';
  public generals: GetPoiGeneralDto[];
  public general: GetPoiGeneralDto;
  private searchparam: any;
  private selectedGeneral: GetPoiGeneralDto;
  public loading = false;

  buildingGeneralMap: Record<number, GetBuildingDto> = [];

  constructor(
    private generalService: POIV1Service,
    private roomService: RoomsV1Service,
    private buildingService: BuildingV1Service,
    private toastrService: ToastrServiceInt,
    private dialog: ConfirmationDialogService
  ) {
    this.generals = [];
  }

  ngOnInit() {
    this.getAll();
  }

  getGeneral(searchparam: any) {
    this.searchparam = searchparam;
    this.generals.length = 0;
    if (searchparam) {
      if (Number(searchparam)) {
        this.getGeneralById(searchparam);
      } else {
        this.getGeneralByName(searchparam);
      }
    } else {
      this.getAll();
    }
  }

  getAll() {
    this.loading = true;
    this.generalService.getAllPoiGenerals1().subscribe((data) => {
      this.loading = false;
      data.forEach((d) => {
        if (d.room) {
          if (!d.room.building) {
            d.room.building = {};
            this.loading = true;
            this.roomService.getRoom(d.room.id).subscribe((room) => {
              this.loading = false;
              d.room.name = room.name;
              room.building.id && (d.room.building.id = room.building.id);
            });
          }
        }
      });
      this.generals = data;
      this.generals.forEach(general => {
        const room = general.room;
        if (room.building.id) {
          this.loading = true;
          this.buildingService.getBuilding(room.building.id).subscribe((building) => {
            this.loading = false;
            this.buildingGeneralMap[room.building.id] = building;
          });
        }
      });
      this.generals.sort((a, b) => a.name.localeCompare(b.name));
    });
  }

  getGeneralById(id: number) {
    this.loading = true;
    this.generalService.getPoiGeneral1(id).subscribe(
      (data) => {
        this.loading = false;
        this.generals[0] = data;
      },
      (error) => {
      }
    );
  }

  getGeneralByName(name: string) {
    this.loading = true;
    this.generalService.getPoiGeneralsPage1(null, name).subscribe((data) => {
      this.loading = false;
      this.generals = data.content as any;
    });
  }

  onDelete(id: number, name: string) {
    this.dialog
      .askForConfirmation({
        message: 'Sind sie sicher, dass sie ' + name + ' löschen wollen? ',
        dialogOptions: ['CANCEL', 'CONFIRM']
      })
      .pipe(filter((result) => result == 'CONFIRM'))
      .subscribe(() => {
        this.generalService.deletePoiGeneral1(id).subscribe(() => {
          this.toastrService.setToastrSuccess('Erfolgreich!', 'Der Eintrag wurde gelöscht.', { progressBar: true });
          this.getAll();
        });
      });
  }
}
