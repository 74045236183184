import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { GetPersonDto, PersonsV1Service } from '../../../_generated/hka-app-service';
import { isNullOrUndefined } from 'util';
import { ToastrServiceInt } from '../../shared/util/toastr.service';
import { ConfirmationDialogService } from '../../confirmation-dialog/confirmation-dialog.service';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-person-detail',
  templateUrl: './person-detail.component.html',
  styleUrls: ['./person-detail.component.scss']
})
export class PersonDetailComponent {
  screenHeight: number;
  @Input() person: GetPersonDto;
  @Output() personDeleted = new EventEmitter<void>(); // EventEmitter for deletion event

  constructor(
    private toastrService: ToastrServiceInt,
    private personService: PersonsV1Service,
    private dialog: ConfirmationDialogService
  ) {
    this.screenHeight = window.innerHeight;
  }

  isScreenHeightUnder700(): boolean {
    return this.screenHeight < 800;
  }

  isScreenHeightOver700(): boolean {
    return this.screenHeight >= 800;
  }

  public onDelete(person: GetPersonDto): void {
    this.dialog
      .askForConfirmation({
        message:
          'Sind Sie sicher, dass Sie die Person ' + person.firstName + ', ' + person.lastName + ' löschen möchten?',
        dialogOptions: ['CANCEL', 'CONFIRM']
      })
      .pipe(filter((result) => result == 'CONFIRM'))
      .subscribe(() => {
        this.personService.deletePerson(person.id).subscribe(
          () => {
            this.toastrService.setToastrSuccess('Erfolgreich!', 'Die Person wurde gelöscht.', { progressBar: true });
            this.personDeleted.emit();
            this.person = null;
          },
          (error) => {
            this.toastrService.setToastrError('Fehler!', 'Die Person konnte nicht gelöscht werden.');
          }
        );
      });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.screenHeight = event.target.innerHeight;
  }

  protected readonly isNullOrUndefined = isNullOrUndefined;
}
