import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { RoomService } from '../../room/shared/room.service';
import {
  BuildingV1Service,
  GetBuildingDto,
  GetPersonDto,
  GetRoomDto,
  PersonsV1Service,
  PutPersonDto,
  RoomsV1Service
} from '../../../_generated/hka-app-service';
import { ToastrServiceInt } from '../../shared/util/toastr.service';
import { MatSelectChange } from '@angular/material/select';
import { filter } from 'rxjs/operators';
import { ConfirmationDialogService } from '../../confirmation-dialog/confirmation-dialog.service';

@Component({
  selector: 'app-new-person',
  templateUrl: './new-person.component.html',
  styleUrls: ['./new-person.component.scss']
})
export class NewPersonComponent implements OnInit {
  @Output()
  refreshSearch: EventEmitter<GetPersonDto> = new EventEmitter<GetPersonDto>();
  form: FormGroup;
  id: FormControl<number>;
  firstName: FormControl<string>;
  lastName: FormControl<string>;
  email: FormControl<string>;
  academicDegree: FormControl<string>;
  title: FormControl<string>;
  consultationHour: FormControl<string>;
  phone: FormControl<string>;
  building: FormControl<GetBuildingDto>;
  room: FormControl<GetRoomDto>;
  imageUrl: FormControl<string>;
  allRooms: GetRoomDto[] = [];
  allBuildings: GetBuildingDto[] = [];
  availableRooms: GetRoomDto[] = [];

  constructor(
    private router: Router,
    private toastrService: ToastrServiceInt,
    private personService: PersonsV1Service,
    private readonly formBuilder: FormBuilder,
    private readonly roomService: RoomsV1Service,
    private readonly roomHelperService: RoomService,
    private readonly buildingService: BuildingV1Service,
    private dialog: ConfirmationDialogService
  ) {
    this.email = new FormControl('', [Validators.required, Validators.email]);
    this.phone = new FormControl(null, Validators.pattern('^[0-9 +#]*$'));
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      id: this.id,
      firstName: this.firstName,
      lastName: this.lastName,
      email: this.email,
      academicDegree: this.academicDegree,
      title: this.title,
      consultationHour: this.consultationHour,
      phone: this.phone,
      building: this.building,
      room: this.room,
      imageUrl: this.imageUrl
    });

    this.buildingService.getAllBuildings1().subscribe((buildings) => {
      this.allBuildings = buildings.sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });

      // get all rooms inside get all buildings, so we can calculate the first set of available buildings
      this.roomService.getAllRooms1().subscribe((rooms) => {
        this.allRooms = rooms;
        //          if (this.person.room?.building?.id) {
        //            this.availableRooms = this.roomHelperService.calculateRoomsOfBuilding(this.allRooms, this.person.room?.building?.id);
        //            if (this.person.room?.id) {
        //              const roomIndex = this.availableRooms.findIndex(room => this.person.room.id === room.id);
        //              this.room.setValue(this.availableRooms[roomIndex], { onlySelf: true });
        //            }
        //          }
      });
    });

    // whenever the building value changes the rooms are reset
    this.form.get('building').valueChanges.subscribe((value) => {
      console.log(value);
      this.availableRooms = this.allRooms.filter((room) => room.building.id == value);
      //      this.availableRooms = this.roomHelperService.calculateRoomsOfBuilding(this.allRooms, value);
      let defaultRoom = this.availableRooms.find((element) => element.name === 'Kein Raum');
      //      this.room.setValue(defaultRoom); // we reset the value of the room if the
    });
  }

  public onBuildingChange(building: MatSelectChange) {
    console.log(building);
  }

  onCreate() {
    if (this.form.pristine) {
      this.toastrService.setToastrWarning('Achtung!', 'Es wurde nichts geändert');
      return;
    } else {
      const personTmp: PutPersonDto = {
        academicDegree: this.form.get('academicDegree').value,
        consultationHour: this.form.get('consultationHour').value,
        email: this.form.get('email').value,
        firstName: this.form.get('firstName').value,
        imageUrl: this.form.get('imageUrl').value,
        lastName: this.form.get('lastName').value,
        ldap: this.form.get('person')?.value.ldap,
        phone: this.form.get('phone').value,
        remark: this.form.get('person')?.value.remark,
        title: this.form.get('title').value,

        faculty: this.form.get('person')?.value.faculty
      };
      console.log(JSON.stringify(personTmp));
      this.personService.createPerson1(personTmp).subscribe(
        () => {
          this.refreshSearch.next(personTmp);
          this.router.navigate(['/searchperson']);
          this.toastrService.setToastrSuccess('Erfolgreich!', 'Ihre Daten wurden gespeichert.', { progressBar: true });
        },
        (error) => {
          this.toastrService.setToastrError('Fehler!', 'Ihre Daten konnten nicht gespeichert werden.');
        }
      );
    }
  }

  cancelEdit() {
    this.dialog
      .askForConfirmation({
        message: 'Möchten Sie die Ansicht wirklich verlassen? Es könnten Änderungen verloren gehen.',
        dialogOptions: ['CANCEL', 'CONFIRM']
      })
      .pipe(filter((result) => result == 'CONFIRM'))
      .subscribe(() => {
        this.router.navigate(['/searchperson']);
      });
  }
}
