import { Observable, throwError as observableThrowError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { Inject, Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { LoginService } from "../../login/shared/login.service";
import { Building, Room } from "./room";
import { HttpClient } from "@angular/common/http";
import { GetRoomDto } from '../../../_generated/hka-app-service';

@Injectable()
export class RoomService {
  constructor(@Inject(HttpClient) private readonly http: HttpClient, private loginService: LoginService) {
  }

  getAllRooms(): Observable<Room[]> {
    return this.http.get<Room[]>(environment.url + "/rooms").pipe(catchError(this.handleError));
  }

  getAllBuildings(): Observable<Building[]> {
    return this.http.get<Building[]>(environment.url + "/buildings").pipe(catchError(this.handleError));
  }

  getRoomById(id: number): Observable<Room> {
    return (
      this.http
        .get<Room>(environment.url + "/rooms/" + `${id}`)
        .pipe(catchError(this.handleError))
    );
  }

  getBuildingById(id: number): Observable<Building> {
    return (
      this.http
        .get<Building>(environment.url + "/buildings/" + `${id}`)
        //.map(response => this.toRoom(response))
        .pipe(catchError(this.handleError))
    );
  }
  /**
   * Gets Buildings by name
   * The method uses the .content of the provided server-endpoint although the content actually is pagable.
   * @param name
   * @return an array of all the found rooms with the name
   */
  getBuildingByFullName(name: string): Observable<Building[]> {
    return this.http.get<any>(environment.url + `/buildings/page?name=${name}`)
      .pipe(map(serverResponse => serverResponse.content ?? []), catchError(this.handleError));
  }

  createRoom(room: Room) {
    const httpOptions = {
      headers: this.loginService.getAuthorizationHeader()
    };
    return this.http.post<Room>(environment.url + "/rooms", room, httpOptions);
  }

  deleteRoomById(id: number) {
    const httpOptions = {
      headers: this.loginService.getAuthorizationHeader()
    };
    return this.http.delete<any>(environment.url + `/rooms/${id}`, httpOptions).pipe(catchError(this.handleError));
  }

  /**
   * Does not send a request but works with the data provided. Moved to the service to reduce duplicates
   * @param allRooms list of all rooms (already got by another call)
   * @param buildingId the id of the building we want the rooms of
   * @return the sorted list of rooms belonging to the given building
   */
  public calculateRoomsOfBuilding(allRooms: GetRoomDto[], buildingId: number): GetRoomDto[] {
    const roomsOfBuilding = allRooms.filter(room => room.building && room.building.id === buildingId);
    return roomsOfBuilding.sort(function(room1, room2) {
      const x = room1.name.toLowerCase();
      const y = room2.name.toLowerCase();
      if (x < y) {
        return -1;
      }
      if (x > y) {
        return 1;
      }
      return 0;
    });
  }

  private handleError(error: any) {
    let errorMessage: string;
    errorMessage = error.message ? error.message : error.toString();
    return observableThrowError(errorMessage);
  }


}
