import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { LoginService } from './shared/login.service';

@Injectable({
  providedIn: 'root'
})
export class LoginGuard implements CanActivate {

  public authenticated = false;

  constructor(private readonly loginService: LoginService) {
    if(this.loginService.hasValidToken()) {
      this.authenticated = true;
    }
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.loginService.hasValidToken()) {
      this.authenticated = true;
      return this.authenticated;
    } else {
      this.authenticated = false;
      this.loginService.logout();
      return false;
    }
  }

}

export interface TokenResponse {
  auth: string;
  exp: number;
  sub: string;
}
