import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-searchform-campus',
  templateUrl: './searchform-campus.component.html',
  styleUrls: ['./searchform-campus.component.scss']
})
export class SearchformCampusComponent {

  cid: number;

  @Output() id: EventEmitter<number> = new EventEmitter<number>();

  @Output()
  public newBuilding: EventEmitter<void> = new EventEmitter<void>();

  constructor() {}

  onSearch() {
    this.id.next(this.cid);
  }

  onNewBuilding() {
    this.newBuilding.emit();
  }
}
