import { NgModule } from '@angular/core';
import { CommonModule, NgFor, NgOptimizedImage } from '@angular/common';
import { SemesterScheduleComponent } from './component/semester-schedule/semester-schedule.component';
import { PLANS_ROUTES } from './plans.routes';
import { RouterModule } from '@angular/router';
import { SemesterScheduleService } from './service/semester-schedule.service';
import { ModifyCoursesComponent } from './component/modify-courses/modify-courses.component';
import { ManageSemesterScheduleUsecase } from './usecase/manage-semester-schedule.usecase';
import { ModifyCoursesService } from './service/modify-courses.service';
import { FormsModule } from '@angular/forms';
import { TaskPlanComponent } from './component/task-plan/task-plan.component';
import { ManageTaskPlanUsecase } from './usecase/manage-task-plan.usecase';
import { TaskPlanService } from './service/task-plan.service';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {
  faCaretDown,
  faCaretUp,
  faChevronCircleUp,
  faPencilAlt,
  faPlus,
  faSearch,
  faTrashAlt,
} from '@fortawesome/free-solid-svg-icons';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { TextFieldModule } from '@angular/cdk/text-field';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MaterialFileInputModule } from 'ngx-material-file-input';
import { MatIconModule } from '@angular/material/icon';
import { MatExpansionModule } from '@angular/material/expansion';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { LockedPlansComponent } from './component/locked-plans/locked-plans.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { IgnoreSemestersComponent } from './component/ignore-semesters/ignore-semesters.component';
import { MatSelectModule } from '@angular/material/select';

@NgModule({
  declarations: [
    SemesterScheduleComponent,
    ModifyCoursesComponent,
    TaskPlanComponent,
    LockedPlansComponent,
    IgnoreSemestersComponent,
  ],
  imports: [
    NgFor,
    MatInputModule,
    FormsModule,
    FontAwesomeModule,
    CommonModule,
    RouterModule.forChild(PLANS_ROUTES),
    MatCardModule,
    MatButtonModule,
    MatFormFieldModule,
    TextFieldModule,
    MatListModule,
    MaterialFileInputModule,
    MatIconModule,
    MatExpansionModule,
    ClipboardModule,
    MatAutocompleteModule,
    NgOptimizedImage,
    MatSelectModule,
    MatProgressSpinnerModule,
  ],
  providers: [
    ManageSemesterScheduleUsecase,
    ManageTaskPlanUsecase,
    SemesterScheduleService,
    ModifyCoursesService,
    TaskPlanService,
  ],
})
export class PlansModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(faSearch, faChevronCircleUp, faPencilAlt, faPlus, faTrashAlt, faCaretUp, faCaretDown);
  }
}
