<div class="card">
  <div class="card-body">
    <div class="row" *ngIf="roomsOfBuilding">
      <div id="rooms" class="col-md-12">
        <div class="col-md-12">
          <h3 class="mb-0">
            Räume
            <div class="float-right padding-22" id="faicon">
              <a class="btn btn-outline-primary" [routerLink]="['/newroom']">
                <fa-icon [icon]="['fas', 'plus']"></fa-icon> Neu
              </a>
            </div>
          </h3>
          <hr />
          <table class="col-md-12" style="border:none">
            <ul class="list-group">
              <a class="list-group-item list-group-item-action" *ngFor="let room of roomsOfBuilding">
                <div class="float-right" id="faicon">
                  <button class="btn btn-outline-primary" (click)="onDelete(room.id, room.name)">
                    <fa-icon [icon]="['fas', 'trash-alt']"></fa-icon>
                  </button>
                </div>
                <td>
                  <strong>{{ room.name }}</strong>
                </td>
              </a>
            </ul>
          </table>
        </div>
      </div>
    </div>
    <div id="noData" *ngIf="!roomsOfBuilding">
      Bitte wählen Sie ein Gebäude aus!
    </div>
  </div>
</div>
