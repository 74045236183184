<div class="row">
  <div class="col-md-12" id="studentUnionSearchForm">
    <div class="input-group mb-3">
      <div style="margin-right: 5px;">
        <a class="btn btn-outline-primary" [routerLink]="['/newstudentunion']">
          <fa-icon [icon]="['fas', 'plus']"></fa-icon> Neu
        </a>
      </div>
      <input
        id="searchfieldStudentUnion"
        type="text"
        class="form-control"
        [(ngModel)]="pid"
        (keyup.enter)="onSearch()"
        placeholder="Name oder ID eintragen"
      />
      <div class="input-group-append">
        <span class="input-group-text" (click)="onSearch()">
          <fa-icon [icon]="['fas', 'search']"></fa-icon>
        </span>
      </div>
    </div>
  </div>
</div>
