export class ChangeRequest implements ChangeRequest {
  static fromServer(personServer: ChangeRequest) {
    const person = new ChangeRequest(
      personServer.comment,
      personServer.changes,
      personServer.label,
      personServer.hasImage,
      personServer.hsUser,
      personServer.id,
      personServer.objectId,
      personServer.type,
      personServer.updatedAt
    );
    return person;
  }

  constructor(
    public comment: string,
    public changes: any,
    public label: string,
    public hasImage: boolean,
    public hsUser: string,
    public id: number,
    public objectId: number,
    public type: string,
    public updatedAt: string
  ) {}
}

export const changeRequestTypes = {
  person: 'PERSON',
  office: 'OFFICE',
  studentUnion: 'STUDENTUNION',
  general: 'GENERAL',
  all: 'ALL'
};
