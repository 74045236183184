import { environment } from '../../../environments/environment';
import { Component, OnInit } from '@angular/core';
import { GetNewsCategoryDto, NewsV1Service } from '../../../_generated/hka-app-service';

@Component({
  selector: 'app-shownewscatecory',
  templateUrl: './shownewscatecory.component.html',
  styleUrls: ['./shownewscatecory.component.scss']
})
export class ShownewscatecoryComponent implements OnInit {
  categories: GetNewsCategoryDto[] = [];
  imageBaseURL: string;
  public loading = false;
  public sortedNewsCategories: GetNewsCategoryDto[];

  constructor(private readonly newsCategoryService: NewsV1Service) {
  }

  ngOnInit() {
    this.getAll();
    this.imageBaseURL = environment.url + '/';
  }

  getAll() {
    this.loading = true;
    this.newsCategoryService.getAllNewsCategories1().subscribe((data) => {
      this.categories = data;
      this.loading = false;
      this.sortedNewsCategories = this.categories.sort((a, b) => a.name.localeCompare(b.name));
    });
  }

  topFunction() {
    document.body.scrollTop = 0; // for safari
    document.documentElement.scrollTop = 0; // for chrome, firefox, IE, opera
  }
}
