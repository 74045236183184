import { Component, OnInit } from '@angular/core';
import { SemesterService } from '../../service/semester.service';
import { DepartmentsV1Service, GetLdapFacultyDto, GetSemesterDto } from '../../../../_generated/hka-app-service';

@Component({
  selector: 'app-locked-plans',
  templateUrl: './locked-plans.component.html',
  styleUrls: ['./locked-plans.component.scss']
})
export class LockedPlansComponent implements OnInit {

  semesters: GetSemesterDto[] = [];
  selectedSemester: GetSemesterDto | null;
  selectedFaculty?: GetLdapFacultyDto;
  isLoaded = false;

  constructor(private readonly semestersService: SemesterService, private readonly facultyService: DepartmentsV1Service) {
  }

  ngOnInit(): void {
    this.semestersService.getLockedSemesterPlans().subscribe({
      next: data => {
        const sorted = data.sort();
        sorted.forEach(id => {
          this.semestersService.getSemesterById(id).subscribe({
            next: data => {
              data && this.semesters.push(data);
            }
          });
        });
        this.isLoaded = true;
      },
      error: err => this.isLoaded = true
    });
  }

  onSelectPlan(id: number) {
    this.facultyService.getDepartment(this.semesters[id]?.course?.facultyId.toString()).subscribe({
      next: data => {
        this.selectedSemester = this.semesters[id];
        this.selectedFaculty = data;
      }
    });
  }
}
