<h1>Change Request bearbeiten</h1>
<mat-card class="col-md-8 offset-md-2 bg-light mb-5">
  <mat-card-header class="w-100">
    <mat-card-title>
      <span class="h6">
        <b>
          [{{
            cr?.type == 'GENERAL'
              ? 'ALLGEMEIN'
              : cr?.type == 'STUDENTUNION'
              ? 'FACHSCHAFT'
              : cr?.type == 'PERSON'
              ? 'PERSON'
              : cr?.type == 'OFFICE'
              ? 'SEKRETARIAT'
              : ''
          }}]
        </b>
        <b *ngIf="cr?.type == 'PERSON' && !isNullOrUndefined(cr.label)"> - {{ cr.label }}</b>
        <b
          *ngIf="
            cr?.type === 'OFFICE' ||
            cr?.type === 'STUDENTUNION' ||
            (cr?.type === 'GENERAL' &&
              typeOf(cr.changes) === 'object' &&
              cr.changes.length > 0 &&
              cr.changes[0][0] === 'name')
          "
        >
          - {{ cr.changes[0][1].before }}
        </b>
      </span>
    </mat-card-title>
    <mat-card-subtitle>
      <div class="h6">
        <span class="pr-1">
          <b>#{{ cr?.id }}</b>
        </span>
        <span>
          {{ cr?.updatedAt }}
        </span>
      </div>
    </mat-card-subtitle>
  </mat-card-header>
  <mat-divider></mat-divider>
  <mat-card-content>
    <!-- Table -->
    <div class="mt-4">
      <table attr.id="table-changes-{{ changeRequest?.id }}" class="table table-changes table-bordered">
        <thead class="thead-light">
          <tr>
            <th scope="col">Attribut</th>
            <th scope="col">Alter Wert</th>
            <th scope="col">Neuer Wert</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let change of cr?.changes">
            <td class="align-middle">{{ change[0] }}</td>
            <td class="align-middle" [innerHTML]="change[1]?.before"></td>
            <td>
              <mat-form-field appearance="fill" class="w-100 pt-3">
                <input matInput [(ngModel)]="change[1].after" (change)="onChange(change)" placeholder="Neuer Wert" />
              </mat-form-field>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- Image Section -->
    <div *ngIf="cr?.hasImage" class="mt-4">
      <mat-card>
        <mat-card-header>
          <mat-card-title><span class="oi oi-paperclip"></span> Anhang</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <figure class="figure">
            <img
              src="{{ imgUrl }}"
              class="figure-img img-thumbnail"
              alt="{{ cr.id }}.png"
              (click)="openImageModal(cr.id)"
            />
            <figcaption class="figure-caption">{{ cr.id }}.png</figcaption>
          </figure>
        </mat-card-content>
      </mat-card>
    </div>
  </mat-card-content>
  <mat-divider></mat-divider>
  <mat-card-actions>
    <button mat-flat-button color="warn" (click)="onAbort()">Abbrechen</button>
    <button mat-flat-button color="primary" (click)="onUpdate()" class="float-right">Bestätigen</button>
  </mat-card-actions>
</mat-card>
