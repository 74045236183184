<div class="card">
  <div class="card-header">
    <h5>Aufgabenplan als iCal bearbeiten</h5>
  </div>
  <div class="card-footer">
    <div class="buttons col-9">
      <button (click)="onDownload()" class="btn btn-danger mb-2">Download</button>
      <div class="upload-task-plan">
        <div class="input-group-text">
          <input type="file" (change)="onChange($event)" />
        </div>
        <div class="buttons d-flex align-items-center ml-2">
          <button (click)="onUpload()" class="btn btn-outline-danger">Hochladen</button>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- JSON REST API REQUIRED -->

<!--<div class="card">-->
<!--  <div class="card-header">-->
<!--    <h5>Aufgabenplan erstellen und hochladen</h5>-->
<!--  </div>-->
<!--  <div class="card-body">-->
<!--    <div class="col-12 mb-3">-->
<!--      <label>-->
<!--        Name des Aufgabenplans-->
<!--        <input class="mr-4" type="text" placeholder="" [value]="task.name" />-->
<!--      </label>-->

<!--      <button (click)="newEvent()" class="btn btn-danger">-->
<!--        Neuer Eintrag hinzufügen-->
<!--      </button>-->
<!--    </div>-->

<!--    <ng-container *ngFor="let entry of task.entries">-->
<!--      <div class="col-12">-->
<!--        <label>-->
<!--          Eventname-->
<!--          <input class="mr-4" type="text" placeholder="" [value]="entry.description" />-->
<!--        </label>-->

<!--        <label>-->
<!--          Startdatum-->
<!--          <input class="mr-4" type="date" placeholder="" [value]="entry.dates.begin" />-->
<!--        </label>-->

<!--        <label>-->
<!--          Enddatum-->
<!--          <input type="date" placeholder="" [value]="entry.dates.end" />-->
<!--        </label>-->
<!--      </div>-->
<!--    </ng-container>-->
<!--  </div>-->
<!--  <div class="card-footer">-->
<!--    <div class="buttons col-9">-->
<!--      <button (click)="onUpload()" class="btn btn-danger">-->
<!--        Upload-->
<!--      </button>-->
<!--      <button (click)="reset()" class="btn btn-danger">-->
<!--        Reset-->
<!--      </button>-->
<!--    </div>-->
<!--  </div>-->
<!--</div>-->
