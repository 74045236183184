import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Building, Room } from '../../room/shared/room';
import { ToastrServiceInt } from '../../shared/util/toastr.service';
import { General } from '../shared/general';
import { GeneralService } from '../shared/general.service';
import { isNullOrUndefined, isUndefined } from 'util';
import {
  BuildingV1Service,
  GetPoiGeneralDto,
  GetRoomDto,
  POIV1Service,
  RoomsV1Service
} from '../../../_generated/hka-app-service';
import { filter } from 'rxjs/operators';
import { ConfirmationDialogService } from '../../confirmation-dialog/confirmation-dialog.service';

@Component({
  selector: 'app-update-general',
  templateUrl: './update-general.component.html',
  styleUrls: ['./update-general.component.scss'],
  providers: [GeneralService]
})
export class UpdateGeneralComponent implements OnInit, OnDestroy {
  pageTitle = 'Allgemein bearbeiten';
  @Input()
  general: GetPoiGeneralDto;
  @Output()
  refreshSearch: EventEmitter<General> = new EventEmitter<General>();
  form: FormGroup;
  rooms: GetRoomDto[] = [];
  allRooms: GetRoomDto[] = [];
  buildings = [];
  private sub: Subscription;
  private subBuildingChange: Subscription;

  constructor(
    private generalService: POIV1Service,
    private campusService: BuildingV1Service,
    private roomService: RoomsV1Service,
    private readonly formBuilder: FormBuilder,
    private toastrService: ToastrServiceInt,
    private router: Router,
    private route: ActivatedRoute,
    private dialog: ConfirmationDialogService
  ) {
  }

  ngOnInit(): void {
    this.sub = this.route.params.subscribe((params) => {
      const generalId = +params['generalId']; // (+) converts string 'id' to a number
      this.generalService.getPoiGeneral1(generalId).subscribe((general) => {
        this.campusService.getAllBuildings1().subscribe((buildings) => {
          this.buildings = buildings;

          let buildingId: number;
          if (!(!general || !general.room || !general.room.building || !general.room.building.id)) {
            buildingId = general.room.building.id;
            this.setupRooms(buildingId);
          }

          this.form = this.formBuilder.group({
            id: general.id,
            name: general.name,
            email: general.email,
            openingHours: general.openingHours,
            phone: general.phone,
            room: general.room,
            building: { id: buildingId },
            url: general.url
          });

          if (isNullOrUndefined(buildingId)) {
            this.form.get('building').setValue(undefined);
            this.disableRoom();
          }

          this.subBuildingChange = this.form.get('building').valueChanges.subscribe((newBuilding: Building) => {
            if (!isUndefined(newBuilding)) {
              this.setupRooms(this.form.get('building').value.id);
              this.form.get('room').enable();
            } else {
              this.disableRoom();
            }
          });
        });
      });
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();

    if (this.subBuildingChange) {
      this.subBuildingChange.unsubscribe();
    }
  }

  compareBuilding(building1: Building, building2: Building): boolean {
    return building1 && building2 ? building1.id === building2.id : building1 === building2;
  }

  compareRoom(room1: Room, room2: Room): boolean {
    return room1 && room2 ? room1.id === room2.id : room1 === room2;
  }

  setupRooms(buildingId: number) {
    this.roomService.getAllRooms1().subscribe((allRooms) => {
      this.allRooms = allRooms;
      this.rooms = this.calculateRoomsOfBuilding(allRooms, buildingId);
    });
  }

  private calculateRoomsOfBuilding(allRooms: GetRoomDto[], buildingId: number): GetRoomDto[] {
    const roomsOfBuilding = allRooms.filter((room) => room.building && room.building.id === buildingId);
    return roomsOfBuilding.sort(function(room1, room2) {
      const x = room1.name.toLowerCase();
      const y = room2.name.toLowerCase();
      if (x < y) {
        return -1;
      }
      if (x > y) {
        return 1;
      }
      return 0;
    });
  }

  updateGeneral() {
    const formValue = this.form.value as General;

    const updatedGeneral = new General(
      formValue.email,
      formValue.id,
      formValue.name,
      formValue.openingHours,
      formValue.phone,
      formValue.url,
      isNullOrUndefined(formValue.room)
        ? null
        : new Room(formValue.room.name, formValue.room.building, formValue.room.id)
    );

    this.generalService.updatePoiGeneral1(updatedGeneral.id, updatedGeneral).subscribe((data) => {
      this.refreshSearch.next(updatedGeneral);
      this.router.navigate(['/searchgeneral']);
      this.toastrService.setToastrSuccess('Erfolgreich!', 'Ihre Daten wurden gespeichert.');
    });
  }

  cancelEdit() {
    this.dialog
      .askForConfirmation({
        message: 'Möchten Sie die Ansicht wirklich verlassen? Es könnten Änderungen verloren gehen.',
        dialogOptions: ['CANCEL', 'CONFIRM']
      })
      .pipe(filter((result) => result == 'CONFIRM'))
      .subscribe(() => {
        this.router.navigate(['/searchgeneral']);
      });
  }

  disableRoom() {
    this.form.get('room').setValue(undefined);
    this.form.get('room').disable();
  }

  buildingSelected() {
    return isNullOrUndefined(this.form.get('building').value);
  }
}
