import { Component, Input, OnInit } from '@angular/core';
import { AnalyticsEnvironment, AnalyticsService } from '../../api/analytics.service';

@Component({
  selector: 'app-number-widget',
  templateUrl: './number-widget.component.html',
  styleUrls: ['./number-widget.component.scss']
})
export class NumberWidgetComponent implements OnInit {

  @Input() startDate: string = '2015-08-14';
  @Input() endDate: string = 'today';
  @Input() metric: string;
  @Input() environment!: AnalyticsEnvironment;
  @Input() label: string = 'Aktive Nutzer';
  value: number = 0;
  valueLoading = false;

  constructor(private readonly analyticsService: AnalyticsService) {
  }

  ngOnInit(): void {
    this.getAnalytics(this.environment.propertyId);
  }

  private getAnalytics(propertyId: string) {
    this.valueLoading = true;
    const dateRanges = [{ startDate: this.startDate, endDate: this.endDate }];
    const metrics = [{ name: this.metric }];
    const dimensions = [{ name: 'date' }];

    this.analyticsService.fetchAnalyticsData(propertyId, dateRanges, metrics, dimensions).then(
      (data) => {
        data.subscribe({
          next: (value: any) => {
            console.log(value);
            value.rows.forEach(row => {
              this.value += +row.metricValues[0].value;
            });
            this.valueLoading = false;
          },
          error: _ => this.valueLoading = false
        });
      },
      (error) => {
        console.error('Error fetching analytics data:', error);
        this.valueLoading = false;
      }
    );
  }

}
