import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Campus } from '../shared/campus';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ToastrServiceInt } from '../../shared/util/toastr.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { BuildingV1Service, GetBuildingDto } from '../../../_generated/hka-app-service';
import { filter } from 'rxjs/operators';
import { ConfirmationDialogService } from '../../confirmation-dialog/confirmation-dialog.service';

@Component({
  selector: 'app-update-campus',
  templateUrl: './update-campus.component.html',
  styleUrls: ['./update-campus.component.scss']
})
export class UpdateCampusComponent implements OnInit, OnDestroy {
  pageTitle = 'Gebäude bearbeiten';
  @Input()
  campus: GetBuildingDto;
  @Output()
  refreshSearch: EventEmitter<Campus> = new EventEmitter<Campus>();
  form: FormGroup;
  id: FormControl<number>;
  name: FormControl<string>;
  street: FormControl<string>;
  houseNumber: FormControl<string>;
  postcode: FormControl<number>;
  coordinates: FormControl<string>;
  coordinatesCenter: FormControl<string>;
  private sub?: Subscription;

  constructor(
    private campusService: BuildingV1Service,
    private readonly formBuilder: FormBuilder,
    private router: Router,
    private toastrService: ToastrServiceInt,
    private route: ActivatedRoute,
    private dialog: ConfirmationDialogService
  ) {
  }

  ngOnInit() {
    this.sub = this.route.params.subscribe((params) => {
      const campusId = +params['campusId']; // (+) converts string 'id' to a number
      this.campusService.getBuilding(campusId).subscribe((data) => {
        const data1 = JSON.stringify(data);
        this.campus = JSON.parse(data1);
        this.id = new FormControl<number>(this.campus.id);
        this.name = new FormControl<string>(this.campus.name);
        this.street = new FormControl<string>(this.campus.street);
        this.houseNumber = new FormControl<string>(this.campus.houseNumber);
        this.postcode = new FormControl<number>(this.campus.postcode);
        this.coordinatesCenter = new FormControl<string>(this.campus.coordinatesCenter);
        this.coordinates = new FormControl<string>(this.campus.coordinates);

        this.form = this.formBuilder.group({
          id: this.id,
          name: this.name,
          street: this.street,
          houseNumber: this.houseNumber,
          postcode: this.postcode,
          coordinates: this.coordinates,
          coordinatesCenter: this.coordinatesCenter
        });
      });
    });
  }

  ngOnDestroy() {
    this.sub?.unsubscribe();
  }

  onUpdate() {
    const campusTmp = new Campus(
      this.id?.value,
      this.name?.value,
      this.street?.value,
      this.houseNumber?.value,
      this.postcode?.value,
      this.coordinatesCenter?.value,
      this.coordinates?.value,
      false
    );
    this.campusService.updateBuilding(this.id?.value, campusTmp).subscribe({
      next: data => {
        this.refreshSearch.next(campusTmp);
        this.router.navigate(['/searchcampus']);
        data && this.toastrService.setToastrSuccess('Erfolgreich!', 'Ihre Daten wurden gespeichert.');
      }
    });
  }

  cancelEdit() {
    this.dialog
      .askForConfirmation({
        message: 'Möchten Sie die Ansicht wirklich verlassen? Es könnten Änderungen verloren gehen.',
        dialogOptions: ['CANCEL', 'CONFIRM']
      })
      .pipe(filter((result) => result == 'CONFIRM'))
      .subscribe(() => {
        this.router.navigate(['/searchcampus']);
      });
  }

  updateCoordinatesFromMap(coordinates: any) {
    this.coordinatesCenter = new FormControl<string>(coordinates);
    this.campus.coordinatesCenter = coordinates;
    this.form = this.formBuilder.group({
      id: this.id,
      name: this.name,
      street: this.street,
      houseNumber: this.houseNumber,
      postcode: this.postcode,
      coordinates: this.coordinates,
      coordinatesCenter: this.coordinatesCenter
    });
  }
}
