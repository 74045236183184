import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrServiceInt } from '../../shared/util/toastr.service';
import { environment } from '../../../environments/environment';
import { Faculty } from '../../shared/models/faculty';
import { GetNewsCategoryDto, NewsV1Service, PutNewsCategoryDto } from '../../../_generated/hka-app-service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ConfirmationDialogService } from '../../confirmation-dialog/confirmation-dialog.service';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-editnewscategory',
  templateUrl: './editnewscategory.component.html',
  styleUrls: ['./editnewscategory.component.scss']
})
export class EditnewscategoryComponent implements OnInit {
  id: number;
  news: GetNewsCategoryDto;
  formatChoice: string;
  prevFormat: string;
  imageUrl: string;
  editiert = false;
  editiertBild = false;
  deleted = false;
  bildBase64textString = '';

  formGroup!: FormGroup;

  constructor(
    private newsCategoryService: NewsV1Service,
    private route: ActivatedRoute,
    private router: Router,
    private toastrService: ToastrServiceInt,
    private readonly formBuilder: FormBuilder,
    private dialog: ConfirmationDialogService
  ) {
  }

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.id = params['id'];
    });
    this.newsCategoryService.getNewsCategory1(this.id).subscribe((data) => {
      this.news = data;
      this.initForm(this.news);
      this.convertToDataURLviaCanvas(environment.url + '/' + data.imageUrl, 'image/png').then((base64Img: string) => {
        base64Img = base64Img.split(',')[1];
        this.bildBase64textString = base64Img;
      });
    });
  }

  private initForm(news: GetNewsCategoryDto) {
    this.formGroup = this.formBuilder.group({
      categoryName: [news.name || '', [Validators.required, Validators.minLength(2)]],
      feedUrl: [news.feed || '', [Validators.required, Validators.pattern('^https?://.*')]],
      facultyInput: [news.faculty?.id || '', [Validators.minLength(1)]],
      feedFormat: [news.format || '', []],
      fileSelect: ['', []]
    });
    this.formatChoice = news.format || '';
  }

  onFormSubmit() {
    let faculty: Faculty = null;
    const facultyInput = this.formGroup.get('facultyInput').value ?? '';
    if (typeof facultyInput === 'string' && facultyInput.trim() !== '') {
      faculty = {
        id: facultyInput
      } as Faculty;
    }

    const payload: PutNewsCategoryDto = {
      faculty: faculty?.id || null,
      feed: this.formGroup.get('feedUrl')?.value,
      format: this.formGroup.get('feedFormat')?.value,
      id: this.news.id,
      image: this.bildBase64textString,
      name: this.formGroup.get('categoryName')?.value
    };

    this.newsCategoryService.updateNewsCategory1(payload).subscribe(
      () => {
        this.editiert = true;
        this.toastrService.setToastrSuccess('Erfolgreich!', 'News Category wurde erfolgreich editiert!');
      },
      () => {
        this.editiert = false;
        this.toastrService.setToastrError('Fehlgeschlagen!', 'Ein Fehler ist aufgetreten!');
      }
    );
  }

  deleteCategory() {
    this.newsCategoryService.deleteNewsCategory1(this.id).subscribe(
      () => {
        this.deleted = true;
        this.toastrService.setToastrSuccess('Erfolgreich!', 'News Category wurde erfolgreich gelöscht!', {
          progressBar: true
        });
        setTimeout(() => {
          this.router.navigate(['/newscategory']).then();
        }, 2500);
      },
      () => {
        this.deleted = false;
        this.toastrService.setToastrError('Fehlgeschlagen!', 'Ein Fehler ist aufgetreten!');
      }
    );
  }

  convertToDataURLviaCanvas(url: string, outputFormat: string): Promise<string> {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.crossOrigin = 'Anonymous';
      img.onload = function() {
        let canvas = <HTMLCanvasElement>document.createElement('CANVAS');
        const ctx = canvas.getContext('2d');
        canvas.height = img.height;
        canvas.width = img.width;
        ctx.drawImage(img, 0, 0);
        const dataURL = canvas.toDataURL(outputFormat);
        canvas = null;
        resolve(dataURL);
      };
      img.src = url;
    });
  }

  handleFileSelect(evt) {
    const files = evt.target.files;
    const file = files[0];

    if (files && file) {
      const reader = new FileReader();

      reader.onload = this._handleReaderLoaded.bind(this);

      reader.readAsBinaryString(file);
    } else {
      this.bildBase64textString = '';
    }
  }

  _handleReaderLoaded(readerEvt) {
    const binaryString = readerEvt.target.result;
    this.bildBase64textString = btoa(binaryString);
    this.editiertBild = true;
  }

  askForConfirmation(del?: boolean) {
    this.dialog
      .askForConfirmation({
        message: del
          ? 'Möchten sie das Element wirklich löschen?'
          : 'Möchten Sie die Ansicht wirklich verlassen? Es könnten Änderungen verloren gehen.',
        dialogOptions: ['CANCEL', 'CONFIRM']
      })
      .pipe(filter((result) => result == 'CONFIRM'))
      .subscribe(() => {
        del ? this.deleteCategory() : this.router.navigate(['/newscategory']);
      });
  }
}
