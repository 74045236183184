export const environment = {
  production: true,
  url: 'https://api.hosts.hka-app.de', //old: 'https://www.iwi.hs-karlsruhe.de/hskampus-broker/api'
  analytics: {
    apiUrl: 'https://analyticsdata.googleapis.com/v1beta/properties',
    client_email: 'admin-app@produktion-15ca7.iam.gserviceaccount.com',
    private_key: `-----BEGIN PRIVATE KEY-----
    MIIEvgIBADANBgkqhkiG9w0BAQEFAASCBKgwggSkAgEAAoIBAQDBqgfhXq6A7leX\nIwa7SOMUoWMDbXcgNfnlZRUedNB4mkoBmkVe5TrtML464kIUcisuG+xsliBI/Kog\nzuogblmcS4e/BsBR2G44y0QcUD81N5jaqFe0VPNcMa5X/E/O/FhBnSPWaGdWnq+V\nzhhywEqrl2vRjMkc6K6ttwQfsEsd0+Hy91KkC+FOLFK3q9Xa0qe+lCNZ96r+SAoF\npgAQ6i3sNVoy9J0vdbaoqiJmo9VSR6t5mnZe3yh+7BzrB0e12g+a86IkNKLJKyQu\nQJ3CpFGDnhuOM+3s+2RX0mn9+XB7i7H1RfygynIjzXCOBxqEDM76CrDWQmQ4PIj4\nKTmO2mzXAgMBAAECggEASISfW8xlAsF9CgqjICk8YwLFvUhVtbmQgE4YGOX8FFUO\nBkia1lx28s9wRENqJBbWRXdOUiDrKPl3yT5B3HAF3SlJRQZFqMAnEdxHfmSsLoTJ\nl4M3CD9RO4Ic+zRvPB4w/CzX+RbyLzTPogRhEkMgEt3qETcrLciRdFoWULlmf12r\noHzaffWrbOb/dqmpFIAusVpWEp6IJb2VESJ3rgNNZ4wvsbWxMCvtjIBaov6Dg61v\nJK7kGz0bLxC+yWtbDZA4dVT+6uabociTApiRpcPdVLcn/4SKK3gMd6LxEXPSEKZb\nHykitM0yJFJfXxu9+Q1CYy0nmbWmwv+FMO2iHQxeZQKBgQDmjmGXw6LWWBRwWQoM\nG+2jIJO2mAZaC01DeUiw25x6bkAW3YH5ARFIXNqiil2Bu3b4yTLuL1zNVd1ZkUOQ\n5EODOZOBiKgGy6fytekPC5MF4ccJKNPKucNg4RW7ra/1DkgveAA6iuvsZhZGjiAr\nRWOcR/qEIS5yb7k1OJrY/iik1QKBgQDXCWK5Zwe58pwiTILBn3YHDs9lfgqYQJ7f\nvm+72YfP3i9FeS7TYDU1lFvrax6/mOmcYSzPCAcwfNHtP3xIelWNnwcMValdM+rC\n/F2AQ3/cQxwTV+27D1oq7ivBglWeiP35Yocuoi1Vs+d/W4SCobaKdVrySStehaiE\n/z0Pt6WQ+wKBgQCv9gbh7zv+XybqP+p9J2uMzrYt64Udf/auAojKAjJA9pgPW2tx\nAbGRCt0KpJONCehZJCWsx5UKWfgf/dAVa0Fji7PabtPeq9w4T0Haa7kACjr/8JsD\n5BcLf344yOy8GPRa1jlGdPRubdOecaZ3n/PQONcntLNgP4iY5+S2q/OVKQKBgFyL\neIaMVeHsDNAiF4AXDae58v4eAZdG8Fkgz1uMIgwO9k8y+TkVRbEaeXjW8DPLLncT\nx0IOlXGDtqEhWm6i36EEmPbxAuPOzTE/hUoHQsbbxwB4LiL8V3T0bbZpTkndcVhb\n1Av41gP1WiMQkvXZfQMXwJ1XaifS5BTg+DmEcufzAoGBAKP83oQ2mcEnzt3Dfhxs\nZx1hEmyC62FMx+ez9A+/Y9v+Y8C/IdYXSwkIwONjz+2JjhHRtm5br+3TCUrqTv/C\ny7O1W4Hc9RTA98a4T3MoCPDJx0wv7Wuo5VKve+0y/+BM+WtLL8NDkZz5GKEQxiyU\neL8rWK/90SNIceU/RfWqRW/r
    -----END PRIVATE KEY-----`
  }
};
