import { Component, OnInit } from '@angular/core';
import { RoomService } from "../shared/room.service";
import { Building, Room } from "../shared/room";
import { BuildingV1Service, GetBuildingDto, GetRoomDto, RoomsV1Service } from '../../../_generated/hka-app-service';

@Component({
  selector: 'app-search-room',
  templateUrl: './search-room.component.html',
  styleUrls: ['./search-room.component.scss'],
})
export class SearchRoomComponent implements OnInit {
  pageTitle = 'Räume';
  roomsOfBuilding: GetRoomDto[];
  rooms: GetRoomDto;
  building: GetBuildingDto;
  buildings: GetBuildingDto[];
  private updateActive: boolean = false;
  private searchparam: any;
  public loading: boolean = false;
  selectedBuilding: GetBuildingDto;

  constructor(private roomService: RoomsV1Service, private readonly buildingService: BuildingV1Service) {
    this.buildings = [];
  }

  ngOnInit() {
    this.getAll();
  }

  getBuilding(searchparam: any) {
    this.searchparam = searchparam;
    if (searchparam) {
      if (Number(searchparam)) {
        this.getBuildingById(searchparam);
      } else {
        this.getBuildingByName(searchparam);
      }
    } else {
      this.getAll();
    }
  }

  getAll() {
    this.loading = true;
    this.getAllBuildings();
  }

  getAllBuildings() {
    this.buildingService.getAllBuildings1().subscribe({
      next: data => {
        this.buildings = data;
      }
    });
  }

  getBuildingById(id: number) {
    this.buildingService.getBuilding(id).subscribe(data => {
      this.loading = false;
      data ? (this.buildings = [data]) : (this.buildings = []);
    });
  }

  getBuildingByName(searchparam: string) {
    this.loading = true;
    this.buildingService.getBuildingsPage1(null, searchparam).subscribe(data => {
      this.loading = false;
      this.buildings = data.content;
    });
  }

  setUpdateBuilding(id: any) {
    this.roomService.getRoom(id).subscribe(data => {
      this.rooms = data;
      this.updateActive = true;
    });
  }

  refreshSearch(building: Building) {
    this.setBuilding(building);
    this.getBuilding(this.searchparam);
  }

  setBuilding(building: GetBuildingDto) {
    this.building = building;
  }

  onSelect(building: GetBuildingDto) {
    this.loading = true;
    this.roomsOfBuilding = [];
    if (building.id) {
      this.roomService.getAllRooms1().subscribe(data => {
        data.forEach(room => {
          if (room.building.id === building.id) {
            this.roomsOfBuilding.push(room);
          }
        });
        this.roomsOfBuilding.sort((a, b) => {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        });
      });
    }
    this.selectedBuilding = building;
    this.loading = false;
  }
}
