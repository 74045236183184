import { HttpClient } from '@angular/common/http';
import { Inject } from '@angular/core';
import { Injectable } from '@angular/core';
import { LoginService } from 'app/login/shared/login.service';
import { environment } from 'environments/environment';
import { throwError as observableThrowError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CourseResponseDto } from '../model/course-response.dto';
import { CreateCourseRequestDto } from '../model/create-course-request.dto';
import { ToastrServiceInt } from '../../../shared/util/toastr.service';
import { Router } from '@angular/router';

@Injectable()
export class CourseService {
  constructor(
    @Inject(HttpClient) private readonly http: HttpClient,
    private loginService: LoginService,
    private toastrService: ToastrServiceInt,
    private readonly _router: Router
  ) {}

  public getAllCourses(): Observable<CourseResponseDto[]> {
    return this.http
      .get<CourseResponseDto[]>(environment.url + `/courses`, { headers: this.loginService.getAuthorizationHeader() })
      .pipe(catchError(this.handleError));
  }

  /**
   * Method for request one course by id
   * @param courseId
   */
  public getCourseById(courseId: number): Observable<CourseResponseDto> {
    return this.http
      .get<CourseResponseDto>(environment.url + `/courses/${courseId}`)
      .pipe(catchError(this.handleError));
  }

  public createCourse(course: CreateCourseRequestDto) {
    return this.http
      .post(environment.url + `/courses`, course, { headers: this.loginService.getAuthorizationHeader() })
      .toPromise()
      .then(() => {
        this.toastrService.setToastrSuccess(
          'Erfolgreich',
          'Studiengang "' + course.name + '" wurde erfolgreich erstellt!',
          {
            timeout: 15000,
          }
        );
        this._router.navigate(['/courses']);
      })
      .catch((err) => {
        this.toastrService.setToastrError('Error', 'Studiengang konnte nicht erstellt werden!', { timeout: 15000 });
      });
  }

  public updateCourse(course: CreateCourseRequestDto, courseId: number) {
    return this.http
      .put(environment.url + `/courses/${courseId}`, course, { headers: this.loginService.getAuthorizationHeader() })
      .toPromise()
      .then(() => {
        this.toastrService.setToastrSuccess(
          'Erfolgreich',
          'Studiengang "' + course.name + '" wurde erfolgreich aktualisiert!',
          {
            timeout: 15000,
          }
        );
        this._router.navigate(['/courses']);
      })
      .catch(() => {
        this.toastrService.setToastrError('Error', 'Studiengang konnte nicht aktualisiert werden!', { timeout: 15000 });
      });
  }

  public deleteCourse(courseId: number) {
    return this.http
      .delete(environment.url + `/courses/${courseId}`, { headers: this.loginService.getAuthorizationHeader() })
      .toPromise()
      .then(() => {
        this.toastrService.setToastrSuccess('Erfolgreich', 'Studiengang wurde erfolgreich gelöscht!', {
          timeout: 15000,
        });
        this._router.navigate(['/courses']);
      })
      .catch(() => {
        // this.toastrService.setToastrError('Error', 'Studiengang konnte nicht gelöscht werden!', { timeout: 15000 });
      });
  }

  private handleError(error: any) {
    let errorMessage: string;
    errorMessage = error.message ? error.message : error.toString();
    this.toastrService.setToastrError('Error', errorMessage, { timeout: 10000 });
    return observableThrowError(errorMessage);
  }
}
