<div class="col-md-8 offset-md-2">
  <h1 id="pageHeadline">News Kategorien</h1>
  <a mat-stroked-button class="btn btn-danger mb-4" role="button" ui-sref="news" routerLink="/newscategory/create">
    <mat-icon>add</mat-icon> Kategorie anlegen
  </a>
  <app-loading-spinner [loading]="loading">
    <div class="row">
      <div *ngFor="let category of sortedNewsCategories" class="mt-2 mb-4 col-sm-6 d-flex">
        <mat-card class="p-0 w-100" appearance="outlined">
          <mat-card-header class="bg-light pt-2 mb-2 pb-2 border-bottom">
            <mat-card-title-group class="flex-row-reverse align-items-center">
              <mat-card-title>
                <h4 class="text-break">{{category.name}}</h4>
              </mat-card-title>
              <img mat-card-lg-image class="mr-3 rounded h-100" src="{{imageBaseURL}}{{category.imageUrl}}"
                alt="{{category.name}}">
            </mat-card-title-group>
          </mat-card-header>
          <mat-card-content class="flex-column p-4 justify-content-between">
            <div class="mb-3">
              <b>Feed: </b> <a class="text-break" href="{{category.feed}}">{{category.feed}}</a>
            </div>
            <div class="mt-3">
              <b>Format: </b> {{category.format}}
            </div>
            <div class="d-flex justify-content-end">
              <a mat-stroked-button class="bg-light d-flex justify-content-center align-items-center" role="button"
                [routerLink]="['/newscategory/edit', category.id]">
                <mat-icon>edit</mat-icon>
                Editieren
              </a>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
    <button *ngIf="!loading" id="goTopBtn" title="Go to Top" (click)="topFunction()">
      <fa-icon [icon]="['fas', 'chevron-circle-up']"></fa-icon>
    </button>
  </app-loading-spinner>