<div id="crUpdatePerson" *ngIf="form">
  <h1 id="pageHeadline">{{ pageTitle }}</h1>

  <mat-form [formGroup]="form" role="form">
    <P style="width: 100%">
      <mat-form-field appearance="legacy" style="width: 100%">
        <mat-label>ID </mat-label>
        <input
          matInput
          style="padding-left: 7px"
          id="idInput"
          placeholder="Not set"
          formControlName="id"
          [readonly]="true"
          required
        />
      </mat-form-field>
    </P>

    <p style="width: 100%">
      <mat-form-field appearance="legacy" style="width: 100%">
        <mat-label for="firstNameInput">Vorname </mat-label>
        <input
          matInput
          style="padding-left: 7px"
          id="firstNameInput"
          placeholder="Vorname"
          formControlName="firstName"
          required
        />
      </mat-form-field>
    </p>

    <p style="width: 100%">
      <mat-form-field appearance="legacy" style="width: 100%">
        <mat-label for="lastNameInput">Nachname </mat-label>
        <input
          matInput
          style="padding-left: 7px"
          id="lastNameInput"
          placeholder="Nachname"
          formControlName="lastName"
          required
        />
      </mat-form-field>
    </p>

    <p style="width: 100%">
      <mat-form-field appearance="legacy" style="width: 100%">
        <mat-label for="emailInput">E-Mail </mat-label>
        <input matInput style="padding-left: 7px" id="emailInput" placeholder="E-Mail" formControlName="email" />
      </mat-form-field>
    </p>

    <p style="width: 100%">
      <mat-form-field appearance="legacy" style="width: 100%">
        <mat-label for="academicDegreeInput">Akademischer Grad </mat-label>
        <input
          matInput
          style="padding-left: 7px"
          id="academicDegreeInput"
          placeholder="Akademischer Grad"
          formControlName="academicDegree"
        />
      </mat-form-field>
    </p>

    <p style="width: 100%">
      <mat-form-field appearance="legacy" style="width: 100%">
        <mat-label for="titleInput">Titel </mat-label>
        <input matInput style="padding-left: 7px" id="titleInput" placeholder="Titel" formControlName="title" />
      </mat-form-field>
    </p>

    <p style="width: 100%">
      <mat-form-field appearance="legacy" style="width: 100%">
        <mat-label for="consultationHourInput">Sprechzeiten </mat-label>
        <input
          matInput
          style="padding-left: 7px"
          id="consultationHourInput"
          placeholder="Sprechzeiten"
          formControlName="consultationHour"
        />
      </mat-form-field>
    </p>

    <p style="width: 100%">
      <mat-form-field appearance="legacy" style="width: 100%">
        <mat-label for="phoneInput">Telefonnummer </mat-label>
        <input matInput style="padding-left: 7px" id="phoneInput" placeholder="Telefonnummer" formControlName="phone" />
      </mat-form-field>
    </p>

    <p style="width: 100%">
      <mat-form-field appearance="legacy" style="width: 100%">
        <mat-label for="imageUrlInput">Bild URL </mat-label>
        <input
          matInput
          style="padding-left: 7px"
          id="imageUrlInput"
          placeholder="Bild URL"
          formControlName="imageUrl"
        />
      </mat-form-field>
    </p>

    <p style="width: 100%; display: flex; justify-content: space-between; flex-wrap: wrap">
      <button mat-flat-button id="cancelBtn" color="warn" (click)="cancelEdit()" style="width: 49%">Abbrechen</button>
      <button
        mat-flat-button
        id="updateBtn"
        (click)="onUpdate()"
        [disabled]="form.pristine || !form.valid"
        style="width: 49%"
        color="warn"
      >
        Update
      </button>
    </p>
  </mat-form>
</div>
