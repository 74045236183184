<header>
  <nav
    [ngClass]="{ 'navbar navbar-expand-lg navbar-dark': true, 'bg-primary': isProduction, 'dev-marker': !isProduction }"
    role="navigation"
  >
    <a class="navbar-brand logo-wrapper" routerLink="/dashboard">
      <img class="app-logo" src="assets/images/hka-app-logo.svg" height="48px" alt="hka-app-logo" />
      <span>HKA-APP</span>
    </a>

    <!-- Brand and toggle get grouped for better mobile display -->
    <button
      type="button"
      class="navbar-toggler"
      data-toggle="collapse"
      data-target="#navbarSupportedContent"
      (click)="isCollapsed = !isCollapsed"
    >
      <span class="navbar-toggler-icon"></span>
    </button>

    <!-- Collect the nav links, forms, and other content for toggling -->
    <div class="collapse navbar-collapse" id="navbarSupportedContent" [ngbCollapse]="isCollapsed">
      <!-- Mobile navigation with toggling -->
      <ul *ngIf="localStorage.getItem('authToken')" class="navbar-nav d-sm-none">
        <li class="nav-item">
          <a class="nav-link" routerLink="/dashboard">Home</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/changerequests">Change-Requests</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/analytics">Analytics</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/newscategory">News-Kategorien</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/courses">Studiengänge</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/searchperson"> Personen</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/searchgeneral"> Allgemein</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/searchcampus"> Gebäude</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/searchstudentunion"> Fachschaften</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/searchoffice"> Sekretariate</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" (click)="logout()"> Logout</a>
        </li>
      </ul>

      <!-- Browser navigation without toggling -->
      <ul *ngIf="localStorage.getItem('authToken')" class="nav navbar-nav mr-auto d-none d-sm-flex">
        <li class="nav-item">
          <a class="nav-link" routerLinkActive="active" routerLink="/dashboard">Home</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLinkActive="active" routerLink="/changerequests">Change-Requests</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLinkActive="active" routerLink="/newscategory">News-Kategorien</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLinkActive="active" routerLink="/courses"> Studiengänge</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLinkActive="active" routerLink="/searchperson"> Personen</a>
        </li>
        <li class="nav-item dropdown" routerLinkActive="active" ngbDropdown>
          <a
            class="nav-link dropdown-toggle"
            routerLinkActive="active"
            ngbDropdownToggle
            role="button"
            aria-haspopup="true"
            aria-expanded="false"
          >
            Campus
          </a>
          <div class="dropdown-menu" aria-labelledby="navbarDropdown" ngbDropdownMenu>
            <a class="dropdown-item" routerLinkActive="active" routerLink="/searchgeneral"> Allgemein</a>
            <a class="dropdown-item" routerLinkActive="active" routerLink="/searchcampus"> Gebäude</a>
            <a class="dropdown-item" routerLinkActive="active" routerLink="/searchstudentunion"> Fachschaften</a>
            <a class="dropdown-item" routerLinkActive="active" routerLink="/searchoffice"> Sekretariate</a>
            <a class="dropdown-item" routerLinkActive="active" routerLink="/searchroom"> Räume</a>
          </div>
        </li>
        <li class="nav-item dropdown" routerLinkActive="active" ngbDropdown>
          <a
            class="nav-link dropdown-toggle"
            routerLinkActive="active"
            ngbDropdownToggle
            role="button"
            aria-haspopup="true"
            aria-expanded="false"
          >
            Pläne und Listen
          </a>
          <div class="dropdown-menu" aria-labelledby="navbarDropdown" ngbDropdownMenu>
            <a class="dropdown-item" routerLinkActive="active" routerLink="/plan/semesterschedule"> Terminplan</a>
            <a class="dropdown-item" routerLinkActive="active" routerLink="/plan/scsl"> Aufgabenplan</a>
            <a class="dropdown-item" routerLinkActive="active" routerLink="/plan/modifycourses"> Modify-Courses</a>
            <a class="dropdown-item" routerLinkActive="active" routerLink="/plan/locked-plans">Stundenpläne gesperrt</a>
            <a class="dropdown-item" routerLinkActive="active" routerLink="/plan/ignoreSemesters"
              >Semester ignorieren</a
            >
          </div>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLinkActive="active" routerLink="/analytics">Analytics</a>
        </li>
      </ul>
      <!-- Right browser navigation -->
      <ul class="nav navbar-nav navbar-right d-none d-sm-flex">
        <li id="webApp" class="wa nav-item">
          <!-- Still old Website; could be something like that: 'https://api.hosts.hka-app.de/swagger-ui'  -->
          <a class="nav-link" href="https://app.hskampus.de/" target="_blank"> WebApp </a>
        </li>
        <li id="linktree" class="fb nav-item">
          <a class="nav-link" href="https://linktr.ee/hka_app" target="_blank">
            <img src="assets/images/linktree-white-icon.png" alt="Linktree-HKA" />
          </a>
        </li>
        <li *ngIf="localStorage.getItem('authToken')" class="nav-item">
          <a class="logout nav-link" (click)="logout()"> Logout</a>
        </li>
      </ul>
    </div>
    <!-- /.navbar-collapse -->
  </nav>
</header>
