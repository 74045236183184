<div>
  <a class="btn btn-outline-primary" [routerLink]="['/person/new']">
    <fa-icon [icon]="['fas', 'plus']"></fa-icon> Neu
  </a>
</div>
<mat-form-field appearance="outline" style="width: 40%">
  <mat-label>Suche nach einer Person</mat-label>
  <input matInput [(ngModel)]="pid" (keyup.enter)="onSearch()" placeholder="Name oder ID eintragen" />
  <button mat-icon-button color="primary" (click)="onSearch()" matSuffix>
    <mat-icon>search</mat-icon>
  </button>
</mat-form-field>
