import { throwError as observableThrowError, Observable } from "rxjs";
import { catchError } from 'rxjs/operators';
import { Injectable, Inject } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { LoginService } from '../../login/shared/login.service';
import { ChangeRequest } from './change-request';

@Injectable()
export class ChangeRequestService {
  constructor(@Inject(HttpClient) private readonly http: HttpClient, private loginService: LoginService) {}

  getAllChangeRequests(): Observable<Array<ChangeRequest>> {
    return this.http.get<any>(environment.url + '/changerequests').pipe(catchError(this.handleError));
  }

  getChangeRequestById(id: number): Observable<ChangeRequest> {
    return this.http.get<any>(environment.url + `/changerequests/${id}`).pipe(catchError(this.handleError));
  }

  getChangeRequestByType(type: string): Observable<Array<ChangeRequest>> {
    return this.http.get<any>(environment.url + `/changerequests/type?type=${type}`).pipe(catchError(this.handleError));
  }

  deleteChangeRequest(id: number): Observable<any> {
    const httpOptions = {
      headers: this.loginService.getAuthorizationHeader()
    };
    return this.http.delete(environment.url + `/changerequests/${id}`, httpOptions).pipe(catchError(this.handleError));
  }

  private handleError(error: any) {
    let errMsg: string;
    errMsg = error.message ? error.message : error.toString();
    console.error(errMsg);
    return observableThrowError(errMsg);
  }
}
