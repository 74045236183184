<div id="crNewRoom" class="col-12" *ngIf="form">
  <h1 id="pageHeadline">{{ pageTitle }}</h1>

  <form [formGroup]="form" role="form">
    <div class="form-group row">
      <mat-form-field appearance="outline" class="col-9">
        <mat-label>Name<span style="color: red"></span></mat-label>
        <input matInput id="NameInput" placeholder="Name eingeben" formControlName="name" required />
      </mat-form-field>
    </div>

    <div class="form-group row">
      <mat-form-field appearance="outline" class="col-9">
        <mat-label>Gebäude<span style="color: red"></span></mat-label>
        <mat-select
          id="buildingsDropdown"
          formControlName="building"
          [(ngModel)]="building"
          [compareWith]="compareBuilding"
          required
        >
          <mat-option [value]="undefined">Gebäude auswählen</mat-option>
          <mat-option *ngFor="let building of buildings" [value]="building">{{ building?.name }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="col-9">
      <button id="cancelBtn" mat-raised-button color="warn" (click)="cancelEdit()">Abbrechen</button>
      <button
        id="updateBtn"
        mat-raised-button
        color="primary"
        (click)="onSubmit()"
        [disabled]="form.pristine || !form.valid"
      >
        Hinzufügen
      </button>
    </div>
  </form>
</div>
