<div id="crUpdateStudentUnion" class="col-12" *ngIf="form">
  <h1 id="pageHeadline">{{ pageTitle }}</h1>

  <form [formGroup]="form" role="form">
    <div class="form-group row">
      <label for="idInput" class="col-9 col-form-label">
        ID
      </label>
      <div class="col-9">
        <input
          id="idInput"
          placeholder="Not set"
          class="form-control"
          formControlName="id"
          [readonly]="true"
          required
        />
      </div>
    </div>

    <div class="form-group row">
      <label for="nameInput" class="col-9 col-form-label"> Name<span style="color: red">*</span> </label>
      <div class="col-9">
        <input id="nameInput" placeholder="Name" class="form-control" formControlName="name" required />
      </div>
    </div>

    <div class="form-group row">
      <label for="buildingsDropdown" class="col-9 col-form-label">Gebäude</label>
      <div class="col-9">
        <select class="form-control" id="buildingsDropdown" formControlName="building" [compareWith]="compareBuilding">
          <option [ngValue]="undefined">Gebäude auswählen</option>
          <option [ngValue]="building" *ngFor="let building of buildings">{{ building?.name }}</option>
        </select>
      </div>
    </div>

    <div class="form-group row">
      <label for="roomsDropdown" class="col-9 col-form-label">
        Räume<span *ngIf="!buildingSelected()" style="color: red">*</span>
      </label>
      <div class="col-9">
        <select
          class="form-control"
          id="roomsDropdown"
          *ngIf="!buildingSelected()"
          required
          formControlName="room"
          [compareWith]="compareRoom"
        >
          <option [ngValue]="undefined">Raum auswählen</option>
          <option *ngFor="let room of rooms" [ngValue]="room">{{ room?.name }}</option>
        </select>
        <select
          class="form-control"
          id="roomsDropdown"
          *ngIf="buildingSelected()"
          formControlName="room"
          [compareWith]="compareRoom"
        >
          <option [ngValue]="undefined">Raum auswählen</option>
          <option *ngFor="let room of rooms" [ngValue]="room">{{ room?.name }}</option>
        </select>
      </div>
    </div>

    <div class="form-group row">
      <label for="emailInput" class="col-9 col-form-label">
        E-Mail
      </label>
      <div class="col-9">
        <input id="emailInput" placeholder="E-Mail" class="form-control" formControlName="email" />
      </div>
    </div>

    <div class="form-group row">
      <label for="meetingInput" class="col-9 col-form-label">
        Sitzung
      </label>
      <div class="col-9">
        <input id="meetingInput" placeholder="Sitzung" class="form-control" formControlName="meeting" />
      </div>
    </div>
    <div class="form-group row">
      <label for="phoneInput" class="col-9 col-form-label">
        Telefonnummer
      </label>
      <div class="col-9">
        <input id="phoneInput" placeholder="Telefonnummer" class="form-control" formControlName="phone" />
      </div>
    </div>
    <div class="form-group row">
      <label for="urlInput" class="col-9 col-form-label">
        URL
      </label>
      <div class="col-9">
        <input id="urlInput" placeholder="URL" class="form-control" formControlName="url" />
      </div>
    </div>
    <div class="form-group row">
      <label for="urlTwoInput" class="col-9 col-form-label">
        2. URL
      </label>
      <div class="col-9">
        <input id="urlTwoInput" placeholder="Zweite URL" class="form-control" formControlName="urlTwo" />
      </div>
    </div>
    <div class="buttons col-9">
      <button id="cancelBtn" class="btn btn-danger" (click)="cancelEdit()">Abbrechen</button>
      <button
        id="updateBtn"
        (click)="updateStudentUnion()"
        [disabled]="form.pristine || !form.valid"
        class="btn btn-danger"
      >
        Update
      </button>
    </div>
  </form>
</div>
