import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {
  faCaretDown,
  faCaretUp,
  faChevronCircleUp,
  faExclamationTriangle,
  faPencilAlt,
  faPlus,
  faSearch,
  faTrashAlt
} from '@fortawesome/free-solid-svg-icons';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxPaginationModule } from 'ngx-pagination';
import { ToastrModule } from 'ngx-toastr';
import { AlertComponent } from './alert/alert.component';
import { AlertService } from './alert/shared/alert.service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SearchCampusComponent } from './campus/search-campus/search-campus.component';
import { SearchformCampusComponent } from './campus/search-campus/searchform-campus/searchform-campus.component';
import { UpdateCampusComponent } from './campus/update-campus/update-campus.component';
import { ChangeRequestComponent } from './change-requests/change-request/change-request.component';
import { CrOverviewComponent } from './change-requests/change-requests.component';
import { EditChangeRequestComponent } from './change-requests/edit-change-request/edit-change-request.component';
import { ChangeRequestService } from './change-requests/shared/change-requests.service';
import { CourseDetailComponent } from './courses/course-details/course-detail.component';
import { CourseSerachComponent } from './courses/course-serach/course-search.component';
import { CoursesOverviewComponent } from './courses/courses-overview/courses-overview.component';
import { CourseService } from './courses/shared/service/course.service';
import { FacultyService } from './courses/shared/service/faculty.service';
import {
  DashboardChangeRequestsComponent
} from './dashboard/dashboard-change-requests/dashboard-change-requests.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { FooterComponent } from './footer/footer.component';
import { NewGeneralComponent } from './general/new-general/new-general.component';
import { SearchGeneralComponent } from './general/search-general/search-general.component';
import { SearchformGeneralComponent } from './general/search-general/searchform-general/searchform-general.component';
import { GeneralService } from './general/shared/general.service';
import { UpdateGeneralComponent } from './general/update-general/update-general.component';
import { GmapComponent } from './gmap/gmap.component';
import { HeaderComponent } from './header/header.component';
import { LoginComponent } from './login/login.component';
import { LoginService } from './login/shared/login.service';
import { CreatenewscategoryComponent } from './newscategory/createnewscategory/createnewscategory.component';
import { EditnewscategoryComponent } from './newscategory/editnewscategory/editnewscategory.component';
import { ShownewscatecoryComponent } from './newscategory/shownewscatecory/shownewscatecory.component';
import { NewOfficeComponent } from './office/new-office/new-office.component';
import { SearchOfficeComponent } from './office/search-office/search-office.component';
import { SearchformOfficeComponent } from './office/search-office/searchform-office/searchform-office.component';
import { OfficeService } from './office/shared/office.service';
import { UpdateOfficeComponent } from './office/update-office/update-office.component';
import { PersonDetailComponent } from './person/person-detail/person-detail.component';
import { SearchPersonComponent } from './person/search-person/search-person.component';
import { SearchformPersonComponent } from './person/search-person/searchform-person/searchform-person.component';
import { PersonService } from './person/shared/person.service';
import { UpdatePersonComponent } from './person/update-person/update-person.component';
import { PlansModule } from './plans/plans.module';
import { NewRoomComponent } from './room/new-room/new-room.component';
import { RoomDetailComponent } from './room/room-detail/room-detail.component';
import { SearchRoomComponent } from './room/search-room/search-room.component';
import { SearchformRoomComponent } from './room/search-room/searchform-room/searchform-room.component';
import { ToastrServiceInt } from './shared/util/toastr.service';
import { UnixToDatePipe } from './shared/util/unixToDatePipe';
import { NewStudentUnionComponent } from './student-union/new-student-union/new-student-union.component';
import {
  SearchformStudentUnionComponent
} from './student-union/search-student-union/searform-student-union/searchform-student-union.component';
import { StudentUnionComponent } from './student-union/search-student-union/student-union.component';
import { StudentUnionService } from './student-union/shared/student-union.service';
import { UpdateStudentUnionComponent } from './student-union/update-student-union/update-student-union.component';
import { RoomService } from './room/shared/room.service';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatSelectModule } from '@angular/material/select';
import { DialogComponent } from './dialog/dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { NewBuildingComponent } from './new-building/new-building.component';
import { ApiModule, Configuration } from '../_generated/hka-app-service';
import { environment } from '../environments/environment';
import { Router } from '@angular/router';
import { AuthInterceptor } from './login/shared/auth.interceptor';
import { NewPersonComponent } from './person/new-person/new-person.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { LoadingSpinnerComponent } from './loading-spinner/loading-spinner.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { AnalyticsComponent } from './analytics/analytics.component';
import { AsyncPipe, registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import { NgxEchartsModule } from 'ngx-echarts';
import { MatMenuModule } from '@angular/material/menu';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatSidenavModule } from '@angular/material/sidenav';
import { TimeRangePipe } from './analytics/pipes/time-range.pipe';
import { AnalyticsWidgetComponent } from './analytics/component/analytics-widget/analytics-widget.component';
import { BarChartComponent } from './analytics/component/pie-chart/bar-chart.component';
import { NumberWidgetComponent } from './analytics/component/number-widget/number-widget.component';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';

export function apiConfigFactory(): Configuration {
  return new Configuration({
    basePath: environment.url,
    apiKeys: { 'Authorization': localStorage.getItem('authToken') },
    credentials: {
      JWT: () => {
        return 'Bearer ' + localStorage.getItem('authToken');
      }
    }
  });
}

registerLocaleData(localeDe);

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    LoginComponent,
    FooterComponent,
    DashboardComponent,
    DashboardChangeRequestsComponent,
    ChangeRequestComponent,
    SearchPersonComponent,
    SearchformPersonComponent,
    CrOverviewComponent,
    UpdatePersonComponent,
    SearchCampusComponent,
    SearchformCampusComponent,
    UpdateCampusComponent,
    UnixToDatePipe,
    CoursesOverviewComponent,
    CourseDetailComponent,
    CourseSerachComponent,
    PersonDetailComponent,
    GmapComponent,
    AlertComponent,
    StudentUnionComponent,
    SearchformStudentUnionComponent,
    UpdateStudentUnionComponent,
    NewStudentUnionComponent,
    GmapComponent,
    ShownewscatecoryComponent,
    CreatenewscategoryComponent,
    EditnewscategoryComponent,
    SearchOfficeComponent,
    UpdateOfficeComponent,
    SearchformOfficeComponent,
    NewOfficeComponent,
    UpdateGeneralComponent,
    SearchGeneralComponent,
    SearchformGeneralComponent,
    NewGeneralComponent,
    SearchRoomComponent,
    SearchformRoomComponent,
    RoomDetailComponent,
    NewRoomComponent,
    EditChangeRequestComponent,
    DialogComponent,
    NewBuildingComponent,
    NewPersonComponent,
    LoadingSpinnerComponent,
    ConfirmationDialogComponent,
    AnalyticsComponent,
    TimeRangePipe,
    AnalyticsWidgetComponent,
    BarChartComponent,
    NumberWidgetComponent
  ],
  imports: [
    AsyncPipe,
    BrowserModule,
    FontAwesomeModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({ positionClass: 'toast-top-right', timeOut: 2000 }),
    NgxPaginationModule,
    PlansModule,
    MatCardModule,
    MatDividerModule,
    MatIconModule,
    MatButtonModule,
    MatInputModule,
    MatListModule,
    MatSelectModule,
    MatProgressSpinnerModule,
    // AgmCoreModule.forRoot({
    //   apiKey: 'AIzaSyB2G6ZZo2DentH8LwYaeOe2Vqgeluf8dIk',
    //   libraries: ['places']
    // }),
    ApiModule.forRoot(apiConfigFactory),
    NgbModule,
    MatDialogModule,
    MatSnackBarModule,
    MatButtonToggleModule,
    NgxEchartsModule.forRoot({
      echarts: () => import('echarts')
    }),
    MatMenuModule,
    MatGridListModule,
    MatSidenavModule,
    MatProgressSpinnerModule
  ],
  providers: [
    LoginService,
    ToastrServiceInt,
    AlertService,
    OfficeService,
    StudentUnionService,
    GeneralService,
    ChangeRequestService,
    PersonService,
    RoomService,
    CourseService,
    FacultyService,
    {
      provide: HTTP_INTERCEPTORS,
      useFactory: function(router: Router) {
        return new AuthInterceptor(router);
      },
      multi: true,
      deps: [Router]
    },
    { provide: LOCALE_ID, useValue: 'de-DE' }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(
      faSearch,
      faChevronCircleUp,
      faPencilAlt,
      faPlus,
      faTrashAlt,
      faCaretUp,
      faCaretDown,
      faExclamationTriangle
    );
  }
}
