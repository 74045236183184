/* eslint-disable @typescript-eslint/member-ordering */
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { OfficeService } from '../shared/office.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ToastrServiceInt } from '../../shared/util/toastr.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Office } from '../shared/office';
import { Building, Room } from '../../room/shared/room';
import { isNullOrUndefined, isUndefined } from 'util';
import {
  BuildingV1Service,
  GetLdapFacultyDto,
  GetPoiOfficeDto,
  GetRoomDto,
  POIV1Service,
  PutPoiOfficeDto,
  RoomsV1Service
} from '../../../_generated/hka-app-service';
import { filter } from 'rxjs/operators';
import { ConfirmationDialogService } from '../../confirmation-dialog/confirmation-dialog.service';

@Component({
  selector: 'app-update-office',
  templateUrl: './update-office.component.html',
  styleUrls: ['./update-office.component.scss'],
  providers: [OfficeService]
})
export class UpdateOfficeComponent implements OnInit, OnDestroy {
  pageTitle = 'Sekretariat bearbeiten';
  @Input()
  office: GetPoiOfficeDto;
  @Output()
  refreshSearch: EventEmitter<Office> = new EventEmitter<Office>();
  form: FormGroup;
  rooms: GetRoomDto[] = [];
  allRooms: GetRoomDto[] = [];
  buildings = [];
  private sub: Subscription;
  private subBuildingChange: Subscription;

  constructor(
    private officeService: POIV1Service,
    private campusService: BuildingV1Service,
    private roomService: RoomsV1Service,
    private readonly formBuilder: FormBuilder,
    private toastrService: ToastrServiceInt,
    private router: Router,
    private route: ActivatedRoute,
    private dialog: ConfirmationDialogService
  ) {
  }

  ngOnInit(): void {
    this.sub = this.route.params.subscribe((params) => {
      const officeId = +params['officeId']; // (+) converts string 'id' to a number
      this.officeService.getPoiOffice1(officeId).subscribe((office) => {
        this.campusService.getAllBuildings1().subscribe((buildings) => {
          this.buildings = buildings;

          let buildingId: number;
          if (!(!office || !office.room || !office.room.building || !office.room.building.id)) {
            buildingId = office.room.building.id;
            this.setupRooms(buildingId);
          }

          this.form = this.formBuilder.group({
            id: office.id,
            name: office.name,
            faculty: office.faculty.id,
            email: office.email,
            openingHours: office.openingHours,
            phone: office.phone,
            room: office.room,
            building: { id: buildingId }
          });

          if (isNullOrUndefined(buildingId)) {
            this.form.get('building').setValue(undefined);
            this.disableRoom();
          }

          this.subBuildingChange = this.form.get('building').valueChanges.subscribe((newBuilding: Building) => {
            if (!isUndefined(newBuilding)) {
              this.setupRooms(this.form.get('building').value.id);
              this.form.get('room').enable();
            } else {
              this.disableRoom();
            }
          });
        });
      });
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();

    if (this.subBuildingChange) {
      this.subBuildingChange.unsubscribe();
    }
  }

  compareBuilding(building1: Building, building2: Building): boolean {
    return building1 && building2 ? building1.id === building2.id : building1 === building2;
  }

  compareRoom(room1: Room, room2: Room): boolean {
    return room1 && room2 ? room1.id === room2.id : room1 === room2;
  }

  setupRooms(buildingId: number) {
    this.roomService.getAllRooms1().subscribe((allRooms) => {
      this.allRooms = allRooms;
      this.rooms = this.calculateRoomsOfBuilding(allRooms, buildingId);
    });
  }

  private calculateRoomsOfBuilding(allRooms: GetRoomDto[], buildingId: number): GetRoomDto[] {
    const roomsOfBuilding = allRooms.filter((room) => room.building && room.building.id === buildingId);
    return roomsOfBuilding.sort(function(room1, room2) {
      const x = room1.name.toLowerCase();
      const y = room2.name.toLowerCase();
      if (x < y) {
        return -1;
      }
      if (x > y) {
        return 1;
      }
      return 0;
    });
  }

  updateOffice() {
    const formValue = this.form.value as Office;

    const updatedOfficeObject = new Office(
      formValue.id,
      formValue.name,
      formValue.faculty,
      formValue.email,
      formValue.openingHours,
      formValue.phone,
      formValue.updatedAt,
      isNullOrUndefined(formValue.room)
        ? null
        : new Room(formValue.room.name, formValue.room.building, formValue.room.id)
    );

    const updatedOffice: PutPoiOfficeDto = {
      id: updatedOfficeObject.id,
      name: updatedOfficeObject.name,
      faculty: updatedOfficeObject.faculty as unknown as GetLdapFacultyDto,
      email: updatedOfficeObject.email,
      openingHours: updatedOfficeObject.openingHours,
      phone: updatedOfficeObject.phone,
      room: {
        id: formValue.room.id,
        isDeleted: formValue.room.isDeleted,
        name: formValue.room.name
      }
    };

    this.officeService.updatePoiOffice1(updatedOffice.id, updatedOffice).subscribe((data) => {
      this.refreshSearch.next(updatedOfficeObject);
      this.router.navigate(['/searchoffice']);
      this.toastrService.setToastrSuccess('Erfolgreich!', 'Ihre Daten wurden gespeichert.');
    });
  }

  cancelEdit() {
    this.dialog
      .askForConfirmation({
        message: 'Möchten Sie die Ansicht wirklich verlassen? Es könnten Änderungen verloren gehen.',
        dialogOptions: ['CANCEL', 'CONFIRM']
      })
      .pipe(filter((result) => result == 'CONFIRM'))
      .subscribe(() => {
        this.router.navigate(['/searchoffice']);
      });
  }

  disableRoom() {
    this.form.get('room').setValue(undefined);
    this.form.get('room').disable();
  }

  buildingSelected() {
    return isNullOrUndefined(this.form.get('building').value);
  }
}
