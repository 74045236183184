<div id="editNewsCategory" class="col-md-8 offset-md-2">
  <h1 id="pageHeadline">News Kategorie updaten</h1>

  <form *ngIf="formGroup" [formGroup]="formGroup" (submit)="onFormSubmit()" class="d-flex flex-column">
    <mat-form-field appearance="fill">
      <mat-label for="categoryNameInputEdit">Titel der Kategorie</mat-label>
      <input matInput type="text" id="categoryNameInputEdit" formControlName="categoryName" required />
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label for="categoryFeedInputEdit">Adresse des Feeds</mat-label>
      <input matInput type="text" id="categoryFeedInputEdit" formControlName="feedUrl" required />
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label for="facultyInput">Fakultät</mat-label>
      <input matInput type="text" id="facultyInput" placeholder="Fakultät ID" formControlName="facultyInput" />
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label for="categoryFeedFormatEdit">Format wählen (bisher: {{ prevFormat }})</mat-label>
      <mat-select id="categoryFeedFormatEdit" formControlName="feedFormat" required>
        <mat-option value="RSS">RSS</mat-option>
        <mat-option value="JSON">JSON</mat-option>
      </mat-select>
    </mat-form-field>

    <div>
      <mat-label>Bild wählen*</mat-label>
      <div class="d-flex align-items-center mb-1">
        <mat-icon>warning</mat-icon>
        <span>Bild ist wegen der Konfiguration des Backends required!</span>
      </div>
      <input type="file" id="categoryFileSelectEdit" (change)="handleFileSelect($event)" formControlName="fileSelect" />
    </div>

    <div *ngIf="bildBase64textString !== ''" class="text-center">
      <img src="data:image/png;base64,{{ bildBase64textString }}" class="img-thumbnail w-25" alt="Bild Kategorie" />
    </div>

    <div class="d-flex justify-content-start mt-2 mb-2">
      <a mat-stroked-button role="button" class="btn btn-secondary mr-2" ui-sref="news" (click)="askForConfirmation()"
        >Zurück</a
      >
      <button
        mat-flat-button
        class="btn rounded btn-success mr-2"
        type="submit"
        [disabled]="!formGroup.valid || bildBase64textString === ''"
      >
        Update
      </button>
      <button mat-flat-button type="button" class="btn btn-danger" (click)="askForConfirmation(true)">
        Kategorie löschen
      </button>
    </div>
  </form>
</div>
