<div id="crNewOffice" class="col-12" *ngIf="form">
  <h1 id="pageHeadline">{{ pageTitle }}</h1>

  <form [formGroup]="form" role="form">
    <div class="form-group row">
      <mat-form-field class="col-9">
        <mat-label>Name</mat-label>
        <input matInput id="nameInput" placeholder="Name" formControlName="name" required />
      </mat-form-field>
    </div>

    <div class="form-group row">
      <mat-form-field class="col-9">
        <mat-label>Fakultät</mat-label>
        <input matInput id="facultyInput" placeholder="Fakultät ID" formControlName="faculty" required />
      </mat-form-field>
    </div>

    <div class="form-group row">
      <mat-form-field class="col-9">
        <mat-label>Gebäude</mat-label>
        <mat-select id="buildingsDropdown" formControlName="building" [compareWith]="compareBuilding">
          <mat-option [value]="undefined">Gebäude auswählen</mat-option>
          <mat-option *ngFor="let building of buildings" [value]="building">{{ building?.name }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="form-group row">
      <mat-form-field class="col-9">
        <mat-label>Räume<span *ngIf="!buildingSelected()" style="color: red">*</span></mat-label>
        <mat-select
          id="roomsDropdown"
          *ngIf="!buildingSelected()"
          required
          formControlName="room"
          [compareWith]="compareRoom"
        >
          <mat-option [value]="undefined">Raum auswählen</mat-option>
          <mat-option *ngFor="let room of rooms" [value]="room">{{ room?.name }}</mat-option>
        </mat-select>
        <mat-select id="roomsDropdown" *ngIf="buildingSelected()" formControlName="room" [compareWith]="compareRoom">
          <mat-option [value]="undefined">Raum auswählen</mat-option>
          <mat-option *ngFor="let room of rooms" [value]="room">{{ room?.name }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="form-group row">
      <mat-form-field class="col-9">
        <mat-label>E-Mail</mat-label>
        <input matInput id="emailInput" placeholder="E-Mail" formControlName="email" />
      </mat-form-field>
    </div>

    <div class="form-group row">
      <mat-form-field class="col-9">
        <mat-label>Öffnungszeiten</mat-label>
        <input matInput id="openingHoursInput" placeholder="Öffnungszeiten" formControlName="openingHours" />
      </mat-form-field>
    </div>

    <div class="form-group row">
      <mat-form-field class="col-9">
        <mat-label>Telefonnummer</mat-label>
        <input matInput id="phoneInput" placeholder="Telefonnummer" formControlName="phone" />
      </mat-form-field>
    </div>

    <div class="buttons col-9">
      <button mat-raised-button id="cancelBtn" color="warn" (click)="cancelEdit()">Abbrechen</button>
      <button
        mat-raised-button
        id="updateBtn"
        color="primary"
        (click)="onSubmit()"
        [disabled]="form.pristine || !form.valid"
      >
        Hinzufügen
      </button>
    </div>
  </form>
</div>
