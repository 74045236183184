import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Building, Room } from '../shared/room';
import { ToastrServiceInt } from '../../shared/util/toastr.service';
import { BuildingV1Service, GetRoomDto, PostRoomDto, RoomsV1Service } from '../../../_generated/hka-app-service';
import { filter } from 'rxjs/operators';
import { ConfirmationDialogService } from '../../confirmation-dialog/confirmation-dialog.service';

@Component({
  selector: 'app-new-room',
  templateUrl: './new-room.component.html',
  styleUrls: ['./new-room.component.scss']
})
export class NewRoomComponent implements OnInit, OnDestroy {
  pageTitle = 'Raum hinzufügen';
  form: FormGroup;

  rooms: GetRoomDto[] = [];
  allRooms: GetRoomDto[] = [];
  buildings = [];

  private sub: Subscription;
  private subBuildingChange: Subscription;

  constructor(
    private campusService: BuildingV1Service,
    private roomService: RoomsV1Service,
    private readonly formBuilder: FormBuilder,
    private toastrService: ToastrServiceInt,
    private router: Router,
    private route: ActivatedRoute,
    private dialog: ConfirmationDialogService
  ) {
  }

  ngOnInit(): void {
    this.sub = this.route.params.subscribe(() => {
      this.campusService.getAllBuildings1().subscribe((buildings) => {
        this.buildings = buildings;

        this.form = this.formBuilder.group({
          name: null,
          building: { id: null }
        });
      });
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
    if (this.subBuildingChange) {
      this.subBuildingChange.unsubscribe();
    }
  }

  compareBuilding(building1: Building, building2: Building): boolean {
    return building1 && building2 ? building1.id === building2.id : building1 === building2;
  }

  onSubmit() {
    const formValue = this.form.value as Room;
    const newRoomDto: PostRoomDto = {
      deleted: formValue.isDeleted,
      name: formValue.name,
      version: 0
    };
    this.roomService.createRoom1(newRoomDto).subscribe(() => {
      this.router.navigate(['/searchroom']);
      this.toastrService.setToastrSuccess('Erfolgreich!', 'Ein neuer Eintrag wurde erstellt.', { progressBar: true });
    });
  }

  cancelEdit() {
    this.dialog
      .askForConfirmation({
        message: 'Möchten Sie die Ansicht wirklich verlassen? Es könnten Änderungen verloren gehen.',
        dialogOptions: ['CANCEL', 'CONFIRM']
      })
      .pipe(filter((result) => result == 'CONFIRM'))
      .subscribe(() => {
        this.router.navigate(['/searchroom']);
      });
  }
}
