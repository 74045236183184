<div class='container-fluid' id='courseDetails'>
  <h1 id='pageHeadline'>{{ pageTitle }}</h1>

  <div *ngIf='isEditMode' class='form-group row'>
    <label for='idInput' class='col-9 col-form-label'> Id<span style='color: red'>*</span> </label>
    <div class='col-9'>
      <input id='idInput' placeholder='Id' class='form-control' [(ngModel)]='course.id' required readonly />
    </div>
  </div>

  <div *ngIf='isEditMode' class='form-group row'>
    <label for='lsfIdInput' class='col-9 col-form-label'> Lsf_Id<span style='color: red'>*</span> </label>
    <div class='col-9'>
      <input
        id='lsfIdInput'
        placeholder='Lsf_id'
        class='form-control'
        [(ngModel)]='course.lsfId'
        required
        readonly
      />
    </div>
  </div>

  <div class='form-group row'>
    <label for='nameInput' class='col-9 col-form-label'> Name<span style='color: red'>*</span> </label>
    <div class='col-9'>
      <input id='nameInput' placeholder='Name' class='form-control' [(ngModel)]='course.name' required />
    </div>
  </div>

  <div *ngIf='isEditMode' class='form-group row'>
    <label for='updatedAt' class='col-9 col-form-label'> Updated at<span style='color: red'></span> </label>
    <div class='col-9'>
      <input
        id='updatedAt'
        placeholder='Wurde noch nicht bearbeitet'
        class='form-control'
        [(ngModel)]='course.updated_at'
        readonly
      />
    </div>
  </div>

  <div class='form-group row'>
    <label for='facultys' class='col-9 col-form-label'> Fakultät wählen<span style='color: red'>*</span></label>
    <div class='col-9'>
      <select
        id='facultys'
        class='form-control'
        [ngModel]='this.course.facultyId'
        required
        (ngModelChange)='onChange($event.toString())'
      >
        <option *ngFor='let faculty of facultys' class='form-control' value='{{ faculty.id }}'>
          {{ faculty.name }}
        </option>
      </select>
      <!--select *ngIf="this.course.faculty === undefined" id="new_faculty" class="form-control" ngModel="this.course.faculty.id" required (ngModelChange)="onChange($event.toString())">
        <option *ngFor="let faculty of facultys" class="form-control" value="{{ faculty.id }}" >
          {{ faculty.name }}
        </option>
      </select-->
    </div>
  </div>

  <div class='form-group row'>
    <label for='semesterNameInput' class='col-9 col-form-label'> Semester<span style='color: red'></span></label>

    <div class='col-9'>
      <div class='card' *ngFor='let semester of course.semesters'>
        <div *ngIf='isEditMode && semester.id' class='form-group row'>
          <label for='semesterIdInput' class='col-9 col-form-label'> Id<span style='color: red'></span> </label>
          <div class='col-9'>
            <input
              id='semesterIdInput'
              placeholder='noch keine Semester-ID'
              class='form-control'
              [ngModel]='semester.id'
              required
              readonly
            />
          </div>
        </div>

        <div class='form-group row'>
          <label for='semesterNameInput' class='col-9 col-form-label'> Name<span style='color: red'>*</span> </label>
          <div class='col-9'>
            <input
              id='semesterNameInput'
              placeholder='Semester-Name'
              class='form-control'
              [(ngModel)]='semester.name'
              required
            />
          </div>
        </div>

        <div class='form-group row'>
          <label for='semesterDiplayNameInput' class='col-9 col-form-label'>
            Anzeige-Name<span style='color: red'>*</span>
          </label>
          <div class='col-9'>
            <input
              id='semesterDiplayNameInput'
              placeholder='Anzeige-Name'
              class='form-control'
              [(ngModel)]='semester.displayName'
              required
            />
          </div>
        </div>

        <div class='form-group row'>
          <label for='semesterNumberInput' class='col-9 col-form-label'>
            Semester-Nummer<span style='color: red'>*</span>
          </label>
          <div class='col-9'>
            <input
              id='semesterNumberInput'
              placeholder='Semester-Nummer'
              class='form-control'
              [(ngModel)]='semester.semesterNumber'
              required
            />
          </div>
        </div>

        <div class='form-group row'>
          <label for='iCalFileHttpLinkInput' class='col-9 col-form-label'>
            Http-Link zum iCal-File<span style='color: red'>*</span>
          </label>
          <div class='col-9'>
            <input
              id='iCalFileHttpLinkInput'
              placeholder='Http-Link zum iCal-File'
              class='form-control'
              [(ngModel)]='semester.iCalFileHttpLink'
              required
            />
          </div>
        </div>

        <div *ngIf='isEditMode && semester.id' class='form-group row'>
          <label for='iCalFileIsValidInput' class='col-9 col-form-label'>
            iCal-File ist valide<span style='color: red'></span>
          </label>
          <div class='col-9'>
            <input
              id='iCalFileIsValidInput'
              type='checkbox'
              class='form-control'
              [ngModel]='semester.isValidICal'
              required
              readonly
            />
          </div>
        </div>

        <div *ngIf='isEditMode && semester.id' class='form-group row'>
          <label for='iCalHashInput' class='col-9 col-form-label'> iCal-Hash<span style='color: red'></span> </label>
          <div class='col-9'>
            <input
              id='iCalHashInput'
              placeholder='noch kein iCal-Hash'
              class='form-control'
              [ngModel]='semester.iCalHash'
              required
              readonly
            />
          </div>
        </div>
      </div>

      <button id='addSemesterBtn' (click)='onAddSemester()'>+ Semester hinzufügen</button>
    </div>
  </div>

  <div class='buttons col-9'>
    <button id='cancelBtn' class='btn btn-danger' (click)='onCancel()'>Abbrechen</button>
    <button id='updateBtn' (click)='onSubmit()' [disabled]='false' class='btn btn-danger'>Hochladen</button>
  </div>
</div>
