<div>
  <mat-card>
    <mat-card-title>Semester Ignorieren</mat-card-title>
    <mat-card-content>
      <div class="d-flex justify-content-center flex-column align-items-start">
        <mat-form-field class="form-field w-30">
          <mat-label>Fakultät auswählen</mat-label>
          <mat-select [(value)]="selectedFaculty" (selectionChange)="setSelectedFaculty(selectedFaculty)">
            <ng-container *ngFor="let faculty of faculties | async">
              <mat-option [value]="faculty">{{ faculty }}</mat-option>
            </ng-container>
            <mat-option [value]="0">Keine Auswahl</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-divider></mat-divider>
        <ng-container> </ng-container>
      </div>
      <mat-selection-list #toIgnore>
        <ng-container *ngFor="let semester of semesters | async">
          <mat-list-option [value]="semester">{{ semester.name }}</mat-list-option>
        </ng-container>
        <button
          mat-stroked-button
          class="btn btn-danger mb-4"
          (click)="ignoreSemesters(toIgnore.selectedOptions.selected)"
        >
          Ausgewählte Semester ignorieren
        </button>
      </mat-selection-list>
    </mat-card-content>
  </mat-card>
  <mat-card style="margin-top: 1em">
    <mat-card-title>Semester von Ignore Liste entfernen</mat-card-title>
    <mat-card-content>
      <mat-selection-list #toUnignore>
        <ng-container *ngFor="let semester of ignoredSemesters | async">
          <mat-list-option [value]="semester">{{ semester }}</mat-list-option>
        </ng-container>
        <button
          mat-stroked-button
          class="btn btn-danger mb-4"
          (click)="unignoreSemesters(toUnignore.selectedOptions.selected)"
        >
          Ausgewählte Semester nicht mehr ignorieren
        </button>
      </mat-selection-list>
    </mat-card-content>
  </mat-card>

  <mat-card style="margin-top: 1em">
    <mat-card-title>Zurücksetzen</mat-card-title>
    <mat-card-content>
      <button mat-stroked-button class="btn btn-danger mb-4" (click)="unignoreAll()">
        Alle Semester nicht mehr ignorieren
      </button>
    </mat-card-content>
  </mat-card>
</div>
