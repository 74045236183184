import { Room } from '../../room/shared/room';
import { Office } from '../../office/shared/office';

export interface Person {
  academicDegree: string;
  consultationHour: string;
  email: string;
  firstName: string;
  id: number;
  imageUrl: string;
  isDeleted: boolean;
  lastName: string;
  ldap: string;
  phone: string;
  remark: string;
  title: string;
  updatedAt: string;
  faculty: string;
}

export class Person implements Person {
  static fromServer(personServer: Person) {
    return new Person(
      personServer.academicDegree,
      personServer.consultationHour,
      personServer.email,
      personServer.firstName,
      personServer.id,
      personServer.imageUrl,
      personServer.isDeleted,
      personServer.lastName,
      personServer.ldap,
      personServer.phone,
      personServer.remark,
      personServer.title,
      personServer.updatedAt,
      personServer.room,
      personServer.faculty,
      personServer.office
    );
  }

  constructor(
    public academicDegree: string,
    public consultationHour: string,
    public email: string,
    public firstName: string,
    public id: number,
    public imageUrl: string,
    public isDeleted: boolean,
    public lastName: string,
    public ldap: string,
    public phone: string,
    public remark: string,
    public title: string,
    public updatedAt: string,
    public room: Room,
    public faculty: string,
    public office: Office // Version  vllt hinzufuegen
  ) {}

  updatePerson(
    id: number,
    firstName: string,
    lastName: string,
    email: string,
    academicDegree: string,
    title: string,
    consultationHour: string,
    phone: string,
    imageUrl: string,
    faculty: string,
    office: Office
  ) {
    this.academicDegree = academicDegree;
    this.consultationHour = consultationHour;
    this.firstName = firstName;
    this.email = email;
    this.id = id;
    this.lastName = lastName;
    this.phone = phone;
    this.title = title;
    this.imageUrl = imageUrl;
    this.faculty = faculty;
    this.office = office;
  }
}
