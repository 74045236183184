<div id="studentUnionList" class="col-md-8 offset-md-2">
  <h1>{{ pageTitle }}</h1>
  <!--searchbar-->
  <div class="row">
    <app-searchform-student-union (id)="getStudentUnion($event)" class="col-12"></app-searchform-student-union>
  </div>
  <app-loading-spinner [loading]="loading">
    <!--cards-->
    <div class="row">
      <div *ngFor="let studentUnion of studentUnions" class="col-sm-6 card-group">
        <div class="card" *ngIf="studentUnion">
          <div class="card-header">
            <div class="width-70 left-break-word">
              <h5 class="mb-0">{{ studentUnion.name }}</h5>
            </div>
            <div class="float-right width-30">
            <button class="btn btn-outline-primary float-right" (click)="onDelete(studentUnion.id, studentUnion.name)">
                <fa-icon [icon]="['fas', 'trash-alt']"></fa-icon>
              </button>
              <a [routerLink]="['/updatestudentunion', studentUnion.id]"
                class="btn btn-outline-primary float-right margin-right">
                <fa-icon [icon]="['fas', 'pencil-alt']"></fa-icon>
              </a>
            </div>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-12 studentUnion">
                <table>
                  <tr>
                    <th>Gebäude:</th>
                    <td>{{ studentUnion.room?.building?.name ? studentUnion.room.building.name : '-' }}</td>
                  </tr>
                  <tr>
                    <th>Raum:</th>
                    <td>{{ studentUnion.room?.name ? studentUnion.room.name : '-' }}</td>
                  </tr>
                  <tr>
                    <th>Sitzung:</th>
                    <td>{{ studentUnion.meeting ? studentUnion.meeting : '-' }}</td>
                  </tr>
                  <tr>
                    <th>Telefon:</th>
                    <td>{{ studentUnion.phone ? studentUnion.phone : '-' }}</td>
                  </tr>
                  <tr>
                    <th>Email:</th>
                    <td>{{ studentUnion.email ? studentUnion.email : '-' }}</td>
                  </tr>
                  <tr>
                    <th>Url:</th>
                    <td>
                      <ng-container *ngIf="studentUnion.url; then urlAvailable; else urlNotAvailable"> </ng-container>
                      <ng-template #urlAvailable>
                        <a href="//{{ studentUnion.url }}" target="_blank">{{ studentUnion.url }}</a>
                      </ng-template>
                      <ng-template #urlNotAvailable>
                        -
                      </ng-template>
                    </td>
                  </tr>
                  <tr>
                    <th>2. Url:</th>
                    <td>
                      <ng-container *ngIf="studentUnion.urlTwo; then urlTwoAvailable; else urlTwoNotAvailable">
                      </ng-container>
                      <ng-template #urlTwoAvailable>
                        <a href="//{{ studentUnion.urlTwo }}" target="_blank">{{ studentUnion.urlTwo }}</a>
                      </ng-template>
                      <ng-template #urlTwoNotAvailable>
                        -
                      </ng-template>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
          <div class="card-footer">
            <small>Letztes Update: {{ studentUnion.updatedAt * 1000 | date: 'dd.MM.yyyy HH:mm:ss' }}</small>
          </div>
        </div>
        <!--no data entries found-->
        <div *ngIf="!loading && (!studentUnion || studentUnions.length === 0)" class="noResults">
          Keinen Datensatz gefunden!
        </div>
      </div>
    </div>
  </app-loading-spinner>
</div>