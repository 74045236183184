<div class="container-fluid">
  <div *ngIf="displayedCourses | async" id="courseList" class="col-md-12">
    <h1>{{ pageTitle }}</h1>
    <!--searchbar-->
    <app-course-search
      class="col-12"
      (change)="onSearch($event.toString())"
      (input)="onSearch($event.toString())"
      (newCourse)="onNewCourse()"
      (search)="onSearch($event.toString())"
      ></app-course-search>
    <app-loading-spinner [loading]="loading">
      <div class="row">
        <div *ngFor="let course of displayedCourses | async" class="col-sm-6 mb-2">
          <mat-card class="p-0 w-100" appearance="outlined" *ngIf="course.facultyId">
            <mat-card-header class="bg-light pt-2 mb-2 pb-2 border-bottom">
              <mat-card-title-group class="flex-row-reverse align-items-center">
                <mat-card-title>
                  <h4 class="text-break">{{ course.name }}</h4>
                </mat-card-title>
              </mat-card-title-group>
            </mat-card-header>
            <mat-card-content class="flex-column p-4 justify-content-between">
              <div class="col-12 course">
                <table>
                  <tr>
                    <th>Id:</th>
                    <td>{{ course.id }}</td>
                  </tr>
                  <tr>
                    <th>Name:</th>
                    <td>{{ course.name }}</td>
                  </tr>
                  <tr>
                    <th>Fakultät:</th>
                    <td>{{ course.facultyId }}</td>
                  </tr>
                  <tr>
                    <th>Semester-Anzahl:</th>
                    <td>{{ course.semesters.length }}</td>
                  </tr>
                  <tr>
                    <th>Semester:</th>
                    <td><i>(mehr Infos in den Details)</i></td>
                  </tr>
                </table>
              </div>
            </mat-card-content>
          </mat-card>
          <!--            TODO: ask server team to add updated at -->
          <!--          <div class='card-footer'>-->
          <!--            <small *ngIf='course.updated_at; else noLastUpdate'>Letztes-->
          <!--              Update: {{ course.updated_at * 1000 | date: 'dd.MM.yyyy HH:mm:ss' }}</small>-->
          <!--            <ng-template #noLastUpdate>Letztes Update: Nicht verfügbar</ng-template>-->
          <!--          </div>-->
        </div>
      </div>
    </app-loading-spinner>
  </div>
</div>
