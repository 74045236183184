import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CreatenewscategoryComponent } from 'app/newscategory/createnewscategory/createnewscategory.component';
import { EditnewscategoryComponent } from 'app/newscategory/editnewscategory/editnewscategory.component';
import { ShownewscatecoryComponent } from 'app/newscategory/shownewscatecory/shownewscatecory.component';
import { SearchCampusComponent } from './campus/search-campus/search-campus.component';
import { UpdateCampusComponent } from './campus/update-campus/update-campus.component';
import { ChangeRequestComponent } from './change-requests/change-request/change-request.component';
import { CrOverviewComponent } from './change-requests/change-requests.component';
import { EditChangeRequestComponent } from './change-requests/edit-change-request/edit-change-request.component';
import { CourseDetailComponent } from './courses/course-details/course-detail.component';
import { CoursesOverviewComponent } from './courses/courses-overview/courses-overview.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { NewGeneralComponent } from './general/new-general/new-general.component';
import { SearchGeneralComponent } from './general/search-general/search-general.component';
import { UpdateGeneralComponent } from './general/update-general/update-general.component';
import { LoginComponent } from './login/login.component';
import { LoginGuard } from './login/login.guard';
import { NewBuildingComponent } from './new-building/new-building.component';
import { NewOfficeComponent } from './office/new-office/new-office.component';
import { SearchOfficeComponent } from './office/search-office/search-office.component';
import { UpdateOfficeComponent } from './office/update-office/update-office.component';
import { NewPersonComponent } from './person/new-person/new-person.component';
import { SearchPersonComponent } from './person/search-person/search-person.component';
import { UpdatePersonComponent } from './person/update-person/update-person.component';
import { PLANS_ROUTES } from './plans/plans.routes';
import { NewRoomComponent } from './room/new-room/new-room.component';
import { SearchRoomComponent } from './room/search-room/search-room.component';
import { NewStudentUnionComponent } from './student-union/new-student-union/new-student-union.component';
import { StudentUnionComponent } from './student-union/search-student-union/student-union.component';
import { UpdateStudentUnionComponent } from './student-union/update-student-union/update-student-union.component';
import { AnalyticsComponent } from './analytics/analytics.component';

const routes: Routes = [
  {
    path: '',
    component: LoginComponent,
    pathMatch: 'full'
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [LoginGuard]
  },
  {
    path: 'changerequest/:id',
    component: ChangeRequestComponent,
    canActivate: [LoginGuard]
  },
  {
    path: 'newscategory',
    component: ShownewscatecoryComponent,
    canActivate: [LoginGuard]
  },
  {
    path: 'courses',
    component: CoursesOverviewComponent,
    canActivate: [LoginGuard]
  },
  {
    path: 'courses/new',
    component: CourseDetailComponent,
    canActivate: [LoginGuard]
  },
  {
    path: 'building/new',
    component: NewBuildingComponent,
    canActivate: [LoginGuard]
  },
  {
    path: 'courses/edit/:courseId',
    component: CourseDetailComponent,
    canActivate: [LoginGuard]
  },
  {
    path: 'searchperson',
    component: SearchPersonComponent,
    canActivate: [LoginGuard]
  },
  {
    path: 'person/new',
    component: NewPersonComponent,
    canActivate: [LoginGuard]
  },
  {
    path: 'updateperson/:personId',
    component: UpdatePersonComponent,
    canActivate: [LoginGuard]
  },
  {
    path: 'searchcampus',
    component: SearchCampusComponent,
    canActivate: [LoginGuard]
  },
  {
    path: 'updatecampus/:campusId',
    component: UpdateCampusComponent,
    canActivate: [LoginGuard]
  },
  {
    path: 'searchoffice',
    component: SearchOfficeComponent,
    canActivate: [LoginGuard]
  },
  {
    path: 'updateoffice/:officeId',
    component: UpdateOfficeComponent,
    canActivate: [LoginGuard]
  },
  {
    path: 'newoffice',
    component: NewOfficeComponent,
    canActivate: [LoginGuard]
  },
  {
    path: 'searchstudentunion',
    component: StudentUnionComponent,
    canActivate: [LoginGuard]
  },
  {
    path: 'updatestudentunion/:studentUnionId',
    component: UpdateStudentUnionComponent,
    canActivate: [LoginGuard]
  },
  {
    path: 'newstudentunion',
    component: NewStudentUnionComponent,
    canActivate: [LoginGuard]
  },
  {
    path: 'searchgeneral',
    component: SearchGeneralComponent,
    canActivate: [LoginGuard]
  },
  {
    path: 'updategeneral/:generalId',
    component: UpdateGeneralComponent,
    canActivate: [LoginGuard]
  },
  {
    path: 'newgeneral',
    component: NewGeneralComponent,
    canActivate: [LoginGuard]
  },
  {
    path: 'changerequests',
    component: CrOverviewComponent,
    canActivate: [LoginGuard]
  },
  {
    path: 'analytics',
    component: AnalyticsComponent,
    canActivate: [LoginGuard]
  },
  {
    path: 'newscategory/create',
    component: CreatenewscategoryComponent,
    canActivate: [LoginGuard]
  },
  {
    path: 'newscategory/edit/:id',
    component: EditnewscategoryComponent,
    canActivate: [LoginGuard]
  },
  {
    path: 'searchroom',
    component: SearchRoomComponent,
    canActivate: [LoginGuard]
  },
  {
    path: 'newroom',
    component: NewRoomComponent,
    canActivate: [LoginGuard]
  },
  {
    path: 'changerequests/edit/:id',
    component: EditChangeRequestComponent,
    canActivate: [LoginGuard]
  },
  {
    path: 'plan',
    children: PLANS_ROUTES,
    canActivate: [LoginGuard]
  },
  {
    path: '**',
    redirectTo: '/'
  }
];

// export const routing: ModuleWithProviders<any> = RouterModule.forRoot(appRoutes, { useHash: true });

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
