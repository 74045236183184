import { Component } from '@angular/core';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {AsyncPipe} from '@angular/common';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';

export interface MyDate {
  startDate: string,
  endDate?: string,
  timeArray?: string[],
  description: string,
  originalString: string,
}

export interface LinePart {
  x?: number,
  y?: number,
  width?: number,
  str: string,
}

export interface Line {
  parts?: LinePart[], // only for pdf and not used in further parsing other than figuring out the lines themselves
  str: string,
}

export interface JoinedDate {
  str: string,
  lines: Line[],
}

export interface DateTime {
  date: Date,
  isFullDayDate: boolean,
}

/*
ToDo: write and fill an interface that has the following properties:
  Has the true texts cells for the 3 lines. (without any modifications)
  The parsed texts for them
  The actual X values for casting these lines [Will probably be different].
  Of course the dates of the final Format (with the FinalDate) format.
 */

export interface DatePage {
  joinedDates: JoinedDate[],
  number: number,
}

@Component({
  selector: 'app-modify-courses',
  templateUrl: './modify-courses.component.html',
  styleUrls: ['./modify-courses.component.scss'],
})
export class ModifyCoursesComponent {
  //textToConvert: string = '';
  id: string = '';
  lsf_id: string = '';
  name: string = '';
  faculty: string = '';
  semester_amount: string = '';
  semester: string = '';



  public async test() {
  }
  
}
