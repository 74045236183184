import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrServiceInt } from '../shared/util/toastr.service';
import { Campus } from '../campus/shared/campus';
import { Subscription } from 'rxjs';
import { ConfirmationDialogService } from '../confirmation-dialog/confirmation-dialog.service';
import { filter } from 'rxjs/operators';
import { BuildingV1Service } from '../../_generated/hka-app-service';

@Component({
  selector: 'app-new-building',
  templateUrl: './new-building.component.html',
  styleUrls: ['./new-building.component.scss']
})
export class NewBuildingComponent implements OnInit {
  pageTitle = 'Neues Gebäude hinzufügen';
  @Input()
  campus: Campus;
  @Output()
  refreshSearch: EventEmitter<Campus> = new EventEmitter<Campus>();
  form: FormGroup;
  id: FormControl<number>;
  name: FormControl<string>;
  street: FormControl<string>;
  houseNumber: FormControl<string>;
  postcode: FormControl<number>;
  coordinates: FormControl<string>;
  coordinatesCenter: FormControl<string>;
  private sub: Subscription;

  constructor(
    private campusService: BuildingV1Service,
    private readonly formBuilder: FormBuilder,
    private router: Router,
    private toastrService: ToastrServiceInt,
    private route: ActivatedRoute,
    private dialog: ConfirmationDialogService
  ) {
  }

  ngOnInit() {
    this.sub = this.route.params.subscribe((params) => {
      // const campusId = +params['campusId']; // (+) converts string 'id' to a number
      const campusId = 1;
      this.campusService.getBuilding(campusId).subscribe((data) => {
        const data1 = JSON.stringify(data);
        this.campus = JSON.parse(data1);
        // this.id = new FormControl<number>(this.campus.id);
        // this.name = new FormControl<string>(this.campus.name);
        // this.street = new FormControl<string>(this.campus.street);
        // this.houseNumber = new FormControl<string>(this.campus.houseNumber);
        // this.postcode = new FormControl<number>(this.campus.postcode);
        // this.coordinatesCenter = new FormControl<string>(this.campus.coordinatesCenter);
        // this.coordinates = new FormControl<string>(this.campus.coordinates);

        this.form = this.formBuilder.group({
          id: this.id,
          name: this.name,
          street: this.street,
          houseNumber: this.houseNumber,
          postcode: this.postcode,
          coordinates: this.coordinates,
          coordinatesCenter: this.coordinatesCenter
        });
      });
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  onUpdate() {
    const campusTmp = new Campus(
      this.id.value,
      this.name.value + 'TEST',
      this.street.value,
      this.houseNumber.value,
      this.postcode.value,
      this.coordinatesCenter.value,
      this.coordinates.value,
      false
    );
    this.campusService.updateBuilding(this.id.value, campusTmp).subscribe((data) => {
      this.refreshSearch.next(campusTmp);
      this.router.navigate(['/searchcampus']);
      this.toastrService.setToastrSuccess('Erfolgreich!', 'Ihre Daten wurden gespeichert.');
    });
  }

  onCreate() {
    const campusTmp = {
      name: this.form.controls.name.value,
      street: this.form.controls.street.value,
      houseNumber: this.form.controls.houseNumber.value,
      postcode: +this.form.controls.postcode.value,
      coordinatesCenter: this.form.controls.coordinatesCenter.value,
      coordinates: this.form.controls.coordinates.value
    };
    this.campusService.createBuilding1(campusTmp).subscribe((data) => {
      // this.refreshSearch.next(campusTmp);
      this.router.navigate(['/searchcampus']);
      this.toastrService.setToastrSuccess('Erfolgreich!', 'Ihre Daten wurden gespeichert.');
    });
  }

  cancelEdit() {
    this.dialog
      .askForConfirmation({
        message: 'Möchten Sie die Ansicht wirklich verlassen? Es könnten Änderungen verloren gehen.',
        dialogOptions: ['CANCEL', 'CONFIRM']
      })
      .pipe(filter((result) => result == 'CONFIRM'))
      .subscribe(() => {
        this.router.navigate(['/searchcampus']);
      });
  }

  updateCoordinatesFromMap(coordinates: any) {
    this.coordinatesCenter = new FormControl<string>(coordinates);
    this.campus.coordinatesCenter = coordinates;
    this.form = this.formBuilder.group({
      id: this.id,
      name: this.name,
      street: this.street,
      houseNumber: this.houseNumber,
      postcode: this.postcode,
      coordinates: this.coordinates,
      coordinatesCenter: this.coordinatesCenter
    });
  }
}
