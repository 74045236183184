import { Component, Input, OnInit } from '@angular/core';
import { ToastrServiceInt } from 'app/shared/util/toastr.service';
import { GetRoomDto, RoomsV1Service } from '../../../_generated/hka-app-service';
import { ConfirmationDialogService } from '../../confirmation-dialog/confirmation-dialog.service';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-room-detail',
  templateUrl: './room-detail.component.html',
  styleUrls: ['./room-detail.component.scss']
})
export class RoomDetailComponent implements OnInit {
  @Input() roomsOfBuilding: GetRoomDto[];
  @Input() buildingId: number;

  constructor(
    private roomService: RoomsV1Service,
    private toastrService: ToastrServiceInt,
    private dialog: ConfirmationDialogService
  ) {
  }

  ngOnInit() {
  }

  onDelete(id: number, name: string) {
    this.dialog
      .askForConfirmation({
        message: 'Sind sie sicher, dass sie den Raum ' + name + ' löschen wollen? ',
        dialogOptions: ['CANCEL', 'CONFIRM']
      })
      .pipe(filter((result) => result == 'CONFIRM'))
      .subscribe(() => {
        this.roomService.deleteRoom(id).subscribe(() => {
          this.roomsOfBuilding = this.roomsOfBuilding.filter((room) => room.id !== id);
          this.toastrService.setToastrSuccess('Erfolgreich!', 'Der Eintrag wurde gelöscht.', { progressBar: true });
        });
      });
  }
}
