<div class='container h-100'>
  <h1 class='text-center'>Analytics-Übersicht</h1>
  <div class='row w-100'>
    <mat-card class='col-12 col-lg-8'>
      <mat-card-title>Konfiguration</mat-card-title>
      <mat-card-content>
        <div class='configuration-line'>
          <mat-form-field>
            <mat-label>Umgebung</mat-label>
            <mat-select [value]='selectedEnvironment'>
              <mat-option *ngFor='let env of environments' [value]='env'>{{ env.label }}</mat-option>
            </mat-select>
          </mat-form-field>

        </div>
      </mat-card-content>
    </mat-card>
    <div class='col-12 col-lg-4 px-0  d-flex'>
      <app-number-widget
        [label]='"Aktive Nutzer"'
        [environment]='selectedEnvironment'
        [metric]='"activeUsers"'
        [startDate]='"30daysAgo"'
      ></app-number-widget>
      <app-number-widget
        [label]='"Neue Nutzer"'
        [environment]='selectedEnvironment'
        [metric]='"newUsers"'
      ></app-number-widget>
      <app-number-widget
        [label]='"Alle Nutzer"'
        [environment]='selectedEnvironment'
        [metric]='"activeUsers"'
        [startDate]='"2015-08-14"'
      ></app-number-widget>
    </div>
  </div>
  <div class='row pt-2 justify-content-stretch w-full align-items-center'>
    <div class='col-12 col-lg-7 px-0'>
      <app-analytics-widget
        [label]='"Aktive Nutzer"'
        [environment]='selectedEnvironment'
        [metrics]='[{name: "activeUsers"}]'
      ></app-analytics-widget>
    </div>
    <div class='col-12 col-lg-5 px-0'>
      <app-bar-chart
        [label]='"Android/IOS Nutzer"'
        [platform]='true'
        [environment]='selectedEnvironment'
        [metrics]='[{name: "eventCount"}]'
        [eventFilter1]='"IOS"'
        [eventFilter2]='"ANDROID"'
      ></app-bar-chart>
    </div>
  </div>
  <div class='row justify-content-stretch pt-2 w-full align-items-stretch'>
    <div class='col-12 col-lg-4 px-0'>
      <app-analytics-widget
        [label]='"News"'
        [environment]='selectedEnvironment'
        [metrics]='[{name: "activeUsers"}]'
        [eventFilter]='"News"'
      ></app-analytics-widget>
    </div>
    <div class='col-12 col-lg-4 px-0'>
      <app-analytics-widget
        [label]='"Mensa"'
        [environment]='selectedEnvironment'
        [metrics]='[{name: "activeUsers"}]'
        [eventFilter]='"Mensa"'
      ></app-analytics-widget>
    </div>
    <div class='col-12 col-lg-4 px-0'>
      <app-analytics-widget
        [label]='"Erst geöffnet"'
        [environment]='selectedEnvironment'
        [metrics]='[{name: "activeUsers"}]'
        [eventFilter]='"first_open"'
      ></app-analytics-widget>
    </div>
  </div>
</div>
