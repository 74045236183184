import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { RoomService } from 'app/room/shared/room.service';
import { Subscription } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
import {
  BuildingV1Service,
  GetBuildingDto,
  GetPersonDto,
  GetRoomDto,
  PersonsV1Service,
  PutPersonDto,
  RoomsV1Service
} from '../../../_generated/hka-app-service';
import { ToastrServiceInt } from '../../shared/util/toastr.service';
import { ConfirmationDialogService } from '../../confirmation-dialog/confirmation-dialog.service';

@Component({
  selector: 'app-update-person',
  templateUrl: './update-person.component.html',
  styleUrls: ['./update-person.component.scss']
})
export class UpdatePersonComponent implements OnInit, OnDestroy {
  pageTitle = 'Person bearbeiten';
  @Input()
  person: GetPersonDto;
  @Output()
  refreshSearch: EventEmitter<GetPersonDto> = new EventEmitter<GetPersonDto>();
  form: FormGroup;
  private sub: Subscription;
  private allRooms: GetRoomDto[] = [];
  private allBuildings: GetBuildingDto[] = [];
  private availableRooms: GetRoomDto[] = [];

  constructor(
    private personService: PersonsV1Service,
    private readonly formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private toastrService: ToastrServiceInt,
    private readonly roomService: RoomsV1Service,
    private readonly roomHelperService: RoomService,
    private readonly buildingService: BuildingV1Service,
    private dialog: ConfirmationDialogService
  ) {
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      id: [0],
      firstName: [''],
      lastName: [''],
      email: [''],
      academicDegree: [''],
      title: [''],
      consultationHour: [''],
      phone: [''],
      building: [null],
      room: [null],
      imageUrl: ['']
    });

    this.sub = this.route.params
      .pipe(
        switchMap((params) => {
          const personId = +params['personId']; // (+) converts string 'id' to a number
          return this.personService.getPerson(personId);
        })
      )
      .subscribe((person) => {
        this.person = person;
        this.form.patchValue({
          id: this.person.id,
          firstName: this.person.firstName,
          lastName: this.person.lastName,
          email: this.person.email,
          academicDegree: this.person.academicDegree,
          title: this.person.title,
          consultationHour: this.person.consultationHour,
          phone: this.person.phone,
          building: this.person.room?.building,
          room: this.person.room,
          imageUrl: this.person.imageUrl
        });
      });

    this.buildingService.getAllBuildings1().subscribe((buildings) => {
      this.allBuildings = buildings.sort((a, b) => a.name.localeCompare(b.name));
    });

    if (this.person.room?.building?.id) {
      const buildingIndex = this.allBuildings.findIndex((building) => this.person.room.building.id === building.id);
      this.form.get('building').setValue(this.allBuildings[buildingIndex], { onlySelf: true });
    }

    // get all rooms inside get all buildings, so we can calculate the first set of available buildings
    this.roomService.getAllRooms1().subscribe((rooms) => {
      this.allRooms = rooms;
      if (this.person.room?.building?.id) {
        this.availableRooms = this.roomHelperService.calculateRoomsOfBuilding(
          this.allRooms,
          this.person.room?.building?.id
        );
        if (this.person.room?.id) {
          const roomIndex = this.availableRooms.findIndex((room) => this.person.room.id === room.id);
          this.form.get('room').setValue(this.availableRooms[roomIndex], { onlySelf: true });
        }
      }
    });

    // whenever the building value changes the rooms are reset
    this.form.get('building').valueChanges.subscribe((value) => {
      this.availableRooms = this.roomHelperService.calculateRoomsOfBuilding(this.allRooms, value.id);
      let defaultRoom = this.availableRooms.find((element) => element.name === 'Kein Raum');
      this.form.get('room').setValue(defaultRoom); // we reset the value of the room if the
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  onUpdate() {
    if (!this.form.dirty) {
      this.toastrService.setToastrWarning('Achtung!', 'Es wurde nichts geändert');
      return;
    }

    const putPersonDto: PutPersonDto = {
      id: this.form.get('id').value,
      email: this.form.get('email').value,
      firstName: this.form.get('firstName').value,
      lastName: this.form.get('lastName').value,

      academicDegree: this.form.get('academicDegree').value,
      consultationHour: this.form.get('consultationHour').value,
      imageUrl: this.form.get('imageUrl').value,
      deleted: this.person.deleted,
      ldap: this.person.ldap,
      phone: this.form.get('phone').value,
      remark: this.person.remark,
      title: this.form.get('title').value,
      faculty: this.person.faculty
    };

    this.personService.updatePerson(this.person.id, putPersonDto).subscribe(
      () => {
        this.refreshSearch.next(putPersonDto);
        this.router.navigate(['/searchperson']);
        this.toastrService.setToastrSuccess('Erfolgreich!', 'Ihre Daten wurden gespeichert.', { progressBar: true });
      },
      (error) => {
        this.toastrService.setToastrError('Fehler!', 'Ihre Daten konnten nicht gespeichert werden!');
      }
    );
  }

  cancelEdit() {
    this.dialog
      .askForConfirmation({
        message: 'Möchten Sie die Ansicht wirklich verlassen? Es könnten Änderungen verloren gehen.',
        dialogOptions: ['CANCEL', 'CONFIRM']
      })
      .pipe(filter((result) => result == 'CONFIRM'))
      .subscribe(() => {
        this.router.navigate(['/searchperson']);
      });
  }
}
