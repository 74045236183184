import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ConfirmationDialogInput, DialogOption } from './confirmation-dialog.model';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss'],
})
export class ConfirmationDialogComponent {
  hasCancel: boolean = false;
  hasConfirm: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ConfirmationDialogInput,
    public dialogRef: MatDialogRef<ConfirmationDialogComponent, DialogOption>
  ) {
    this.hasCancel = this.data.dialogOptions.some((a) => a == 'CANCEL');
    this.hasConfirm = this.data.dialogOptions.some((a) => a == 'CONFIRM');
  }

  onCancel() {
    this.dialogRef.close('CANCEL');
  }

  onConfirm() {
    this.dialogRef.close('CONFIRM');
  }
}
