import { Component, OnInit } from '@angular/core';
import { GetSemesterDto } from '_generated/hka-app-service';
import { IgnoreSemestersService } from 'app/plans/service/ignore-semesters.service';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { MatListOption } from '@angular/material/list';
import { ConfirmationDialogService } from '../../../confirmation-dialog/confirmation-dialog.service';

@Component({
  selector: 'app-ignore-semesters',
  templateUrl: './ignore-semesters.component.html',
  styleUrls: ['./ignore-semesters.component.scss']
})
export class IgnoreSemestersComponent implements OnInit {
  semesters: BehaviorSubject<GetSemesterDto[]> = new BehaviorSubject<GetSemesterDto[]>([]);
  semesterIndex: Observable<Map<number, GetSemesterDto[]>>;
  faculties: Observable<number[]>;
  selectedFaculty: number = 0;
  currentSelectedFaculty: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  ignoredSemesters: Subject<string[]> = new Subject<string[]>();

  constructor(
    private ignoreSemestersService: IgnoreSemestersService,
    private confirmationDialog: ConfirmationDialogService
  ) {
  }

  ngOnInit(): void {
    this.semesterIndex = this.ignoreSemestersService.getAllSemestersByFaculty();
    this.ignoreSemestersService.getIgnoredSemesters().subscribe((a) => {
      this.ignoredSemesters.next(Object.values(a).map((a: any) => a.name));
    });
    this.faculties = this.semesterIndex.pipe(
      map((index) => {
        return Array.from(index.keys());
      })
    );

    combineLatest([this.currentSelectedFaculty, this.semesterIndex, this.ignoredSemesters]).subscribe(
      ([selected, index, ignoredSemesters]) => {
        if (selected == 0) {
          this.semesters.next([]);
        } else if (index.get(selected)) {
          this.semesters.next(index.get(selected).filter((s) => !ignoredSemesters.some((name) => s.name == name)));
        }
      }
    );
  }

  setSelectedFaculty(selectChange: number) {
    if (selectChange) {
      this.currentSelectedFaculty.next(selectChange);
    }
  }

  ignoreSemesters(semesterSelection: MatListOption[]) {
    let ignore: string[] = semesterSelection.map((s) => s.value.name);
    this.ignoreSemestersService.ignoreSemesters(ignore);
    this.ignoredSemesters.next(ignore);
  }

  unignoreSemesters(semesterSelection: MatListOption[]) {
    let unignore: string[] = semesterSelection.map((s) => s.value);
    this.ignoreSemestersService.unignoreSemesters(unignore);
    this.ignoredSemesters.next([]);
  }

  unignoreAll() {
    this.confirmationDialog
      .askForConfirmation({
        message: 'Sind Sie sicher, dass sie alle Semester von der Liste entfernen möchten?',
        dialogOptions: ['CANCEL', 'CONFIRM']
      })
      .pipe(filter((result) => result == 'CONFIRM'))
      .subscribe(() => {
        this.ignoreSemestersService.unignoreAll();
        this.ignoredSemesters.next([]);
      });
  }
}
