import { Inject, Injectable } from '@angular/core';
import { SemesterService } from './semester.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { GetSemesterDto } from '../../../_generated/hka-app-service';
import { StudentUnionService } from 'app/student-union/shared/student-union.service';
import { catchError, map, take } from 'rxjs/operators';
import { LoginService } from 'app/login/shared/login.service';

@Injectable({
  providedIn: 'root',
})
export class IgnoreSemestersService {
  private _headers: HttpHeaders;

  constructor(@Inject(HttpClient) private readonly http: HttpClient, private loginService: LoginService) {}

  getAllSemesters(): Observable<GetSemesterDto[]> {
    return this.http
      .get<GetSemesterDto[]>(environment.url + '/api/semesters', {
        headers: this._headers,
      })
      .pipe(
        take(1),
        map((a) => a.flat())
      );
  }

  getAllSemestersByFaculty(): Observable<Map<number, GetSemesterDto[]>> {
    return this.getAllSemesters().pipe(
      take(1),
      map((semesters) => {
        let ret = new Map<number, GetSemesterDto[]>();
        semesters.forEach((semester) => {
          if (!semester.course.facultyId) {
            semester.course.facultyId = 1;
          }
          if (!ret.has(semester.course.facultyId)) {
            ret.set(semester.course.facultyId, []);
          }
          ret.get(semester.course.facultyId)!.push(semester);
        });
        return ret;
      })
    );
  }

  getIgnoredSemesters() {
    return this.http
      .get<any>(environment.url + '/api/semesters/ignore/v1', {
        headers: this.loginService.getAuthorizationHeader(),
      })
      .pipe(take(1));
  }

  ignoreSemesters(semestersToIgnore: string[]) {
    this.http
      .post<any>(
        environment.url + '/api/semesters/ignore/v1',
        { semestersToIgnore },
        {
          headers: this.loginService.getAuthorizationHeader(),
        }
      )
      .toPromise()
      .catch(() => {
        console.log('Error');
      });
  }

  unignoreSemesters(toUnignore: string[]) {
    this.http
      .delete<any>(environment.url + `/api/semesters/ignore/v1`, {
        headers: this.loginService.getAuthorizationHeader(),
        body: {
          semestersToIgnore: toUnignore,
        },
      })
      .toPromise()
      .catch(() => {
        console.log('Error unignoring semesters');
      });
  }

  unignoreAll() {
    this.http
      .delete<any>(environment.url + '/api/semesters/ignore/v1/clear', {
        headers: this.loginService.getAuthorizationHeader(),
      })
      .toPromise()
      .catch(() => {
        console.log('Error unignoring semesters');
      });
  }
}
