import { Component, EventEmitter, Output } from '@angular/core';
import { PersonService } from '../../shared/person.service';

@Component({
  selector: 'app-searchform-person',
  templateUrl: './searchform-person.component.html',
  styleUrls: ['./searchform-person.component.scss'],
  providers: [PersonService]
})
export class SearchformPersonComponent {
  @Output()
  id: EventEmitter<number> = new EventEmitter<number>();
  pid: number;
  public searchText: string = null;

  constructor() {}

  onSearch() {
    this.id.next(this.pid);
  }
}
