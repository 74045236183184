import { HttpClient, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, throwError as observableThrowError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { LoginService } from '../../login/shared/login.service';
import { Person } from './person';

@Injectable()
export class PersonService {
  constructor(@Inject(HttpClient) private readonly http: HttpClient, private loginService: LoginService) {}

  getAllPersons(): Observable<any> {
    return this.http.get<any>(environment.url + `/persons`).pipe(catchError(this.handleError));
  }

  getPersonById(id: number): Observable<Person> {
    return this.http.get<any>(environment.url + `/persons/${id}`).pipe(catchError(this.handleError));
  }

  getPersonByLastName(lastName: string) {
    return this.http.get<any>(environment.url + `/persons/page?lastName=${lastName}`);
  }

  getPersonByFirstName(firstName: string) {
    return this.http.get<any>(environment.url + `/persons/page?firstName=${firstName}`);
  }

  getPersonByFullName(firstName: string, lastName: string) {
    return this.http.get<any>(environment.url + `/persons/page?firstName=${firstName}&lastName=${lastName}`);
  }

  updatePerson(person: Person, id: number) {
    // console.log(this.loginService.getAuthorizationHeader());

    const updatePerson = {
      academicDegree: person.academicDegree,
      consultationHour: person.consultationHour,
      email: person.email,
      firstName: person.firstName,
      id: person.id,
      imageUrl: person.imageUrl,
      isDeleted: person.isDeleted,
      lastName: person.lastName,
      ldap: person.ldap,
      phone: person.phone,
      remark: person.remark,
      title: person.title,
      faculty: person.faculty,
      room: person.room,
      office: person.office,
    };
    const httpOptions = {
      headers: this.loginService.getAuthorizationHeader(),
    };
    return this.http
      .put<any>(environment.url + `/persons/${id}`, JSON.stringify(updatePerson), httpOptions)
      .pipe(catchError(this.handleError));
  }

  private toPerson(response: HttpResponse<any>) {
    //TODO: hier habe ich json() auf body geändert, da .json() nicht mehr Teil der neuen HttpResponse ist und scheinbar
    // den Body zurückgegeben hat. Hier muss geprüft werden, ob sich die Funktion weiterhin richtig verhält!
    const jsonObject = response.body as Person;
    return Person.fromServer(jsonObject);
  }

  private handleError(error: any) {
    let errorMessage: string;
    errorMessage = error.message ? error.message : error.toString();
    return observableThrowError(errorMessage);
  }
}
