import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { AnalyticsData, AnalyticsEnvironment, AnalyticsService, DateRange } from '../../api/analytics.service';
import { EChartsOption } from 'echarts';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-bar-chart',
  templateUrl: './bar-chart.component.html',
  styleUrls: ['./bar-chart.component.scss']
})
export class BarChartComponent implements OnInit, AfterViewInit {

  @Input() environment!: AnalyticsEnvironment;
  @Input() label: string = 'Analytics';
  @Input() metrics: Array<{ name: string }> = [];
  @Input() eventFilter1?: string;
  @Input() eventFilter2?: string;
  @Input() platform = false;
  analyticsData1: AnalyticsData;
  analyticsData2: AnalyticsData;
  isLoading = true;
  chartOptions: EChartsOption;

  timeLineOptions: DateRange[];
  selectedTimeLineOption: DateRange = DateRange.Today;

  constructor(private readonly analyticsService: AnalyticsService) {
    this.timeLineOptions = Object.values(DateRange);
  }

  ngOnInit(): void {
    this.selectTimeLineOption(DateRange.SevenDays);
  }

  selectTimeLineOption(option: DateRange) {
    this.selectedTimeLineOption = option;
    if (this.environment.propertyId) {
      this.getAnalytics(this.environment.propertyId, this.selectedTimeLineOption);
    }
  }

  private buildEventFilter(filter: string | null): GAFilter | null {
    return filter ? {
      'filter': {
        'fieldName': this.platform ? 'platform' : 'eventName',
        'stringFilter': {
          'value': filter
        }
      }
    } : null;
  }

  getAnalytics(propertyId: string, start: DateRange, end?: DateRange) {
    this.isLoading = true;
    const dateRanges = [{ startDate: start, endDate: end ?? 'today' }];
    const metrics = this.metrics;
    const dimensions = [{ name: 'date' }];
    const dimensionFilter1 = this.buildEventFilter(this.eventFilter1);
    const dimensionFilter2 = this.buildEventFilter(this.eventFilter2);

    // Fetch data from two analytics endpoints
    const fetch1 = this.analyticsService.fetchAnalyticsData(propertyId, dateRanges, metrics, dimensions, dimensionFilter1);
    const fetch2 = this.analyticsService.fetchAnalyticsData(propertyId, dateRanges, metrics, dimensions, dimensionFilter2);

    Promise.all([fetch1, fetch2])
      .then(([response1, response2]) => {
        response1.subscribe({
          next: value1 => {
            this.analyticsData1 = value1;
            response2.subscribe({
              next: value2 => {
                this.analyticsData2 = value2;
                const timelineData = this.combineAnalyticsData(value1, value2);
                this.createBarChart(timelineData);
                this.isLoading = false;
              },
              error: _ => this.isLoading = false
            });
          },
          error: _ => this.isLoading = false
        });
      })
      .catch(error => {
        console.error('Error fetching analytics data:', error);
        this.isLoading = false;
      });
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if (this.chartOptions) {
        this.isLoading = false;
      }
    }, 0);
  }

  private combineAnalyticsData(data1: any, data2: any) {
    const datePipe = new DatePipe('de-DE');
    const timelineData1 = data1.rows.map((row: {
      dimensionValues: { value: string; }[];
      metricValues: { value: string; }[];
    }) => ({
      date: datePipe.transform(this.parseRawDate(row.dimensionValues[0].value), 'dd.MM.yyyy'),
      value: parseInt(row.metricValues[0].value, 10)
    }));

    const timelineData2 = data2.rows?.map((row: {
      dimensionValues: { value: string; }[];
      metricValues: { value: string; }[];
    }) => ({
      date: datePipe.transform(this.parseRawDate(row.dimensionValues[0].value), 'dd.MM.yyyy'),
      value: parseInt(row.metricValues[0].value, 10)
    })) ?? [];

    return timelineData1.map((entry: { date: any; value: any; }, index: string | number) => ({
      date: entry.date,
      value1: entry.value,
      value2: timelineData2[index]?.value || 0
    }));
  }

  private parseRawDate(rawDate: string): Date {
    const year = parseInt(rawDate.substring(0, 4), 10);
    const month = parseInt(rawDate.substring(4, 6), 10) - 1;
    const day = parseInt(rawDate.substring(6, 8), 10);
    return new Date(year, month, day);
  }

  private createBarChart(timelineData: { date: string, value1: number, value2: number }[]) {
    const labels = timelineData.map(entry => entry.date);
    const data1 = timelineData.map(entry => entry.value1);
    const data2 = timelineData.map(entry => entry.value2);

    this.chartOptions = {
      tooltip: {
        trigger: 'axis',
        formatter: (params: any) => {
          const dataPoint1 = params[0];
          const dataPoint2 = params[1];
          return `${dataPoint1.name}<br/>
                  ${this.eventFilter1}: ${dataPoint1.value}<br/>
                  ${this.eventFilter2}: ${dataPoint2.value}`;
        }
      },
      legend: {
        data: [this.eventFilter1, this.eventFilter2]
      },
      xAxis: {
        type: 'category',
        data: labels
      },
      yAxis: {
        type: 'value'
      },
      series: [
        {
          name: this.eventFilter1,
          type: 'bar',
          data: data1,
          itemStyle: {
            color: 'rgb(79,220,91)'
          }
        },
        {
          name: this.eventFilter2,
          type: 'bar',
          data: data2,
          itemStyle: {
            color: 'rgb(224,94,94)'
          }
        }
      ]
    };
  }
}

export interface GAFilter {
  filter: GAFilterBody;
}

export interface GAFilterBody {
  fieldName: string;
  stringFilter: GAStringFilterBody;
}

export interface GAStringFilterBody {
  value: string;
}
