export class Campus {
  constructor(
    public id: number,
    public name: string,
    public street: string,
    public houseNumber: string,
    public postcode: number,
    public coordinatesCenter: string,
    public coordinates: string,
    public deleted: boolean
  ) {}
}
