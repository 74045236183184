<div class="container-fluid">
  <div class="fixed">
    <h1 id="pageHeadline">{{ pageTitle }}</h1>
  </div>

  <div class="row">
    <app-searchform-person (id)="getPerson($event)" class="col-12"></app-searchform-person>
  </div>

  <app-loading-spinner [loading]="loading">
    <div *ngIf="persons.length > 0" class="page-content">
      <mat-list>
        <div *ngFor="let person of persons">
          <a mat-list-item [routerLink]="" class="list-group-item list-group-item-action" (click)="onSelect(person)"
            [class.active]="person === selectedPerson" *ngIf="!person.isDeleted">
            <div>
              <span class="small">{{ person.academicDegree }}</span>
              <br />
              <strong>{{ person.lastName }}</strong>, {{ person.firstName }}
            </div>
          </a>
        </div>
      </mat-list>

      <div class="person-detail">
        <app-person-detail [person]="selectedPerson" (personDeleted)="onPersonDeleted()"></app-person-detail>
      </div>
    </div>

    <div *ngIf="persons.length === 0 && !loading" id="noResults">
      Keinen Datensatz gefunden!
    </div>
  </app-loading-spinner>
</div>
