<div id="crOverview" class="col-md-8 offset-md-2">
  <h1>{{ changeRequests?.length }} {{ pageTitle }}</h1>
  <!--Filters-->
  <div id="changeReguestsFilter" class="d-flex btn-group btn-group-toggle w-100">
    <mat-button-toggle-group
      class="w-100"
      name="options"
      aria-label="Change Request Filter"
      (change)="onChangeType($event.value)"
    >
      <mat-button-toggle class="flex-fill" value="ALL" checked>Alle</mat-button-toggle>
      <mat-button-toggle class="flex-fill" value="PERSON">Person</mat-button-toggle>
      <!--<mat-button-toggle class="flex-fill" value="COURSE">Vorlesung</mat-button-toggle>-->
      <mat-button-toggle class="flex-fill" value="OFFICE">Sekretariat</mat-button-toggle>
      <mat-button-toggle class="flex-fill" value="STUDENTUNION">Fachschaft</mat-button-toggle>
      <mat-button-toggle class="flex-fill" value="GENERAL">Allgemein</mat-button-toggle>
      <!--<mat-button-toggle class="flex-fill" value="LEARNINGPLACE">Lernplatz</mat-button-toggle>-->
    </mat-button-toggle-group>
  </div>
</div>

<app-loading-spinner [loading]="loading">
  <div>
    <div *ngIf="!(changeRequests?.length > 0); else changeRequestsList">
      <div class="text-center">Keine Change-Requests gefunden</div>
    </div>
    <ng-template #changeRequestsList>
      <div *ngFor="let cr of changeRequests">
        <!--Card-->
        <mat-card class="col-md-8 offset-md-2 bg-light mb-5">
          <mat-card-title>
            <span class="h6">
              <b>
                #{{ cr?.id }} [{{
                  cr.type == 'GENERAL'
                    ? 'ALLGEMEIN'
                    : cr.type == 'STUDENTUNION'
                    ? 'FACHSCHAFT'
                    : cr.type == 'PERSON'
                    ? 'PERSON'
                    : cr.type == 'OFFICE'
                    ? 'SEKRETARIAT'
                    : ''
                }}]
              </b>
              <b *ngIf="cr.type == 'PERSON' && !isNullOrUndefined(cr.label)"> - {{ cr.label }}</b>
            </span>
            <div class="float-right h6">
              <span class="card-date">
                {{ cr?.updatedAt }}
              </span>
            </div>
          </mat-card-title>
          <mat-divider></mat-divider>
          <!-- card body -->
          <mat-card-content>
            <!-- Main Table -->
            <div class="table-responsive-sm">
              <table attr.id="table-changes-{{ cr.id }}" class="table table-changes">
                <thead>
                  <tr>
                    <th scope="col">Attribut</th>
                    <th scope="col">Alter Wert</th>
                    <th scope="col">Neuer Wert</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let change of parseComment(cr.comment)">
                    <td>{{ change.name }}</td>
                    <td [innerHTML]="change.before"></td>
                    <td [innerHTML]="change.after"></td>
                  </tr>
                </tbody>
              </table>

              <div *ngIf="cr.hasImage">
                <table class="table table-attachment">
                  <thead>
                    <tr>
                      <th scope="col"><span class="oi oi-paperclip"></span> Anhang</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <figure class="figure">
                        <img
                          src="{{ validationImageBaseUrl }}/{{ cr.id }}.png"
                          class="figure-img img-thumbnail"
                          alt="{{ cr.id }}.png"
                          type="button"
                          data-toggle="modal"
                          [attr.data-target]="'#pictureModal-' + cr.id"
                        />
                        <figcaption class="figure-caption">{{ cr.id }}.png</figcaption>
                      </figure>
                    </tr>
                  </tbody>
                </table>

                <!-- Modal -->
                <div
                  class="modal fade"
                  attr.id="pictureModal-{{ cr.id }}"
                  tabindex="-1"
                  role="dialog"
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                >
                  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                    <div class="modal-content">
                      <div class="modal-header">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div class="modal-body d-flex justify-content-center">
                        <img
                          src="{{ validationImageBaseUrl }}/{{ cr.id }}.png"
                          class="modal-image"
                          alt="A generic square placeholder image with rounded corners in a figure."
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Modal for Image -->
            <ng-template #imageModal>
              <div class="modal-content">
                <div class="modal-header">
                  <button mat-icon-button (click)="closeImageModal()">
                    <mat-icon>close</mat-icon>
                  </button>
                </div>
                <div class="modal-body d-flex justify-content-center">
                  <img
                    [src]="validationImageBaseUrl + '/' + cr.id + '.png'"
                    class="modal-image"
                    alt="{{ cr.id }}.png"
                  />
                </div>
              </div>
            </ng-template>
          </mat-card-content>

          <mat-card-actions class="d-flex justify-content-between">
            <button mat-flat-button color="warn" (click)="onDelete(cr)">Löschen</button>

            <span class="pt-2 font-weight-bold">Benutzer: {{ cr.hsUser }}</span>

            <div class="d-flex">
              <button
                *ngIf="canChangeRequestBeAppliedAutomatically(cr)"
                mat-stroked-button
                color="warn"
                (click)="onEdit(cr)"
                class="mr-2"
              >
                Change Request Bearbeiten
              </button>

              <button
                *ngIf="canChangeRequestBeAppliedAutomatically(cr)"
                mat-flat-button
                color="primary"
                (click)="onUpdate(cr)"
                class="mr-2"
              >
                Bestätigen
              </button>

              <button mat-stroked-button color="primary" (click)="onManuelEdit(cr)">Element bearbeiten</button>
            </div>
          </mat-card-actions>
        </mat-card>
      </div>
    </ng-template>
  </div>
</app-loading-spinner>
