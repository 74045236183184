import { Component } from '@angular/core';
import { OfficeService } from '../shared/office.service';
import { ToastrServiceInt } from 'app/shared/util/toastr.service';
import { isNullOrUndefined } from 'util';
import { BuildingV1Service, GetPoiOfficeDto, POIV1Service, RoomsV1Service } from '../../../_generated/hka-app-service';
import { debounceTime, filter, map, take, tap } from 'rxjs/operators';
import { ConfirmationDialogService } from '../../confirmation-dialog/confirmation-dialog.service';
import { BehaviorSubject, combineLatest, Subscription } from 'rxjs';

@Component({
  selector: 'app-search-office',
  templateUrl: './search-office.component.html',
  styleUrls: ['./search-office.component.scss'],
  providers: [OfficeService]
})
export class SearchOfficeComponent {
  public offices: GetPoiOfficeDto[];
  public displayedOffices: BehaviorSubject<GetPoiOfficeDto[]> = new BehaviorSubject<GetPoiOfficeDto[]>([]);
  public filter: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public officeSubscription: Subscription;
  public loading = false;

  constructor(
    private officeService: POIV1Service,
    private roomService: RoomsV1Service,
    private toastrService: ToastrServiceInt,
    private buildings: BuildingV1Service,
    private dialog: ConfirmationDialogService
  ) {
    this.officeSubscription = combineLatest([
      officeService.getAllPoiOffices1().pipe(
        take(1),
        map((offices) => offices.sort((a, b) => a.name.localeCompare(b.name)))
      ),
      this.filter.asObservable().pipe(debounceTime(200)),
      this.roomService.getAllRooms1().pipe(take(1)),
      this.buildings.getAllBuildings1().pipe(take(1))
    ])
      .pipe(
        map(([offices, filter, rooms, buildings]) => {
          return (
            filter !== ''
              ? offices.filter((office) => {
                return (
                  office.id.toString().toLowerCase().match(filter.toLowerCase()) ||
                  office.name.toLowerCase().match(filter.toLowerCase())
                );
              })
              : offices
          ).map((d) => {
            if (d.room) {
              if (isNullOrUndefined(d.room.building)) {
                d.room.building = {};
                let room = rooms.find((r) => r.id == d.room.id);
                d.room.name = room.name;
                if (room.building.id) {
                  d.room.building.id = room.building.id;
                }
              } else {
                d.room.building = buildings.find((building) => building.id == d.room.building.id) ?? {};
              }
            }
            return d;
          });
        }),
        tap(() => (this.loading = false))
      )
      .subscribe(this.displayedOffices);

    this.displayedOffices.subscribe(console.log);
  }

  public onSearch(text: string): void {
    this.filter.next(text);
  }

  onDelete(id: number, name: string) {
    this.dialog
      .askForConfirmation({
        message: 'Bist du dir sicher, dass du ' + name + ' löschen willst? ',
        dialogOptions: ['CANCEL', 'CONFIRM']
      })
      .pipe(filter((result) => result == 'CONFIRM'))
      .subscribe(() => {
        this.officeService.deletePoiOffice1(id).subscribe(() => {
          this.toastrService.setToastrSuccess('Erfolgreich!', 'Der Eintrag wurde gelöscht.', { progressBar: true });

          this.displayedOffices.next(this.displayedOffices.value.filter((item) => item.id !== id));
        });
      });
  }
}
