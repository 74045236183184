import { Injectable } from '@angular/core';
import { downloadFile } from 'app/shared/util/downloadFile';
import { TaskPlanService } from '../service/task-plan.service';

@Injectable()
export class ManageTaskPlanUsecase {
  constructor(private backendService: TaskPlanService) {}

  public async downloadTaskPlan(): Promise<void> {
    const blob = new Blob([await this.backendService.getTaskPlan()]);
    downloadFile(blob, 'ersti-task.ics');
  }

  public async uploadTaskPlan(iCal): Promise<void> {
    const formData = new FormData();
    formData.append('CreateTaskPlanICal', iCal);
    await this.backendService.uploadTaskPlan(formData);
  }
}
