import { Component, OnInit } from '@angular/core';
import { BuildingV1Service, GetPersonDto, PersonsV1Service, RoomsV1Service } from '../../../_generated/hka-app-service';

@Component({
  selector: 'app-search-person',
  templateUrl: './search-person.component.html',
  styleUrls: ['./search-person.component.scss']
})
export class SearchPersonComponent implements OnInit {
  pageTitle = 'Personen';
  p = 1;
  person: GetPersonDto;
  persons: GetPersonDto[] = [];
  private updateActive = false;
  private searchparam: any;
  selectedPerson: GetPersonDto;
  public loading = false;
  private page = 0;
  private size = 10;

  constructor(
    private personService: PersonsV1Service,
    private roomService: RoomsV1Service,
    private buildingService: BuildingV1Service
  ) {

  }

  ngOnInit() {
    this.getAll();
  }

  getPerson(searchparam: any) {
    this.searchparam = searchparam;
    this.persons.length = 0;
    if (searchparam) {
      if (Number(searchparam)) {
        this.getPersonById(searchparam);
      } else {
        this.getPersonBySearchParam(searchparam);
      }
    } else {
      this.getAll();
    }
  }

  getAll() {
    this.loading = true;
    this.personService.getAllPersons1().subscribe({
      next: async (data: GetPersonDto[]) => {
        this.loading = false;
        this.persons = data.sort((a, b) => {
          if (a.lastName < b.lastName) {
            return -1;
          }
          if (a.lastName > b.lastName) {
            return 1;
          }
          return 0;
        });
      },
      error: err => {
        this.loading = false;
      }
    });
  }

  getPersonById(id: number) {
    this.loading = true;
    this.personService.getPerson(id).subscribe({
      next: data => {
        this.loading = false;
        this.persons[0] = data;
      }
    });
    this.loading = false;
  }

  getPersonBySearchParam(searchParam: string) {
    this.loading = true;
    this.personService.getPersonsByKeywordPage1(searchParam).subscribe(data => {
      this.loading = false;
      if (data) {
        this.persons = data.content;
      }
    });
  }

  setUpdatePerson(id: any) {
    this.personService.getPerson(id).subscribe(data => {
      this.person = data;
      this.updateActive = true;
    });
  }

  refreshSearch(person: GetPersonDto) {
    this.setPerson(person);
    this.getPerson(this.searchparam);
  }

  setPerson(person: GetPersonDto) {
    this.person = person;
  }

  onSelect(person: GetPersonDto) {
    this.loading = true;
    // console.log(person);
    if (person.room) {
      let room;
      let building;
      // get Room
      this.roomService.getRoom(person.room.id).subscribe(data => {
        // get Building
        this.buildingService.getBuilding(data.building.id).subscribe(b => {
          building = b.name;
          person.room['building'] = building;
        });
        room = data.name;
        person.room['name'] = room;
        this.selectedPerson = person;
        this.loading = false;
      });
    } else {
      this.selectedPerson = person;
      this.loading = false;
    }
  }

  onPersonDeleted() {
    this.getAll();
  }
}
