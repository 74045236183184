import { Component, OnInit } from '@angular/core';
import { Campus } from '../shared/campus';
import { MatDialog } from '@angular/material/dialog';
import { BuildingV1Service, GetBuildingDto } from '../../../_generated/hka-app-service';
import { filter } from 'rxjs/operators';
import { ConfirmationDialogService } from '../../confirmation-dialog/confirmation-dialog.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-search-campus',
  templateUrl: './search-campus.component.html',
  styleUrls: ['./search-campus.component.scss']
})
export class SearchCampusComponent implements OnInit {
  public campusList: GetBuildingDto[];
  public campus: GetBuildingDto;
  private searchparam: any;
  private selectedCampus: GetBuildingDto;
  public loading = false;

  constructor(
    private campusService: BuildingV1Service,
    private _dialog: MatDialog,
    private dialog: ConfirmationDialogService,
    private router: Router
  ) {
    this.campusList = [];
  }

  ngOnInit(): void {
    this.getAll();
  }

  setCampus(campus: Campus) {
    this.campus = campus;
  }

  refreshSearch(campus: Campus) {
    this.setCampus(campus);
    this.getCampus(this.searchparam);
  }

  getCampus(searchparam: any) {
    this.searchparam = searchparam;
    this.campusList.length = 0;
    if (searchparam) {
      if (Number(searchparam)) {
        this.getCampusById(searchparam);
        this.getCampusByName(searchparam);
      } else {
        this.getCampusByName(searchparam);
      }
    } else {
      this.getAll();
    }
  }

  getAll() {
    this.loading = true;
    this.campusService.getAllBuildings1().subscribe((data) => {
      this.loading = false;
      this.campusList = data;
      this.campusList.sort((a, b) => a.name.localeCompare(b.name));
    });
  }

  getCampusById(id: number) {
    this.loading = true;
    this.campusService.getBuilding(id).subscribe(
      (campus) => {
        this.loading = false;
        this.campusList[0] = campus;
      },
      (error) => {
        this.loading = false;
      }
    );
  }

  getCampusByName(name: string) {
    this.loading = true;
    this.campusService.getBuildingsPage1(null, name).subscribe(
      (campus) => {
        this.loading = false;
        this.mergeBuildings(campus.content);
      },
      (error) => {
        this.loading = false;
      }
    );
  }

  onSelect(campus: Campus) {
    this.selectedCampus = campus;
  }

  public onDelete(deletedBuilding: Campus): void {
    this.dialog
      .askForConfirmation({
        message: 'Sind Sie sicher, dass Sie Gebäude "' + deletedBuilding.name + '" löschen möchten?',
        dialogOptions: ['CANCEL', 'CONFIRM']
      })
      .pipe(filter((result) => result == 'CONFIRM'))
      .subscribe(() => {
        this.campusList = this.campusList.filter((item) => item.id !== deletedBuilding.id);
        this.campusService.deleteBuilding(deletedBuilding.id);
      });
  }

  public mergeBuildings(arr: GetBuildingDto[]): void {
    // Concatenate the arrays
    this.campusList = this.campusList.concat(arr);

    // Filter out duplicates based on id
    this.campusList = this.campusList.filter((obj, index, self) => index === self.findIndex((t) => t.id === obj.id));
  }
}
