import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpHeaders } from '@angular/common/http';

@Injectable()
export class LoginService {
  static ROLE_ADMIN = 'ROLE_ADMIN';
  static ROLE_DATA_ADMIN = 'ROLE_DATA_ADMIN';

  constructor(private readonly router: Router) {}

  public logout() {
    this.router.navigate(['/']).then();
    localStorage.removeItem('authToken');
  }

  public getAuthorizationHeader(): HttpHeaders {
    const auth = localStorage.authToken;
    return new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${auth}`
    });
  }

  public hasValidToken() {
    const token = localStorage.getItem('authToken');
    if (token) {
      const parsedToken = this.parseJwt(token);
      const time = new Date().getTime();
      if (parsedToken.exp > time / 1000) {
        return true;
      }
    }
    return false;
  }

  public parseJwt(token: string): any {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace('-', '+').replace('_', '/');
    return JSON.parse(window.atob(base64));
  }
}
