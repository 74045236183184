<div id="createNewsCategory" class="col-md-8 offset-md-2">
  <h1 id="pageHeadline">News Kategorie erstellen</h1>

  <form #form="ngForm" (ngSubmit)="createNewsCategory(form.value)" class="d-flex flex-column">
    <mat-form-field appearance="fill">
      <mat-label for="categoryNameInput">Titel der Kategorie</mat-label>
      <input
        matInput
        type="text"
        id="categoryNameInput"
        name="categoryName"
        placeholder="Titel der Kategorie"
        required
        minlength="2"
        ngModel
      />
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label for="categoryFeedInput">Adresse des Feeds</mat-label>
      <input
        matInput
        type="text"
        id="categoryFeedInput"
        placeholder="URL"
        required
        pattern="^https?://.*"
        name="feedUrl"
        ngModel
      />
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label for="facultyInput">Fakultät</mat-label>
      <input
        matInput
        type="text"
        id="facultyInput"
        placeholder="Fakultät ID"
        minlength="1"
        name="facultyInput"
        ngModel
      />
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label for="categoryFeedFormat">Format wählen</mat-label>
      <mat-select id="categoryFeedFormat" name="feedFormat" required>
        <mat-option value="RSS">RSS</mat-option>
        <mat-option value="JSON">JSON</mat-option>
      </mat-select>
    </mat-form-field>
    <div>
      <mat-label>Bild wählen*</mat-label>
      <div class="d-flex align-items-center mb-1">
        <mat-icon>warning</mat-icon>
        <span>Bild ist wegen der Konfiguration des Backends required!</span>
      </div>
      <input type="file" name="fileSelect" id="categoryFileSelect" (change)="handleFileSelect($event)" />
    </div>

    <div *ngIf="bildBase64textString !== ''" class="text-center categoryImageThumbnail">
      <img src="data:image/png;base64,{{ bildBase64textString }}" class="img-thumbnail w-25" alt="Bild Kategorie" />
    </div>

    <div class="d-flex justify-content-start mt-2 mb-2">
      <a mat-stroked-button role="button" ui-sref="news" (click)="askForConfirmation()" class="btn btn-danger mr-2"
        >Abbrechen</a
      >
      <button
        mat-flat-button
        [disabled]="!form.valid || bildBase64textString === ''"
        class="btn btn-success"
        type="submit"
      >
        Anlegen
      </button>
    </div>
  </form>
</div>