import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Schedule } from '../model/schedule';
import { environment } from '../../../environments/environment';
import { ToastrServiceInt } from '../../shared/util/toastr.service';


// There is still the old website linked, but it seems to grab the information from some central-server 'https://api.hosts.hka-app.de/swagger-ui'
@Injectable()
export class SemesterScheduleService {
  private readonly INF_REST_CURRENT_SEMESTER_SCHEDULE_RESOURCE: string =
    'https://www.iwi.hs-karlsruhe.de/iwii/REST/officialcalendar/v2/current';

  private readonly INF_REST_NEXT_SEMESTER_SCHEDULE_RESOURCE: string =
    'https://www.iwi.hs-karlsruhe.de/iwii/REST/officialcalendar/v2/next';

  private readonly BACKEND_CURRENT_SEMESTER_SCHEDULE_RESOURCE: string = environment.url + '/api/calendar/schedule/current';

  private readonly BACKEND_NEXT_SEMESTER_SCHEDULE_RESOURCE: string = environment.url + '/api/calendar/schedule/next';

  constructor(@Inject(HttpClient) private readonly httpClient: HttpClient, private toastrService: ToastrServiceInt) {}

  public async uploadCurrentSchedule(iCal): Promise<any> {
    const token: string = localStorage.getItem('authToken');
    return this.httpClient
      .put(this.BACKEND_CURRENT_SEMESTER_SCHEDULE_RESOURCE, iCal, {
        headers: {
          Authorization: 'Bearer ' + token
        }
      })
      .toPromise()
      .then(() => {
        this.toastrService.setToastrSuccess('Success', 'iCal konnte erfolgreich hochgeladen werden.');
      })
      .catch(() => {
        this.toastrService.setToastrError('Error', 'iCal konnte nicht hochgeladen werden.');
      });
  }

  public async getCurrentSchedule(): Promise<any> {
    return this.httpClient
      .get(this.BACKEND_CURRENT_SEMESTER_SCHEDULE_RESOURCE, {
        responseType: 'blob'
      })
      .toPromise();
  }

  public async uploadNextSchedule(iCal): Promise<void> {
    const token: string = localStorage.getItem('authToken');
    return this.httpClient
      .put(this.BACKEND_NEXT_SEMESTER_SCHEDULE_RESOURCE, iCal, {
        headers: {
          Authorization: 'Bearer ' + token
        }
      })
      .toPromise()
      .then(() => {
        this.toastrService.setToastrSuccess('Success', 'iCal konnte erfolgreich hochgeladen werden.');
      })
      .catch(() => {
        this.toastrService.setToastrError('Error', 'iCal konnte nicht hochgeladen werden.');
      });
  }

  public async getNextSchedule(): Promise<any> {
    return this.httpClient
      .get(this.BACKEND_NEXT_SEMESTER_SCHEDULE_RESOURCE, {
        responseType: 'blob'
      })
      .toPromise();
  }
}
