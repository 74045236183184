import { Component, OnInit } from '@angular/core';
import { ManageTaskPlanUsecase } from '../../usecase/manage-task-plan.usecase';

@Component({
  selector: 'app-scsl-tasks',
  templateUrl: './task-plan.component.html',
  styleUrls: ['./task-plan.component.scss']
})
export class TaskPlanComponent implements OnInit {
  private iCal;

  constructor(private manageTaskPlanUsecase: ManageTaskPlanUsecase) {}

  public async ngOnInit() {}

  public async onUpload() {
    await this.manageTaskPlanUsecase.uploadTaskPlan(this.iCal);
  }

  public async onDownload() {
    await this.manageTaskPlanUsecase.downloadTaskPlan();
  }

  public async onChange(event) {
    if (!event.target.files || event.target.files.length === 0) {
      return;
    }
    this.iCal = event.target.files[0];
  }
}
