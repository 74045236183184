import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from '../../dialog/dialog.component';
import { CoursesV1Service, GetCourseDto } from '../../../_generated/hka-app-service';
import { BehaviorSubject, combineLatest, Observable, Subject, Subscription } from 'rxjs';
import { debounceTime, map, take, tap, withLatestFrom } from 'rxjs/operators';

@Component({
  selector: 'app-courses-overview',
  templateUrl: './courses-overview.component.html',
  styleUrls: ['./courses-overview.component.scss'],
})
export class CoursesOverviewComponent implements OnInit, OnDestroy {
  public pageTitle: string = 'Studiengänge';
  public loading = true;
  public displayedCourses: BehaviorSubject<GetCourseDto[]> = new BehaviorSubject<GetCourseDto[]>([]);
  public filter: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public courseSubscription: Subscription;

  constructor(
    private readonly _router: Router,
    private readonly _courseServiceV1: CoursesV1Service,
    private _dialog: MatDialog
  ) {
    this.courseSubscription = combineLatest([
      this._courseServiceV1.getAllCourses1().pipe(
        take(1),
        map((courses) => courses.sort((a, b) => a.name.localeCompare(b.name)))
      ),
      this.filter.asObservable().pipe(debounceTime(200)),
    ])
      .pipe(
        map(([courses, filter]) => {
          return filter !== ''
            ? courses.filter((course) => {
                return (
                  course.id.toString().toLowerCase().match(filter.toLowerCase()) ||
                  course.name.toLowerCase().match(filter.toLowerCase())
                );
              })
            : courses;
        }),
        tap(() => (this.loading = false))
      )
      .subscribe(this.displayedCourses);
  }

  public ngOnInit(): void {}

  public onNewCourse(): void {
    this._router.navigate(['/courses/new']);
  }

  public onDelete(deletedCourse: GetCourseDto): void {
    const confirmDialog = this._dialog.open(DialogComponent, {
      data: {
        title: 'Studiengang Löschen',
        message: 'Sind Sie sicher, dass Sie den Studiengang "' + deletedCourse.name + '" löschen möchten?',
      },
    });
    confirmDialog.afterClosed().subscribe((result) => {
      if (result === true) {
        this.displayedCourses.next(this.displayedCourses.value.filter((item) => item.id !== deletedCourse.id));
        this._courseServiceV1.deleteCourse(deletedCourse.id);
      }
    });
  }

  public onEdit(courseId: number): void {
    this._router.navigate(['/courses/edit', courseId]);
  }

  public onSearch(text: string): void {
    this.filter.next(text);
  }

  ngOnDestroy() {
    this.courseSubscription.unsubscribe();
  }
}
