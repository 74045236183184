<!--
  Bisher wurde nur die Seite und einzelne Elemente erstellt.
  Sobald der Live Server wieder läuft kann diese Page mit dem Inhalt von "Studiengang" gefüllt werden.
  Ein großer Teil des Codes kann von dort und semester-schedule zusammen kopiert werden.

-->

<div>
  <mat-card>
    <mat-card-title>Modify Courses
    </mat-card-title>
    <mat-card-content>
      <mat-accordion class='convert-subitem'>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              Page Erklärung
            </mat-panel-title>
            <mat-panel-description>
              Erklärungen zu Einzelheiten der Page
            </mat-panel-description>
          </mat-expansion-panel-header>
          <div>
            <div>
              Unabhänging der Parsing-Quelle (PDF oder Text) gelten folgende Regeln und Einschränkungen für die
              Termine
            </div>
            <mat-list role='list'>
              <mat-list-item role='listitem'>
                Termine ohne Zeit: <br>
                Diese werden immer als ganztägige Events interpretiert
              </mat-list-item>
              <mat-list-item role='listitem'>
                Test
              </mat-list-item>
              <mat-list-item role='listitem'>
                Termine mit Start- und Enddatum jedoch nur einer Startuhrzeit von 8:00 <br>
                Werden immer als ganztägige Events interpretiert
              </mat-list-item>
              <mat-list-item role='listitem'>
                Wochentage (Mo. Di. etc.) <br>
                Werden automatisch aus den Texten entfernt.
              </mat-list-item>
              <mat-list-item role='listitem'>
                Daten mit 4 Uhrzeiten
                Werden als 2 separate Events interpretiert mit jeweiliger Start- und Enduhrzeit
              </mat-list-item>
              <mat-list-item role='listitem'>
                Daten mit 3 oder mehr als 4 Uhrzeiten
                Können nicht korrekt interpretiert werden
              </mat-list-item>
              <mat-list-item role='listitem'>
                Uhrzeit-Parsing
                Für das korrekte Parsing muss sich der Client in der korrekten Zeitzone Europe/Berlin befinden
              </mat-list-item>
            </mat-list>
            <p>
              Test
            </p>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
      <mat-divider></mat-divider>
      <div class='convert-subitem'>
        <h2>Studiengang modifizierung</h2>
        <div>
          <h3>Hier sollte ein Dropdown-/Suchmenu für alle Studiengänge sein</h3>
          <mat-form-field appearance='outline' style='width: 300px; padding-left: 1em;'>
            <mat-label>Studiengänge</mat-label>
            <input type='text'
                   placeholder='Pick one'
                   aria-label='Studiengänge'
                   matInput
                   [matAutocomplete]='auto'>
            <mat-autocomplete autoActiveFirstOption #auto='matAutocomplete'>
              <mat-option [value]='1'>1</mat-option>
              <mat-option [value]='1'>2</mat-option>
              <mat-option [value]='1'>3</mat-option>
            </mat-autocomplete>
          </mat-form-field>

          <mat-divider></mat-divider>
          <mat-form-field appearance='outline' style='width: 300px;'>
            <mat-label>ID</mat-label>
            <input matInput style='width: 100%' [(ngModel)]='id'>
            <mat-hint>ID des Objekts</mat-hint>
          </mat-form-field>
          <mat-form-field appearance='outline' style='width: 300px; padding-left: 1em;'>
            <mat-label>LSF_ID</mat-label>
            <input matInput style='width: 100%' [(ngModel)]='lsf_id'>
            <mat-hint>LSF_ID des Objekts</mat-hint>
          </mat-form-field>
          <mat-form-field appearance='outline' style='width: 300px; padding-left: 1em;'>
            <mat-label>Name</mat-label>
            <input matInput style='width: 100%' [(ngModel)]='name'>
            <mat-hint>Name des Objekts</mat-hint>
          </mat-form-field>
          <mat-form-field appearance='outline' style='width: 300px; padding-left: 1em;'>
            <mat-label>Fakultät</mat-label>
            <input matInput style='width: 100%' [(ngModel)]='faculty'>
            <mat-hint>Fakultät des Objekts</mat-hint>
          </mat-form-field>
          <mat-form-field appearance='outline' style='width: 300px; padding-left: 1em;'>
            <mat-label>Semester Anzahl</mat-label>
            <input matInput style='width: 100%' [(ngModel)]='semester_amount'>
            <mat-hint>Semester Anzahl des Objekts</mat-hint>
          </mat-form-field>
          <mat-form-field appearance='outline' style='width: 300px; padding-left: 1em;'>
            <mat-label>Semester</mat-label>
            <input matInput style='width: 100%' [(ngModel)]='semester'>
            <mat-hint>Semester des Objekts</mat-hint>
          </mat-form-field>
        </div>
      </div>
      <mat-divider></mat-divider>

      <div class='convert-subitem'>
        <button mat-raised-button (click)='test()'>
          Speichern
        </button>
      </div>
      <mat-divider></mat-divider>
    </mat-card-content>
  </mat-card>
</div>
