import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, throwError as observableThrowError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { LoginService } from '../../login/shared/login.service';
import { Office } from './office';

@Injectable()
export class OfficeService {
  constructor(@Inject(HttpClient) private readonly http: HttpClient, private loginService: LoginService) {}

  getAllOffices(): Observable<any> {
    return this.http.get<any>(environment.url + `/poi/offices`).pipe(catchError(this.handleError));
  }

  getOfficeById(id: number): Observable<Office> {
    return this.http.get<any>(environment.url + `/poi/offices/${id}`).pipe(catchError(this.handleError));
  }

  getOfficeByName(name: string) {
    return this.http.get<any>(environment.url + `/poi/offices/page?name=${name}`);
  }

  updateOffice(office: Office, id: number) {
    const updateOffice = {
      id: office.id,
      faculty: {
        id: office.faculty,
        newsCategories: [],
        poiCourses: [],
        courses: [],
      },
      name: office.name,
      email: office.email,
      openingHours: office.openingHours,
      phone: office.phone,
      room: office.room,
    };

    const httpOptions = {
      headers: this.loginService.getAuthorizationHeader(),
    };
    return this.http
      .put<any>(environment.url + `/poi/offices/${id}`, JSON.stringify(updateOffice), httpOptions)
      .pipe(catchError(this.handleError));
  }

  createOffice(office: Office) {
    const newOffice = {
      name: office.name,
      faculty: {
        id: office.faculty,
        newsCategories: [],
        poiCourses: [],
        courses: [],
      },
      email: office.email,
      openingHours: office.openingHours,
      phone: office.phone,
      room: office.room,
    };
    const httpOptions = {
      headers: this.loginService.getAuthorizationHeader(),
    };
    return this.http
      .post<any>(environment.url + `/poi/offices/`, JSON.stringify(newOffice), httpOptions)
      .pipe(catchError(this.handleError));
  }

  deleteOfficeById(id: number) {
    const httpOptions = {
      headers: this.loginService.getAuthorizationHeader(),
    };
    return this.http
      .delete<any>(environment.url + `/poi/offices/${id}`, httpOptions)
      .pipe(catchError(this.handleError));
  }

  private handleError(error: any) {
    let errorMessage: string;
    errorMessage = error.message ? error.message : error.toString();
    return observableThrowError(errorMessage);
  }
}
