import { Pipe, PipeTransform } from '@angular/core';
import { DateRange } from '../api/analytics.service';

@Pipe({
  name: 'timeRange'
})
export class TimeRangePipe implements PipeTransform {

  transform(value: DateRange, ...args: unknown[]): unknown {
    switch (value) {
      case DateRange.Today:
        return 'Heute';
      case DateRange.Yesterday:
        return 'Gestern';
      case DateRange.TwoDays:
        return '2 Tage';
      case DateRange.SevenDays:
        return 'Woche';
      case DateRange.OneMonth:
        return '1 Monat';
      default:
        return '';
    }
  }

}
