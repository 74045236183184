import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { GetSemesterDto } from '../../../_generated/hka-app-service';

@Injectable({
  providedIn: 'root'
})
export class SemesterService {

  private _headers: HttpHeaders;

  constructor(private readonly http: HttpClient) {
  }

  public getLockedSemesterPlans(): Observable<LockedSemesterPlans> {
    return this.http.get<LockedSemesterPlans>(environment.url + '/api/semesters', {
      headers: this._headers,
      params: {
        lockedStudyPlan: true
      }
    });
  }

  public getSemesterById(id: number): Observable<GetSemesterDto> {
    return this.http.get<GetSemesterDto>(environment.url + `/api/semesters/${id}`, {
      headers: this._headers
    });
  }

  private addHeaders(): void {
    this._headers = new HttpHeaders();
    this._headers = this._headers.append('Content-Type', 'application/json');
  }
}

export interface LockedSemesterPlans extends Array<number> {
}
