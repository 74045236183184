import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { LoginService } from 'app/login/shared/login.service';
import { environment } from 'environments/environment';
import { ToastrServiceInt } from '../../shared/util/toastr.service';

@Injectable()
export class ModifyCoursesService {
  constructor(
    @Inject(HttpClient) private readonly http: HttpClient,
    private loginService: LoginService,
    private toastrService: ToastrServiceInt
  ) {}

  /**
   * Uploadservice Erstilist
   * @param json
   */
  public uploadErstiList(json: JSON): Promise<void> {
    return this.http
      .put(environment.url + `/modifycourses`, json, { headers: this.loginService.getAuthorizationHeader() })
      .toPromise()
      .then(() => {
        this.toastrService.setToastrSuccess('Erfolgreich', 'Ersti-Liste erfolgreich hochgeladen!', { timeout: 10000 });
      })
      .catch(() => {
        this.toastrService.setToastrError('Error', 'Ersti-Liste konnte nicht hochgeladen werden!', { timeout: 10000 });
      });
  }
}
