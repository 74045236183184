<div class="container-fluid">
  <h1 id="pageHeadline">{{ pageTitle }}</h1>

  <div class="row">
    <app-searchform-room (id)="getBuilding($event)" class="col-12"></app-searchform-room>
  </div>
  <div class="row" *ngIf="buildings.length > 0">
    <div class="col-md-5">
      <ul class="list-group">
        <a class="list-group-item list-group-item-action" (click)="onSelect(building)"
          [class.active]="building === selectedBuilding" *ngFor="let building of buildings">
          <div>
            <span class="small">{{ building.street }} {{ building.houseNumber }}</span>
            <br />
            <strong>{{ building.name }}</strong>
          </div>
        </a>
      </ul>
    </div>
    <div class="col-md-7">
      <app-room-detail [roomsOfBuilding]="roomsOfBuilding"></app-room-detail>
    </div>
  </div>
  <app-loading-spinner [loading]="loading">
    <div *ngIf="buildings.length === 0 && !loading" id="noResults">
      Keinen Datensatz gefunden!
    </div>
  </app-loading-spinner>
</div>