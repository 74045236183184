<div id='generalList' class='col-md-8 offset-md-2'>
  <h1>{{ pageTitle }}</h1>
  <!--searchbar-->
  <div class='row'>
    <app-searchform-general (id)='getGeneral($event)' class='col-12'></app-searchform-general>
  </div>

  <app-loading-spinner [loading]='loading'>
    <!--cards-->
    <div class='row'>
      <ng-container *ngFor='let general of generals'>
        <div class='card' *ngIf='general'>
          <mat-card-header>
            <mat-card-title class='mb-0'>{{ general.name }}</mat-card-title>
            <div class='button-group'>
              <button mat-icon-button color='warn' [routerLink]="['/updategeneral', general.id]">
                <mat-icon>edit</mat-icon>
              </button>
              <button mat-icon-button color='warn' (click)='onDelete(general.id, general.name)'>
                <mat-icon>delete</mat-icon>
              </button>
            </div>
          </mat-card-header>

          <mat-card-content>
            <table>
              <tr>
                <th>Raum:</th>
                <td>{{ general.room?.id ? general.room.name : '-' }}</td>
              </tr>
              <tr *ngIf='general.room?.building && buildingGeneralMap[general.room.building.id]; else notSet'>
                <th>Gebäude:</th>
                <td>{{ buildingGeneralMap[general.room.building.id].name }}</td>
              </tr>
              <ng-template #notSet>
                <tr>
                  <th>Gebäude:</th>
                  <td><span>-</span></td>
                </tr>
              </ng-template>
              <tr>
                <th>Öffnungszeiten:</th>
                <td>{{ general.openingHours ? general.openingHours : '-' }}</td>
              </tr>
              <tr>
                <th>Telefon:</th>
                <td>{{ general.phone ? general.phone : '-' }}</td>
              </tr>
              <tr>
                <th>Email:</th>
                <td>{{ general.email ? general.email : '-' }}</td>
              </tr>
              <tr>
                <th>Url:</th>
                <td>
                  <ng-container *ngIf='general.url; then urlAvailable; else urlNotAvailable'></ng-container>
                  <ng-template #urlAvailable>
                    <a href='//{{ general.url }}' target='_blank'>{{ general.url }}</a>
                  </ng-template>
                  <ng-template #urlNotAvailable>-</ng-template>
                </td>
              </tr>
            </table>
          </mat-card-content>

          <div class='footer'>
            <small>Letztes Update: {{ general.updatedAt * 1000 | date: 'dd.MM.yyyy HH:mm:ss' }}</small>
          </div>
        </div>
      </ng-container>
    </div>

    <!--no data entries found-->
    <div *ngIf='generals.length === 0 && !loading' class='noResults'>Keinen Datensatz gefunden!</div>
  </app-loading-spinner>
</div>
