import { throwError as observableThrowError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Inject, Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { LoginService } from '../../login/shared/login.service';
import { HttpClient } from '@angular/common/http';
import { General } from './general';

@Injectable()
export class GeneralService {
  constructor(@Inject(HttpClient) private readonly http: HttpClient, private loginService: LoginService) {}

  getAllGenerals(): Observable<any> {
    // console.log(CONF.baseUrl + `/poi/generals`);
    return this.http.get<any>(environment.url + `/poi/generals`).pipe(catchError(this.handleError));
  }

  getGeneralById(id: number): Observable<General> {
    return this.http.get<any>(environment.url + `/poi/generals/${id}`).pipe(catchError(this.handleError));
  }

  getGeneralByName(name: string) {
    return this.http.get<any>(environment.url + `/poi/generals/page?name=${name}`);
  }

  updateGeneral(general: General, id: number) {
    const updateGeneral = {
      id: general.id,
      name: general.name,
      openingHours: general.openingHours,
      email: general.email,
      phone: general.phone,
      url: general.url,
      room: general.room
    };
    const httpOptions = {
      headers: this.loginService.getAuthorizationHeader()
    };
    return this.http
      .put<any>(environment.url + `/poi/generals/${id}`, JSON.stringify(updateGeneral), httpOptions)
      .pipe(catchError(this.handleError));
  }

  createGeneral(general: General) {
    const newGeneral = {
      name: general.name,
      openingHours: general.openingHours,
      email: general.email,
      phone: general.phone,
      url: general.url,
      room: general.room
    };
    const httpOptions = {
      headers: this.loginService.getAuthorizationHeader()
    };
    return this.http
      .post<any>(environment.url + `/poi/generals`, JSON.stringify(newGeneral), httpOptions)
      .pipe(catchError(this.handleError));
  }

  deleteGeneralById(id: number) {
    const httpOptions = {
      headers: this.loginService.getAuthorizationHeader()
    };
    return this.http
      .delete<any>(environment.url + `/poi/generals/${id}`, httpOptions)
      .pipe(catchError(this.handleError));
  }

  private handleError(error: any) {
    let errorMessage: string;
    errorMessage = error.message ? error.message : error.toString();
    return observableThrowError(errorMessage);
  }
}
