<div>
  <mat-card>
    <mat-card-title>Terminplan-Konvertierung
    </mat-card-title>
    <mat-card-content>
      <mat-accordion class='convert-subitem'>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              Parsing Erklärung
            </mat-panel-title>
            <mat-panel-description>
              Erklärungen zu Einzelheiten des Parsings
            </mat-panel-description>
          </mat-expansion-panel-header>
          <div>
            <div>
              Unabhänging der Parsing-Quelle (PDF oder Text) gelten folgende Regeln und Einschränkungen für die
              Termine
            </div>
            <mat-list role="list">
              <mat-list-item role="listitem">
                Termine ohne Zeit: <br>
                Diese werden immer als ganztägige Events interpretiert
              </mat-list-item>
              <mat-list-item role="listitem">
                Termine, die nur ein Startdatum und eine Startuhrzeit jedoch keine Enduhrzeit besitzen: <br>
                Bekommen eine einstellbare default-Dauer von {{defaultLengthOfDatesWithoutEndingHours}} Stunden
              </mat-list-item>
              <mat-list-item role="listitem">
                Termine mit Start- und Enddatum jedoch nur einer Startuhrzeit von 8:00 <br>
                Werden immer als ganztägige Events interpretiert
              </mat-list-item>
              <mat-list-item role="listitem">
                Wochentage (Mo. Di. etc.) <br>
                Werden automatisch aus den Texten entfernt.
              </mat-list-item>
              <mat-list-item role="listitem">
                Daten mit 4 Uhrzeiten
                Werden als 2 separate Events interpretiert mit jeweiliger Start- und Enduhrzeit
              </mat-list-item>
              <mat-list-item role="listitem">
                Daten mit 3 oder mehr als 4 Uhrzeiten
                Können nicht korrekt interpretiert werden
              </mat-list-item>
              <mat-list-item role="listitem">
                Uhrzeit-Parsing
                Für das korrekte Parsing muss sich der Client in der korrekten Zeitzone Europe/Berlin befinden
              </mat-list-item>
            </mat-list>
            <p>
              Alle nicht als Datum (inklusive "bis"), Wochentag oder als Uhrzeit erkannten Textbausteine zwischen den Daten werden als Beschreibung des Termins verwendet.<br>
              Die verwendeten Regulären Ausdrücke sind hierbei:<br>
              Datums-Regex: "{{dateRegex}}"<br>
              Wochentags-Regex: "{{dayRegex}}"<br>
              Uhrzeit-Regex: "{{timeRegex}}"
            </p>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
      <mat-divider></mat-divider>
      <div class='convert-subitem'>
        <h2>Daten für Konvertierung</h2>
        <div>
          <mat-form-field appearance='outline' style='width: 300px;'>
            <mat-label>Kalender Name</mat-label>
            <input matInput style='width: 100%' [(ngModel)]='calenderName'>
          </mat-form-field>
          <mat-form-field appearance='outline' style='width: 300px; padding-left: 1em;'>
            <mat-label>Kalender Beschreibung</mat-label>
            <input matInput style='width: 100%' [(ngModel)]='calenderDescription'>
          </mat-form-field>
          <mat-form-field appearance='outline' style='width: 300px; padding-left: 1em;'>
            <mat-label>Default-Länge</mat-label>
            <input matInput style='width: 100%' type='number' [(ngModel)]='defaultLengthOfDatesWithoutEndingHours'>
            <mat-hint>Setzt die Dauer in Stunden für Termine, die eine Start- jedoch keine Enduhrzeit besitzen</mat-hint>
          </mat-form-field>
        </div>
      </div>
      <mat-divider></mat-divider>
      <div class='convert-subitem'>
        <h2>Konvertiere von PDF</h2>
        <mat-form-field appearance='outline' style='width: 100%;'>
          <mat-label>Abbruchswort</mat-label>
          <mat-hint>String für den ab der letzten Seite alle nachkommenden Daten ignoriert werden. Default ist
            "Mittwoch"
          </mat-hint>
          <input matInput style='width: 100%' [(ngModel)]='cutoffStringForPdfParsing'>
        </mat-form-field>
        <div>
          <mat-form-field style='width: 100%;'>
            <ngx-mat-file-input placeholder='Kalender PDF' [accept]="'.pdf'"
                                (change)='parseUploadedPdf($event)'></ngx-mat-file-input>
            <mat-icon matSuffix>folder</mat-icon>
            <mat-hint>Die Selektierung startet die Konvertierung</mat-hint>
          </mat-form-field>
        </div>
      </div>
      <mat-divider></mat-divider>
      <div class='convert-subitem'>
        <h2>Konvertiere aus Text</h2>
        <mat-accordion>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                Erklärung
              </mat-panel-title>
              <mat-panel-description>
                Erklärung des benötigten Inputs
              </mat-panel-description>
            </mat-expansion-panel-header>
            <p>Die Termine werden durch Datum-Strings erkannt und ihnen wird Text bis zum nächsten Datum-Strings
              zugeordnet. Explizit darf kein Zeilenumbruch innerhalb eines Datums vorkommen. (bspw. 21.<br>11.2022) <br>
              Die "bis" sind dabei wichtig, da zusammenhängende Daten von X bis Y durch sie erkannt wird. Sie müssen in
              der Zeile des Startdatums stehen, damit sie korrekt geparsed werden können <br>
            </p>
          </mat-expansion-panel>
        </mat-accordion>
        <div>
          <mat-form-field appearance='fill' style='width: 100%; padding-top: 1em;'>
            <mat-label>Zu parsender Text</mat-label>
          <textarea matInput style='width: 100%' [(ngModel)]='textToConvert'>
          </textarea>
          </mat-form-field>
        </div>
        <button mat-raised-button (click)='parseString(textToConvert)' [disabled]='!textToConvert'>
          Konvertieren
        </button>
      </div>
      <mat-divider *ngIf='generatedIcal'></mat-divider>
      <div *ngIf='generatedIcal' class='convert-subitem'>
        <h2>Generiertes iCal</h2>
        <div>
          <mat-form-field appearance='fill' style='width: 100%;'>
          <textarea matInput style='width: 100%' [(ngModel)]='generatedIcal'>
          </textarea>
          </mat-form-field>
          <button mat-raised-button [cdkCopyToClipboard]="generatedIcal">Copy to clipboard</button>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
  <mat-card>
    <mat-card-title>
      Upload und Download des <b>aktuellen</b> Semesters
    </mat-card-title>
    <mat-card-content>
      <input type='file' (change)='updateCurrent($event)' placeholder='Upload iCal' accept='.ics' />
    </mat-card-content>
    <mat-card-actions>
      <button mat-button (click)='onUploadCurrentToBackend()' [disabled]='!currentIcal'> <!-- disabled until fixed -->
        Upload iCal ins Backend
      </button>
      <button mat-button (click)='onDownloadCurrentFromBackend()'>
        Download iCal von Backend
      </button>
    </mat-card-actions>
  </mat-card>
  <mat-card>
    <mat-card-title>
      Upload und Download des <b>kommenden</b> Semesters
    </mat-card-title>
    <mat-card-content>
      <input type='file' (change)='updateNext($event)' placeholder='Upload iCal' accept='.ics' />
    </mat-card-content>
    <mat-card-actions>
      <button mat-button (click)='onUploadNextToBackend()' [disabled]='!nextIcal'> <!-- disabled until fixed -->
        Upload iCal ins Backend
      </button>
      <button mat-button (click)='onDownloadNextFromBackend()'>
        Download iCal von Backend
      </button>
    </mat-card-actions>
  </mat-card>
</div>
