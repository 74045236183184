import { ToastrService } from 'ngx-toastr';
import { Injectable } from '@angular/core';

@Injectable()
export class ToastrServiceInt {
  constructor(private toastrService: ToastrService) {}

  setToastrError(title: string, msg: string, config?: any) {
    this.toastrService.error(msg, title, config);
  }

  setToastrSuccess(title: string, msg: string, config?: any) {
    this.toastrService.success(msg, title, config);
  }

  setToastrWarning(title: string, msg: string, config?: any) {
    this.toastrService.warning(msg, title, config);
  }
}
