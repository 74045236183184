import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';

@Component({
  selector: 'app-gmap',
  templateUrl: './gmap.component.html',
  styleUrls: ['./gmap.component.scss']
})
export class GmapComponent implements OnInit, OnChanges {
  lat = 51.678418;
  lng = 7.809007;
  zoom = 17;
  @Input() draggable: boolean;
  @Input() coordinates: string;
  @Output() updateCoordinatesEvent = new EventEmitter<string>();

  constructor() {}

  ngOnInit() {
    this.changeCoordinatesFormat();
  }

  ngOnChanges(changes: any) {
    this.changeCoordinatesFormat();
  }

  updateCoordinates($event) {
    this.lat = $event.coords.lat;
    this.lng = $event.coords.lng;
    this.updateCoordinatesEvent.emit(this.lat + ',' + this.lng);
  }

  changeCoordinatesFormat() {
    const splitted: string[] = this.coordinates.split(',');
    this.lat = Number(splitted[0]);
    this.lng = Number(splitted[1]);
  }
}
