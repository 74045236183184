/* eslint-disable no-redeclare */
/* eslint-disable @typescript-eslint/member-ordering */
import { Room } from '../../room/shared/room';
import { Faculty } from '../../shared/models/faculty';

export interface Office {
  id: number;
  name: string;
  faculty: Faculty;
  openingHours: string;
  email: string;
  phone: string;
  updatedAt: string;
}

export class Office implements Office {
  static fromServer(officeServer: Office) {
    return new Office(
      officeServer.id,
      officeServer.name,
      officeServer.faculty,
      officeServer.email,
      officeServer.openingHours,
      officeServer.phone,
      officeServer.updatedAt,
      officeServer.room
    );
  }

  constructor(
    public id: number,
    public name: string,
    public faculty: Faculty,
    public email: string,
    public openingHours: string,
    public phone: string,
    public updatedAt: string,
    public room: Room
  ) {}

  updatePerson(
    id: number,
    name: string,
    email: string,
    updatedAt: string,
    openingHours: string,
    phone: string,
    room: any
  ) {
    this.id = id;
    this.name = name;
    this.email = email;
    this.openingHours = openingHours;
    this.phone = phone;
    this.updatedAt = updatedAt;
    this.room = room;
  }
}
