import { Component, OnInit } from '@angular/core';
import { LoginService } from './shared/login.service';
import { AuthenticationV1Service, Configuration } from '../../_generated/hka-app-service';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TokenResponse } from './login.guard';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  pageTitle = 'HKA-APP';
  loading = false;
  loginForm: FormGroup;
  isPasswordHidden = true;

  constructor(private readonly formBuilder: FormBuilder,
              private readonly loginService: LoginService,
              private apiConfiguration: Configuration,
              private readonly router: Router,
              private readonly snackBar: MatSnackBar,
              private readonly authService: AuthenticationV1Service) {}

  ngOnInit() {
    if(this.loginService.hasValidToken()) {
      this.router.navigate(['/dashboard']).then();
    } else {
      this.loginForm = this.formBuilder.group({
        username: ['', [Validators.required]],
        password: ['', [Validators.required, Validators.pattern(StrongPasswordRegx)]],
      });
    }
  }

  get username() {
    return this.loginForm.get('username') as AbstractControl;
  }

  get password() {
    return this.loginForm.get('password') as AbstractControl;
  }

  login() {
    this.loading = true;
    this.authService.authenticate1(this.loginForm.value)
      .subscribe({
      next: (response: any) => {
        const parsedToken: TokenResponse = this.parseJwt(response.id_token);
        if (this.isAdmin(parsedToken.auth)) {
          localStorage.setItem('authToken', response.id_token);
          this.apiConfiguration.apiKeys = {"Authorization": response.id_token};
          this.router.navigate(['/dashboard']).then();
        }
      },
      error: error => {
        this.loading = false;
        this.snackBar.open(
          error.status === 401 ?
            'Authentifizierung ist fehlgeschlagen. Benutzername oder Passwort ist falsch.' :
            'Ein Fehler ist aufgetreten: ' + (error.message || 'Unbekannter Fehler'),
          'OK',
          { duration: 3000 }
        );
      },
      complete: () => this.loading = false,
    });
  }

  private parseJwt(token?: string): any {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace('-', '+').replace('_', '/');
    return JSON.parse(window.atob(base64));
  }

  private isAdmin(auth: any): boolean {
    return auth.indexOf(LoginService.ROLE_ADMIN) !== -1 || auth.indexOf(LoginService.ROLE_DATA_ADMIN) !== -1;
  }
}

export const StrongPasswordRegx: RegExp =
    /^(?=[^A-Z]*[A-Z])(?=[^a-z]*[a-z])(?=\D*\d).{8,}$/;
