<div id="campusList" class="col-md-8 offset-md-2">
  <h1>Gebäude</h1>
  <!--searchbar-->
  <div class="row">
    <app-searchform-campus (id)="getCampus($event)" class="col-12"></app-searchform-campus>
  </div>
  <app-loading-spinner [loading]="loading">
    <!--cards-->
    <ng-container *ngFor="let campus of campusList">
      <div *ngIf="campus" class="card">
        <mat-card-header>
          <mat-card-title>{{ campus.name }}</mat-card-title>
          <div>
            <button mat-icon-button color="warn" [routerLink]="['/updatecampus', campus.id]">
              <mat-icon>edit</mat-icon>
            </button>
            <button mat-icon-button color="warn" (click)='onDelete(campus)'>
              <mat-icon>delete</mat-icon>
            </button>
          </div>
        </mat-card-header>

        <mat-card-content>
          <table>
            <tr>
              <th>Straße: </th>
              <td>{{ campus.street }} {{ campus.houseNumber }}</td>
            </tr>
            <tr>
              <th>PLZ:</th>
              <td>{{ campus.postcode }}</td>
            </tr>
          </table>
        </mat-card-content>

        <div class="footer">
          <small>Letztes Update: {{ campus.updatedAt * 1000 | date: 'dd.MM.yyyy HH:mm:ss' }}</small>
        </div>
      </div>
    </ng-container>
    <!--no data entries found-->
    <div *ngIf="campusList.length === 0 && !loading" id="noResults">Keinen Datensatz gefunden!</div>
  </app-loading-spinner>
</div>