import { Component, EventEmitter, Output } from '@angular/core';
import { CourseResponseDto } from '../shared/model/course-response.dto';

@Component({
  selector: 'app-course-search',
  templateUrl: './course-search.component.html',
  styleUrls: ['./course-search.component.scss'],
})
export class CourseSerachComponent {
  @Output()
  public search: EventEmitter<string> = new EventEmitter<string>();

  @Output()
  public newCourse: EventEmitter<void> = new EventEmitter<void>();

  public searchedCourses: CourseResponseDto[] = [];
  public searchInput: string = '';

  public onSearch(): void {
    this.search.emit(this.searchInput);
  }

  public onNewCourse(): void {
    this.newCourse.emit();
  }
}
