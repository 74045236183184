<div class="container d-flex justify-content-center align-items-center">
  <mat-card class="col-5 col-lg-6" [formGroup]="loginForm" (submit)="login()">
    <mat-card-title-group>
      <mat-card-title>{{ pageTitle }}</mat-card-title>
      <mat-card-subtitle>Admin-Dashboard</mat-card-subtitle>
    </mat-card-title-group>
    <mat-card-content>
      <div class="d-flex flex-column justify-content-center align-items-center w-100">
        <mat-form-field class="w-100">
          <mat-label>Benutzername</mat-label>
          <input matInput type="text" formControlName="username">
          <mat-hint *ngIf="!password.value">Benutzername eingeben</mat-hint>
          <mat-error *ngIf="username.hasError('required')">Benutzername ist erforderlich</mat-error>
        </mat-form-field>
        <mat-form-field class="w-100">
          <mat-label>Passwort</mat-label>
          <input matInput type="text" formControlName="password" [type]="isPasswordHidden ? 'password' : 'text'">
          <mat-icon role="button" matSuffix (click)="isPasswordHidden = !isPasswordHidden">{{isPasswordHidden ? 'visibility_off' : 'visibility'}}</mat-icon>
          <mat-hint *ngIf="!password.value">Passwort eingeben</mat-hint>
          <mat-error *ngIf="password.hasError('required')">Passwort ist erforderlich</mat-error>
          <mat-error *ngIf="password.hasError('pattern')">Passwort enthält ungültige Zeichen</mat-error>
        </mat-form-field>
      </div>
    </mat-card-content>
    <mat-card-actions align="end">
      <button id="loginBtn" (click)="login()" class="btn btn-primary d-flex align-items-center">
        <app-loading-spinner [loading]="loading"></app-loading-spinner>
        <span [class.pl-2]="loading">Einloggen</span>
      </button>
    </mat-card-actions>
  </mat-card>
</div>