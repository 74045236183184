import { Component } from '@angular/core';
import { ManageSemesterScheduleUsecase } from '../../usecase/manage-semester-schedule.usecase';
import { PDFDocumentProxy } from 'pdfjs-dist/types/web/pdf_find_controller';
import * as pdfJsLib from 'pdfjs-dist';
import { TextItem } from 'pdfjs-dist/types/src/display/api';
import ical, { ICalCalendarMethod, ICalEventData } from 'ical-generator';
import { FileInput } from 'ngx-material-file-input';
import { ToastrServiceInt } from '../../../shared/util/toastr.service';

export interface MyDate {
  startDate: string,
  endDate?: string,
  timeArray?: string[],
  description: string,
  originalString: string,
}

export interface LinePart {
  x?: number,
  y?: number,
  width?: number,
  str: string,
}

export interface Line {
  parts?: LinePart[], // only for pdf and not used in further parsing other than figuring out the lines themselves
  str: string,
}

export interface JoinedDate {
  str: string,
  lines: Line[],
}

export interface DateTime {
  date: Date,
  isFullDayDate: boolean,
}

/*
ToDo: write and fill an interface that has the following properties:
  Has the true texts cells for the 3 lines. (without any modifications)
  The parsed texts for them
  The actual X values for casting these lines [Will probably be different].
  Of course the dates of the final Format (with the FinalDate) format.
 */

export interface DatePage {
  joinedDates: JoinedDate[],
  number: number,
}

@Component({
  selector: 'app-semester-schedule',
  templateUrl: './semester-schedule.component.html',
  styleUrls: ['./semester-schedule.component.scss']
})
export class SemesterScheduleComponent {
  private cal;
  textToConvert: string = '';
  dateRegex = /\d\d?\.\d\d?\.\d\d\d\d/g;
  timeRegex = /(\d\d?[:.]\d\d)|(\d.*Uhr)/g;
  dayRegex = /Mo\.|Di\.|Mi\.|Do\.|Fr\.|Sa\.|So\.|Mo |Di |Mi |Do |Fr |Sa |So /g;
  cutoffStringForPdfParsing = 'Mittwochs';
  pages: DatePage[] = [];
  defaultLengthOfDatesWithoutEndingHours = 2;
  generatedIcal: string = '';
  calenderDescription: string = '';
  calenderName: string = '';
  pdfFile: FileInput | undefined;
  currentIcal: any;
  nextIcal: any;

  constructor(private manageSemesterSchedule: ManageSemesterScheduleUsecase, private toastrService: ToastrServiceInt) {
    this.cal = ical();
  }

  public async onUploadCurrentToBackend() {
    await this.manageSemesterSchedule.uploadCurrentToBackend(this.currentIcal);
  }

  public async onDownloadCurrentFromBackend() {
    await this.manageSemesterSchedule.getCurrentFromBackend();
  }

  public async onUploadNextToBackend() {
    await this.manageSemesterSchedule.uploadNextToBackend(this.nextIcal);
  }

  public async onDownloadNextFromBackend() {
    await this.manageSemesterSchedule.getNextFromBackend();
  }

  public async convertFromPdf() {
    await this.parseUploadedPdf(this.pdfFile);
  }

  public async parseUploadedPdf(event): Promise<string> {
    if (!event.target.files || event.target.files.length === 0) {
      return;
    }

    const file = event.target.files[0];
    await this.pdfToImageDataURLAsync(file);
  }

  public async parseString(string: string) {
    let lines: string[] = string.split(/\r?\n|\r|\n/g).map(line => line.trim());

    const dateLines = lines.filter(line => this.stringStartsWithDate(line));
    // from Dates have the form of "Some-Date bis" so the next Date-Line is to End of the Date
    const fromDates = dateLines.filter(line => line.includes(' bis'));

    const joinedDates: JoinedDate[] = [];
    let nextLineIsToDate = false;
    for (let date of dateLines) {
      if (nextLineIsToDate) {
        nextLineIsToDate = false;
        continue;
      }
      // ToDo: if a Date is used twice. Once as a from-date and once as a normal this will not work
      if (fromDates.some(fromDate => fromDate === date)) {
        nextLineIsToDate = true;
      }
      joinedDates.push({ str: date, lines: [] });
    }
    joinedDates.forEach((joinedDate, index) => {
      // get the index of the next "true" date that isn't a toDate in the lines of the page
      const nextIndex = index === (joinedDates.length - 1) ?
        lines.length :
        lines.findIndex(line => line === joinedDates[index + 1].str);

      if (nextIndex === -1) {
        console.error('did not find next index');
        console.log(joinedDates[index + 1]);
        this.toastrService.setToastrError('Error in Parsing String Dates',
          'Did not find the next Date for the Date in the lines-Array (should never happen for String parsing): '
          + joinedDates[index + 1].str, { timeout: 10000 });
      }
      // concat all the lines from the date to the next date.
      let startIndex = lines.findIndex(line => line === joinedDate.str);
      let concatStr = joinedDate.str + ' ';
      startIndex++;
      while (startIndex < nextIndex) { // iterate through all the lines until we hit the next date
        concatStr = concatStr.concat(lines[startIndex], ' '); // add a space between all lines
        startIndex++;
      }
      joinedDate.str = this.processDateString(concatStr);
    });
    this.convertDatesToSchedule(joinedDates.map(date => this.convertDateStringToDateObject(date.str)));
  }

  public async pdfToImageDataURLAsync(pdfFile: File) {

    pdfJsLib.GlobalWorkerOptions.workerSrc = await import('pdfjs-dist/build/pdf.worker.entry');

    if (!pdfFile) {
      console.error('no file');
    }
    const arrayBuffer = await pdfFile.arrayBuffer();
    let buffer = new Uint8Array(arrayBuffer);
    const pdf: PDFDocumentProxy = await pdfJsLib.getDocument(buffer).promise;

    const datePages: DatePage[] = [];
    for (let pageNumber = 1; pageNumber <= pdf.numPages; pageNumber++) {
      const page = await pdf.getPage(pageNumber);
      const partialText = await page.getTextContent({ disableCombineTextItems: false, includeMarkedContent: false });
      let lines: Line[] = this.getLinesByYCoordinate(partialText.items as TextItem[]);

      // cut off the last part because it usually also holds some dates that would otherwise mess with the parsing
      if (pageNumber === pdf.numPages) {
        const lastIndex = lines.findIndex(element => element.str.startsWith(this.cutoffStringForPdfParsing));
        lines = lines.slice(0, lastIndex);
      }

      const dateLines = lines.filter(line => this.stringStartsWithDate(line.str));
      // from Dates have the form of "Some-Date bis" so the next Date-Line is to End of the Date
      const fromDates = dateLines.filter(line => line.str.includes(' bis'));

      const joinedDates: JoinedDate[] = [];
      let nextLineIsToDate = false;
      for (let date of dateLines) {
        if (nextLineIsToDate) {
          nextLineIsToDate = false;
          continue;
        }
        // ToDo: if a Date is used twice. Once as a from-date and once as a normal this will not work
        if (fromDates.some(fromDate => fromDate === date)) {
          nextLineIsToDate = true;
        }
        joinedDates.push({ str: date.str, lines: [date] });
      }

      joinedDates.forEach((joinedDate, index) => {
        // get the index of the next "true" date that isn't a toDate in the lines of the page
        const nextIndex = index === (joinedDates.length - 1) ?
          lines.length :
          lines.findIndex(line => line === joinedDates[index + 1].lines[0]);

        if (nextIndex === -1) {
          console.error('did not find next index');
          console.log(joinedDates[index + 1]);
        }
        // concat all the lines from the date to the next date.
        let startIndex = lines.findIndex(line => line === joinedDate.lines[0]);
        let concatStr = joinedDate.lines[0].str + ' ';
        startIndex++;
        while (startIndex < nextIndex) { // iterate through all the lines until we hit the next date
          concatStr = concatStr.concat(lines[startIndex].str, ' '); // add a space between all lines
          joinedDate.lines.push(lines[startIndex]);
          startIndex++;
        }
        joinedDate.str = this.processDateString(concatStr);
      });
      datePages.push({ joinedDates: joinedDates, number: pageNumber });
    }

    /*
      ToDo:
        - Add UI that displays the generated iCal with highlighting (use some editor that already exists)
        - Refactor all these functions be easier to understand.
    */

    let dateObjects: MyDate[] = [];

    for (const datePage of datePages) {
      for (let joinedDate of datePage.joinedDates) {
        let dateObject = this.convertDateStringToDateObject(joinedDate.str)
        dateObjects.push(dateObject);
      }
    }
    this.convertDatesToSchedule(dateObjects);
  }

  private processDateString(dateString: string) {
    let processedString = dateString.replaceAll(this.dayRegex, ' '); // Delete Weekdays from Strings
    const moreThanOneSpace = / {2,}/g;
    processedString = processedString.replaceAll(moreThanOneSpace, ' ').trim();
    return processedString;
  }

  private convertDateStringToDateObject(dateString: string) {
    let startDate: string;
    let endDate: string | undefined;
    let timeArray: string[] | undefined;
    let description: string;

    if (!this.stringStartsWithDate(dateString)) {
      console.error('we have a line that does not start with a date');
      console.error(dateString);
      this.toastrService.setToastrError('Error in Parsing Date-Lines',
        'Lines have to start with the Date but the line is:' + dateString, { timeout: 10000 });
      return undefined;
      // TODO: throw an error message that the parsing went wrong
    }
    let line = dateString;
    const dateStringLength = this.getDateLength(line);
    startDate = line.substring(0, dateStringLength);
    startDate = this.addZerosToDate(startDate);
    line = line.substring(dateStringLength + 1); // cut the date and the space after it
    if (line.substring(0, 3) === 'bis') {
      line = line.substring(4);
      if (!this.stringStartsWithDate(line)) {
        console.error('we have a from-to line that does not have a second date');
        console.error(line);
        this.toastrService.setToastrError('Error in Parsing Date-Lines',
          'Lines that contain the word "bis" after a Date must have a second date but this one has not got one: '
          + dateString, { timeout: 10000 });
        return undefined // TODO: throw an error message that the parsing went wrong
      }
      const secondDateStringLength = this.getDateLength(line);
      endDate = line.substring(0, secondDateStringLength);
      endDate = this.addZerosToDate(endDate);
      line = line.substring(secondDateStringLength + 1);
    }

    const timeMatch = this.calculateTimeMatch(line);
    if (timeMatch?.length > 0) { // there are matches
      const lastMatch = timeMatch[timeMatch.length - 1];
      const lastIndex = line.lastIndexOf(lastMatch);
      line = line.substring(lastIndex + lastMatch.length);
      for (let i = 0; i < timeMatch.length; i++) {
        line = line.replace(timeMatch[i], '');
      }
      timeArray = timeMatch.map(match => this.normalizeTime(match));
      line = line.replaceAll('Uhr', '').trim();
    }
    description = line;
    const dateObject = {
      startDate,
      endDate,
      timeArray,
      description,
      originalString: dateString
    };
    return dateObject as MyDate
  }

  private getLinesByYCoordinate(textItems: TextItem[]): Line[] {
    let lines: Line[] = [];
    let line: Line = { parts: [], str: '' };
    let lastY = -1;
    textItems.filter(item => item.str !== '').forEach(text => { // iterate over all text-items
      if (lastY === text.transform[5]) { // text.transform[5] returns the y on the page
        // if it has the same y value it is one line
        line.str = line.str.concat(text.str);
        line.parts.push({
          str: text.str,
          x: text.transform[4],
          y: text.transform[5],
          width: text.width
        });
      } else {
        if (lastY !== -1) {
          lines.push(line);
        }
        lastY = text.transform[5];
        line = {
          parts: [{
            str: text.str,
            x: text.transform[4],
            y: text.transform[5],
            width: text.width
          }], str: text.str
        };
      }
    });
    lines.push(line);
    return lines;
  }

  private stringStartsWithDate(string: string): boolean {
    for (let stringLength = 8; stringLength <= 10; stringLength++) {
      if (string.substring(0, stringLength).match(this.dateRegex)?.length > 0) {
        return true;
      }
    }
    return false;
  }

  private calculateTimeMatch(string: string): RegExpMatchArray {
    return string.match(this.timeRegex);
  }

  private getDateLength(stringWithDate): number {
    for (let stringLength = 8; stringLength <= 10; stringLength++) {
      if (stringWithDate.substring(0, stringLength).match(this.dateRegex)?.length > 0) {
        return stringLength;
      }
    }
    console.error('did not find a date in the string: ' + stringWithDate);
    return -1;
  }

  private addZerosToDate(startDate: string): string {
    if (startDate.indexOf('.') === 1) {
      startDate = '0'.concat(startDate);
    }
    if (startDate.lastIndexOf('.') === 4) {
      startDate = startDate.replace('.', '.0');
    }
    return startDate;
  }

  private parseMyDateToIsoDate(date: string, timeArray?: string[], useFirstElementOfTimeArray = true, timeArrayOffset = 0): DateTime {
    let isoDate;
    let timeArrayIndex = (useFirstElementOfTimeArray ? 0 : 1) + timeArrayOffset;
    let isFullDay: boolean;
    if (timeArray && timeArray.length > timeArrayIndex) {
      if (useFirstElementOfTimeArray && timeArray.length === 1 && timeArray[timeArrayIndex].startsWith('08')) {
        console.log('first date starts with a time of "08" so we are saying that we have a full day event');
        isoDate = this.dateToIsoFormat(date) + 'T00:00:00Z'; // so only use the full date without anything else
        isFullDay = true;
      } else {
        isoDate = this.dateToIsoFormat(date) + 'T' + timeArray[timeArrayIndex].substring(0, 2) + ':' + timeArray[timeArrayIndex].substring(3, 5) + ':00Z';
        isFullDay = false;
      }
    } else {
      isoDate = this.dateToIsoFormat(date) + 'T00:00:00Z';
      isFullDay = true;
    }
    const offsetDate = new Date(isoDate);
    const hoursOffset = offsetDate.getTimezoneOffset() / 60;
    // These offsets are client-specific, so they only work when the timezone of the client is Europe/Berlin
    // Hard-coding them would not work that easily because of daylight-savings time.
    const actualDate = this.addHours(offsetDate, hoursOffset);

    return {
      date: actualDate,
      isFullDayDate: isFullDay
    };
  }

  private dateToIsoFormat(date: string): string {
    if (date.length != 10) {
      console.error('date-string to parse has to have a length of 10');
      return '';
    }
    return date.substring(6) + '-' + date.substring(3, 5) + '-' + date.substring(0, 2);
  }

  private normalizeTime(match: string) {
    if (match.length === 4) {
      return '0' + match;
    }
    return match;
  }

  private convertDatesToSchedule(dateObjects: MyDate[]) {
    const cal = ical({timezone: 'Europe/Berlin', description: this.calenderDescription, name: this.calenderName, method: ICalCalendarMethod.PUBLISH});
    for (let date of dateObjects) {
      const startDate = this.parseMyDateToIsoDate(date.startDate, date.timeArray, true, 0);
      let end: Date;
      if (date.endDate) {
        end = this.parseMyDateToIsoDate(date.endDate, date.timeArray, false, 0).date
      } else {
        if (date.timeArray && date.timeArray.length > 1) {
          end = this.parseMyDateToIsoDate(date.startDate, date.timeArray, false, 0).date
        }
        // no else block here. We just use the default of the calendar
      }

      if (startDate.isFullDayDate) {
        if (end) {
          end = this.addDays(end, 1) // we always have to extend the days by 1 in order for full day events to work
        } else {
          end = this.addDays(startDate.date, 1); // we always have to extend the days by 1 in order for full day events to work
        }
      }
      else if (!end) {
        if (!startDate.isFullDayDate) {
          end = this.addHours(startDate.date, this.defaultLengthOfDatesWithoutEndingHours);
        }
      }

      let eventData = {start: startDate.date, end: end, allDay: startDate.isFullDayDate, summary: date.description} as ICalEventData;
      cal.createEvent(eventData);

      if (date.timeArray && date.timeArray.length > 2) {
        // this adds an extra date for timeArray longer than 2 (we have two times with the same name) TODO: maybe extend to have n*2 possible times
        let start = this.parseMyDateToIsoDate(date.startDate, date.timeArray, true, 2);
        let end = this.parseMyDateToIsoDate(date.startDate, date.timeArray, false, 2);
        let eventData = {start: start.date, end: end.date, allDay: false, summary: date.description} as ICalEventData;
        cal.createEvent(eventData);
      }
    }
    this.generatedIcal = cal.toString();
    this.toastrService.setToastrSuccess('iCal generated', 'Ical wurde erstellt und kann nun kopiert und angepasst werden', {timeout: 5000});
  }

  private addDays(date: Date, days: number): Date {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

  private addHours(date: Date, hours: number): Date {
    const result = new Date(date);
    result.setHours(result.getHours() + hours);
    return result;
  }

  updateCurrent(event) {
    if (!event.target.files || event.target.files.length === 0) {
      return;
    }

    this.currentIcal = event.target.files[0];
  }

  updateNext(event) {
    if (!event.target.files || event.target.files.length === 0) {
      return;
    }

    this.nextIcal = event.target.files[0];
  }
}
