<mat-card class='w-100 h-100'>
  <mat-card-title style='font-size: 14px!important;'>{{ this.label }}</mat-card-title>
  <mat-card-content>
    <div *ngIf='value !== 0; else loading' class='d-flex flex-column justify-content-center align-items-center'
         style='gap: 2px; padding: 10px;'>
      <div style='font-size: 32px; text-align: center'>
        {{ value }}
      </div>
    </div>
  </mat-card-content>
</mat-card>

<ng-template #loading>
  <div class='loading d-flex justify-content-center align-items-center w-100 h-100'>
    <mat-spinner [diameter]='32'></mat-spinner>
  </div>
</ng-template>
