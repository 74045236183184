import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { EChartsOption } from 'echarts';
import { AnalyticsData, AnalyticsEnvironment, AnalyticsService, DateRange } from '../../api/analytics.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-analytics-widget',
  templateUrl: './analytics-widget.component.html',
  styleUrls: ['./analytics-widget.component.scss']
})
export class AnalyticsWidgetComponent implements OnInit, AfterViewInit {

  @Input() environment!: AnalyticsEnvironment;
  @Input() label: string = 'Analytics';
  @Input() metrics: Array<{ name: string }> = [];
  @Input() eventFilter?: string;
  @Input() platform = false;
  analyticsData: AnalyticsData;
  isLoading = true;
  chartOptions: EChartsOption;

  timeLineOptions: DateRange[];
  selectedTimeLineOption: DateRange = DateRange.Today;

  constructor(private readonly analyticsService: AnalyticsService) {
    this.timeLineOptions = Object.values(DateRange);
  }

  ngOnInit(): void {
    this.selectTimeLineOption(DateRange.SevenDays);
  }

  selectTimeLineOption(option: DateRange) {
    this.selectedTimeLineOption = option;
    this.environment.propertyId &&
    this.getAnalytics(this.environment.propertyId, this.selectedTimeLineOption);
  }

  getAnalytics(propertyId: string, start: DateRange, end?: DateRange) {
    this.isLoading = true;
    const dateRanges = [{ startDate: start, endDate: end ?? 'today' }];
    const metrics = this.metrics;
    const dimensionFilter = this.eventFilter ? {
      'filter': {
        'fieldName': this.platform ? 'platform' : 'eventName',
        'stringFilter': {
          'value': this.eventFilter
        }
      }
    } : null;
    const dimensions = [{ name: 'date' }];

    this.analyticsService.fetchAnalyticsData(propertyId, dateRanges, metrics, dimensions, dimensionFilter).then(
      (data) => {
        data.subscribe({
          next: value => {
            this.analyticsData = value;
            const timelineData = this.processAnalyticsData(value);
            this.createTimelineChart(timelineData);
            this.isLoading = false;
          },
          error: err => this.isLoading = false
        });
      },
      (error) => {
        console.error('Error fetching analytics data:', error);
        this.isLoading = false;
      }
    );
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if (this.chartOptions) {
        this.isLoading = false;
      }
    }, 0);
  }

  private processAnalyticsData(data: any) {
    const datePipe = new DatePipe('de-DE');
    return data.rows.map(row => ({
      date: datePipe.transform(
        this.parseRawDate(row.dimensionValues[0].value),
        'dd.MM.yyyy'
      ),
      activeUsers: parseInt(row.metricValues[0].value, 10)
    }));
  }

  private parseRawDate(rawDate: string): Date {
    const year = parseInt(rawDate.substring(0, 4), 10);
    const month = parseInt(rawDate.substring(4, 6), 10) - 1;
    const day = parseInt(rawDate.substring(6, 8), 10);
    return new Date(year, month, day);
  }

  private createTimelineChart(timelineData: { date: string, activeUsers: number }[]) {
    timelineData.sort((a, b) => {
      const dateA = new Date(a.date.split('.').reverse().join('-'));
      const dateB = new Date(b.date.split('.').reverse().join('-'));
      return dateA.getTime() - dateB.getTime();
    });

    const labels = timelineData.map(entry => entry.date);
    const data = timelineData.map(entry => entry.activeUsers);

    this.chartOptions = {
      tooltip: {
        trigger: 'axis',
        formatter: (params: any) => {
          const dataPoint = params[0];
          return `${dataPoint.name}<br/>Aktive Nutzer: ${dataPoint.value}`;
        }
      },
      xAxis: {
        type: 'category',
        data: labels,
        axisLabel: {
          formatter: (value: string) => value // Format dates as desired
        }
      },
      yAxis: {
        type: 'value'
      },
      series: [{
        type: 'line',
        data: data,
        name: 'Aktive Nutzer',
        lineStyle: {
          color: 'rgba(75, 192, 192, 1)'
        },
        symbol: 'circle',
        symbolSize: 8
      }]
    };
  }

}
