import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { filter, switchMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { SemesterResponseDto } from '../shared/model/semseter-response.dto';
import { CreateSemesterRequestDto } from '../shared/model/create-semeter-request.dto';
import { ToastrServiceInt } from '../../shared/util/toastr.service';
import {
  CoursesV1Service,
  DepartmentsV1Service,
  GetCourseDto,
  GetLdapFacultyDto,
  PostCourseDto,
  PutCourseDto
} from '../../../_generated/hka-app-service';
import { ConfirmationDialogService } from '../../confirmation-dialog/confirmation-dialog.service';

@Component({
  selector: 'app-course-detail',
  templateUrl: './course-detail.component.html',
  styleUrls: ['./course-detail.component.scss']
})
export class CourseDetailComponent implements OnInit {
  public isCreateMode: boolean = false;
  public isEditMode: boolean = false;

  public pageTitle: string = 'Studiengang hinzufügen';

  //public course: CourseResponseDto = <CourseResponseDto>{faculty: {id: ""}, semesters: []};
  public course: GetCourseDto = <GetCourseDto>{
    lsfId: 0,
    name: '',
    updated_at: '',
    facultyId: '',
    semesters: []
  };

  public facultys: GetLdapFacultyDto[] = [];

  public form: FormGroup;

  constructor(
    private readonly _route: ActivatedRoute,
    private readonly _router: Router,
    private readonly _formBuilder: FormBuilder,
    private _courseService: CoursesV1Service,
    private toastrService: ToastrServiceInt,
    private _facultyService: DepartmentsV1Service,
    private dialog: ConfirmationDialogService
  ) {
  }

  public ngOnInit(): void {
    this._route.url.subscribe((url) => {
      this.isCreateMode = url[url.length - 1].path === 'new';
      this.isEditMode = url[url.length - 2].path === 'edit';
    });
    this._facultyService.getAllDepartments1().subscribe((facultyResponses) => (this.facultys = facultyResponses));

    if (this.isEditMode) {
      this.pageTitle = 'Studiengang bearbeiten';
      this._route.params
        .pipe(
          switchMap(({ courseId }) =>
            courseId ? this._courseService.getCourse(courseId) : of(<GetCourseDto>{ semesters: [] })
          ),
          tap((courseResponse) => (this.course = courseResponse))
        )
        .subscribe();
    } else if (this.isCreateMode) {
    }
  }

  public onAddSemester(): void {
    this.course.semesters.push(<SemesterResponseDto>{});
  }

  public onSubmit(): void {
    if (this.isEditMode) {
      this._courseService.updateCourse(this.course.id, this.getCourseToCreate());
    } else if (this.isCreateMode) {
      this._courseService.createCourse1(this.getCourseToCreate() as PostCourseDto);
    }
  }

  public onCancel(): void {
    this.dialog
      .askForConfirmation({
        message: 'Möchten Sie die Ansicht wirklich verlassen? Es könnten Änderungen verloren gehen.',
        dialogOptions: ['CANCEL', 'CONFIRM']
      })
      .pipe(filter((result) => result == 'CONFIRM'))
      .subscribe(() => {
        this.toastrService.setToastrWarning('Hinweis', 'Änderungen wurden nicht übernommen!', {
          timeout: 15000
        });
        this._router.navigate(['/courses']);
      });
  }

  public onChange(value: string): void {
    this.course.facultyId = value;
  }

  public getCourseToCreate(): PutCourseDto {
    return <PutCourseDto>{
      facultyId: this.course.facultyId,
      name: this.course.name,
      semesters: this.course.semesters.map(
        (semesterResponse) =>
          <CreateSemesterRequestDto>{
            name: semesterResponse.name,
            semesterNumber: semesterResponse.semesterNumber,
            displayName: semesterResponse.displayName,
            iCalFileHttpLink: semesterResponse.iCalFileHttpLink
          }
      )
    };
  }
}
