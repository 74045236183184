import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { changeRequestTypes } from '../shared/change-requests';
import { isNullOrUndefined } from 'util';
import { ChangeRequest } from '../shared/change-request';
import {
  ChangeRequestsV1Service,
  PersonsV1Service,
  POIV1Service,
  PutPersonDto,
  PutPoiGeneralDto,
  PutPoiOfficeDto,
  PutPoiStudentUnionDto
} from '../../../_generated/hka-app-service';
import { ConfirmationDialogService } from '../../confirmation-dialog/confirmation-dialog.service';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-edit-change-request',
  templateUrl: './edit-change-request.component.html',
  styleUrls: ['./edit-change-request.component.scss']
})
export class EditChangeRequestComponent implements OnInit {
  changeRequest: ChangeRequest;
  imgUrl: string;
  cr: any;
  public loading = false;

  constructor(
    private route: ActivatedRoute,
    private changeRequestService: ChangeRequestsV1Service,
    private router: Router,
    private personService: PersonsV1Service,
    private poiv1Service: POIV1Service,
    private dialog: ConfirmationDialogService
  ) {
  }

  ngOnInit() {
    this.loading = true;
    this.changeRequestService.getChangeRequest(this.route.url['_value'][2]?.path).subscribe(
      (data) => {
        this.changeRequest = data;
        this.cr = this.changeRequest;
        this.loadImage();
        this.parseChanges();
      },
      (error) => {
        this.loading = false;
        console.log(error);
      }
    );
  }

  private loadImage() {
    this.imgUrl = environment.url + '/changerequests/image/' + this.changeRequest.id + '.png';
  }

  parseChanges(): void {
    try {
      this.cr.changes = JSON.parse(this.cr.comment);
      if (!this.cr.hasImage && (!(this.cr.changes instanceof Array) || this.cr.changes.length === 0))
        this.cr.changes = false;
    } catch (e) {
      this.cr.changes = false;
    }
    for (const change of Object.keys(this.cr.changes)) {
      //convert changes object to array
      const key = Object.keys(this.cr.changes[change])[0];
      this.cr.changes[change] = [key, this.cr.changes[change][key]];
    }
    this.loading = false;
  }

  onAbort() {
    this.dialog
      .askForConfirmation({
        message: 'Möchten Sie die Ansicht wirklich verlassen? Es könnten Änderungen verloren gehen.',
        dialogOptions: ['CANCEL', 'CONFIRM']
      })
      .pipe(filter((result) => result == 'CONFIRM'))
      .subscribe(() => {
        const path = `/changerequests/`;
        this.router.navigate([path]);
      });
  }

  onChange(change: any) {
    const updateItem = this.cr.changes.find(this.findIndexToUpdate, change[0]);

    const index = this.cr.changes.indexOf(updateItem);
    this.cr.changes[index] = change;
  }

  findIndexToUpdate(newItem) {
    return newItem[0] === this;
  }

  onUpdate() {
    this.loading = true;
    switch (this.cr.type) {
      case changeRequestTypes.person:
        this.personService.getPerson(this.cr.objectId).subscribe((person) => {
          this.cr.changes.forEach((change) => {
            person[change[0]] = change[1].after;
          });
          const putPerson: PutPersonDto = {
            ...person,
            email: person.email,
            firstName: person.firstName,
            lastName: person.lastName,
            room: {
              ...person.room,
              building: null
            }
          };
          this.personService.updatePerson(this.cr.objectId, putPerson).subscribe(() => {
            this.onDelete();
          });
        });
        break;
      case changeRequestTypes.office:
        this.poiv1Service.getPoiOffice1(this.cr.objectId).subscribe((office) => {
          this.cr.changes.forEach((change) => {
            office[change[0]] = change[1].after;
          });
          const putOffice: PutPoiOfficeDto = {
            ...office,
            room: {
              ...office.room,
              building: null
            }
          };
          this.poiv1Service.updatePoiOffice1(this.cr.objectId, putOffice).subscribe(() => {
            this.onDelete();
          });
        });
        break;
      case changeRequestTypes.studentUnion:
        this.poiv1Service.getPoiStudentUnion1(this.cr.objectId).subscribe((student) => {
          this.cr.changes.forEach((change) => {
            student[change[0]] = change[1].after;
          });
          const putStudent: PutPoiStudentUnionDto = {
            ...student,
            room: {
              ...student.room,
              building: null
            }
          };
          this.poiv1Service.updatePoiStudentUnion1(this.cr.objectId, putStudent).subscribe(() => {
            this.onDelete();
          });
        });
        break;
      case changeRequestTypes.general:
        this.poiv1Service.getPoiGeneral1(this.cr.objectId).subscribe((general) => {
          this.cr.changes.forEach((change) => {
            general[change[0]] = change[1].after;
          });
          const putGeneral: PutPoiGeneralDto = {
            ...general
          };
          this.poiv1Service.updatePoiGeneral1(this.cr.objectId, putGeneral).subscribe(() => {
            this.onDelete();
          });
        });
        break;
      default:
        this.loading = false;
        break;
    }
  }

  onDelete() {
    this.changeRequestService.deleteChangeRequest(this.cr.id).subscribe(
      (data) => {
        this.loading = false;
        this.router.navigateByUrl('/changerequests');
      },
      () => {
        this.loading = false;
      }
    );
  }

  typeOf(value: any) {
    return typeof value;
  }

  isNullOrUndefined(value: any) {
    return isNullOrUndefined(value);
  }
}
