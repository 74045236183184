import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { StudentUnionService } from '../shared/student-union.service';
import { ToastrServiceInt } from '../../shared/util/toastr.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { StudentUnion } from '../shared/student-union';
import { Building, Room } from '../../room/shared/room';
import { isNullOrUndefined, isUndefined } from 'util';
import {
  BuildingV1Service,
  GetPoiStudentUnionDto,
  GetRoomDto,
  POIV1Service,
  PutPoiStudentUnionDto,
  RoomsV1Service
} from '../../../_generated/hka-app-service';
import { filter } from 'rxjs/operators';
import { ConfirmationDialogService } from '../../confirmation-dialog/confirmation-dialog.service';

@Component({
  selector: 'app-update-student-union',
  templateUrl: './update-student-union.component.html',
  styleUrls: ['./update-student-union.component.scss'],
  providers: [StudentUnionService]
})
export class UpdateStudentUnionComponent implements OnInit, OnDestroy {
  pageTitle = 'Fachschaft bearbeiten';
  @Input()
  studentUnion: GetPoiStudentUnionDto;
  @Output()
  refreshSearch: EventEmitter<StudentUnion> = new EventEmitter<StudentUnion>();
  form: FormGroup;
  rooms: GetRoomDto[] = [];
  allRooms: GetRoomDto[] = [];
  buildings = [];
  private sub: Subscription;
  private subBuildingChange: Subscription;

  constructor(
    private studentUnionService: POIV1Service,
    private campusService: BuildingV1Service,
    private roomService: RoomsV1Service,
    private readonly formBuilder: FormBuilder,
    private toastrService: ToastrServiceInt,
    private router: Router,
    private route: ActivatedRoute,
    private dialog: ConfirmationDialogService
  ) {
  }

  ngOnInit(): void {
    this.sub = this.route.params.subscribe((params) => {
      const studentUnionId = +params['studentUnionId']; // (+) converts string 'id' to a number
      this.studentUnionService.getPoiStudentUnion1(studentUnionId).subscribe((studentUnion) => {
        this.campusService.getAllBuildings1().subscribe((buildings) => {
          this.buildings = buildings;

          let buildingId: number;
          if (!(!studentUnion || !studentUnion.room || !studentUnion.room.building || !studentUnion.room.building.id)) {
            buildingId = studentUnion.room.building.id;
            this.setupRooms(buildingId);
          }

          this.form = this.formBuilder.group({
            id: studentUnion.id,
            name: studentUnion.name,
            email: studentUnion.email,
            meeting: studentUnion.meeting,
            phone: studentUnion.phone,
            url: studentUnion.url,
            urlTwo: studentUnion.urlTwo,
            room: studentUnion.room,
            building: { id: buildingId }
          });
          console.log(studentUnion.urlTwo);
          if (isNullOrUndefined(buildingId)) {
            this.form.get('building').setValue(undefined);
            this.disableRoom();
          }

          this.subBuildingChange = this.form.get('building').valueChanges.subscribe((newBuilding: Building) => {
            if (!isUndefined(newBuilding)) {
              this.setupRooms(this.form.get('building').value.id);
              this.form.get('room').enable();
            } else {
              this.disableRoom();
            }
          });
        });
      });
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();

    if (this.subBuildingChange) {
      this.subBuildingChange.unsubscribe();
    }
  }

  compareBuilding(building1: Building, building2: Building): boolean {
    return building1 && building2 ? building1.id === building2.id : building1 === building2;
  }

  compareRoom(room1: Room, room2: Room): boolean {
    return room1 && room2 ? room1.id === room2.id : room1 === room2;
  }

  setupRooms(buildingId: number) {
    if (!buildingId) {
    }
    this.roomService.getAllRooms1().subscribe((allRooms) => {
      this.allRooms = allRooms;
      this.rooms = this.calculateRoomsOfBuilding(allRooms, buildingId);
    });
  }

  private calculateRoomsOfBuilding(allRooms: GetRoomDto[], buildingId: number): GetRoomDto[] {
    const roomsOfBuilding = allRooms.filter((room) => room.building && room.building.id === buildingId);
    return roomsOfBuilding.sort(function(room1, room2) {
      const x = room1.name.toLowerCase();
      const y = room2.name.toLowerCase();
      if (x < y) {
        return -1;
      }
      if (x > y) {
        return 1;
      }
      return 0;
    });
  }

  updateStudentUnion() {
    const formValue = this.form.value as StudentUnion;
    const updatedStudentUnionObject = new StudentUnion(
      formValue.email,
      formValue.name,
      formValue.id,
      formValue.url,
      formValue.urlTwo,
      formValue.updatedAt,
      formValue.meeting,
      isNullOrUndefined(formValue.room)
        ? null
        : new Room(formValue.room.name, formValue.room.building, formValue.room.id),
      formValue.phone
    );

    const updateStudentUnion: PutPoiStudentUnionDto = {
      id: updatedStudentUnionObject.id,
      name: updatedStudentUnionObject.name,
      url: updatedStudentUnionObject.url,
      urlTwo: updatedStudentUnionObject.urlTwo,
      meeting: updatedStudentUnionObject.meeting,
      phone: updatedStudentUnionObject.phone,
      email: updatedStudentUnionObject.email,
      room: {
        id: formValue.room.id,
        isDeleted: formValue.room.isDeleted,
        name: formValue.room.name
      }
    };

    this.studentUnionService.updatePoiStudentUnion1(updateStudentUnion.id, updateStudentUnion).subscribe((data) => {
      this.refreshSearch.next(updatedStudentUnionObject);
      this.router.navigate(['/searchstudentunion']);
      this.toastrService.setToastrSuccess('Erfolgreich!', 'Ihre Daten wurden gespeichert.');
    });
  }

  cancelEdit() {
    this.dialog
      .askForConfirmation({
        message: 'Möchten Sie die Ansicht wirklich verlassen? Es könnten Änderungen verloren gehen.',
        dialogOptions: ['CANCEL', 'CONFIRM']
      })
      .pipe(filter((result) => result == 'CONFIRM'))
      .subscribe(() => {
        this.router.navigate(['/searchstudentunion']);
      });
  }

  disableRoom() {
    this.form.get('room').setValue(undefined);
    this.form.get('room').disable();
  }

  buildingSelected() {
    return isNullOrUndefined(this.form.get('building').value);
  }
}
