import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-searchform-general',
  templateUrl: './searchform-general.component.html',
  styleUrls: ['./searchform-general.component.scss']
})
export class SearchformGeneralComponent implements OnInit {
  @Output()
  id: EventEmitter<number> = new EventEmitter<number>();
  gid: number;

  constructor() {}

  ngOnInit() {}

  onSearch() {
    this.id.next(this.gid);
  }
}
