import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { StudentUnionService } from '../../shared/student-union.service';

@Component({
  selector: 'app-searchform-student-union',
  templateUrl: './searchform-student-union.component.html',
  styleUrls: ['./searchform-student-union.component.scss'],
  providers: [StudentUnionService]
})
export class SearchformStudentUnionComponent implements OnInit {
  @Output() id: EventEmitter<number> = new EventEmitter<number>();
  pid: number;
  constructor() {}

  ngOnInit() {}

  onSearch() {
    this.id.next(this.pid);
  }
}
