import { Room } from '../../room/shared/room';

export interface StudentUnion {
  email: string;
  name: string;
  id: number;
  url: string;
  urlTwo: string;
  phone: string;
  updatedAt: string;
  meeting: string;
}

export class StudentUnion implements StudentUnion {
  static fromServer(studentUnionServer: StudentUnion) {
    const studentUnion = new StudentUnion(
      studentUnionServer.email,
      studentUnionServer.name,
      studentUnionServer.id,
      studentUnionServer.url,
      studentUnionServer.urlTwo,
      studentUnionServer.updatedAt,
      studentUnionServer.meeting,
      studentUnionServer.room,
      studentUnionServer.phone
    );
    return studentUnion;
  }

  constructor(
    public email: string,
    public name: string,
    public id: number,
    public url: string,
    public urlTwo: string,
    public updatedAt: string,
    public meeting: string,
    public room: Room,
    public phone: string
  ) {
    /*  if (room) {
      this.room = new Room(room);
    } */
  }
}
