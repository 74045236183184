<mat-card>
  <mat-card-title>Stundenpläne gesperrt</mat-card-title>
  <mat-card-content>
    <div *ngIf='semesters.length > 0; else noPlans' class='d-flex justify-content-between align-items-start'>
      <mat-list class='col-6'>
        <div mat-subheader>Pläne</div>
        <mat-list-item *ngFor='let plan of semesters; let i = index'
                       [class.list-item-active]='selectedSemester?.id === plan?.id'
                       [class.list-item]='selectedSemester?.id !== plan?.id'
                       (click)='onSelectPlan(i)'
                       class='d-flex justify-content-start align-items-stretch'>
          <mat-icon matListIcon>event_note</mat-icon>
          <div matLine class='font-weight-bold'>{{plan.name}}</div>
        </mat-list-item>
      </mat-list>
      <mat-card *ngIf='selectedSemester' class='col-6'>
        <mat-card-title>
          Semesterplan: {{ selectedSemester.name }}
        </mat-card-title>
        <mat-card-content>
          <ul *ngIf='selectedFaculty' class='p-0'>
            <li style='gap: 12px' class='d-flex justify-content-start align-items-center pb-2'>
              <div class='h6' style='margin: 0!important;'>
                Fakultät:
              </div>
              <div>
                {{ selectedFaculty.alias }}
              </div>
            </li>
          </ul>
        </mat-card-content>
      </mat-card>
    </div>
  </mat-card-content>
</mat-card>

<ng-template #noPlans>
  <div *ngIf='isLoaded; else loading' class='d-flex flex-column justify-content-center align-items-center'
       style='min-height: 500px'>
    <img [ngSrc]='"assets/images/calendar.jpg"' alt='Calendar' width='500' height='366'>
    <div class='text-center h2'>
      Keine Pläne sind gesperrt.
    </div>
  </div>
  <ng-template #loading>
    <div class='d-flex flex-column justify-content-center align-items-center'>
      <mat-spinner size='24px'></mat-spinner>
    </div>
  </ng-template>
</ng-template>
