<div class="row">
  <div class="col-md-5 col-md-offset-5">
    <div class="input-group mb-3">
      <div class="input-group-prepend">
        <span class="input-group-text">
          <fa-icon [icon]="['fas', 'search']"></fa-icon>
        </span>
      </div>
      <input
        type="text"
        (keyup.enter)="onSearch()"
        class="form-control"
        [(ngModel)]="pid"
        placeholder="Name oder ID eintragen"
        aria-describedby="sizing-addon1"
      />
    </div>
  </div>
</div>
