import { Component, OnInit } from '@angular/core';
import { StudentUnionService } from '../shared/student-union.service';
import { ToastrServiceInt } from 'app/shared/util/toastr.service';
import { isNullOrUndefined } from 'util';
import {
  BuildingV1Service,
  GetPoiStudentUnionDto,
  POIV1Service,
  RoomsV1Service
} from '../../../_generated/hka-app-service';
import { filter } from 'rxjs/operators';
import { ConfirmationDialogService } from '../../confirmation-dialog/confirmation-dialog.service';

@Component({
  selector: 'app-student-union',
  templateUrl: './student-union.component.html',
  styleUrls: ['./student-union.component.scss'],
  providers: [StudentUnionService]
})
export class StudentUnionComponent implements OnInit {
  pageTitle = 'Fachschaften';
  private studentUnion: GetPoiStudentUnionDto;
  public studentUnions: GetPoiStudentUnionDto[];
  private updateActive = false;
  private searchparam: any;
  public loading = false;

  constructor(
    private studentUnionService: POIV1Service,
    private roomService: RoomsV1Service,
    private toastrService: ToastrServiceInt,
    private buildingService: BuildingV1Service,
    private dialog: ConfirmationDialogService
  ) {
    this.studentUnions = [];
  }

  ngOnInit() {
    this.getAll();
  }

  getStudentUnion(searchparam: any) {
    this.searchparam = searchparam;
    this.studentUnions.length = 0;
    if (searchparam) {
      if (Number(searchparam)) {
        this.getStudentUnionById(searchparam);
      } else {
        this.getStudentUnionByName(searchparam);
      }
    } else {
      this.getAll();
    }
  }

  getAll() {
    this.loading = true;
    this.studentUnionService.getAllPoiStudentUnions1().subscribe((data) => {
      this.loading = false;
      data.forEach((d) => {
        if (d.room) {
          if (isNullOrUndefined(d.room.building)) {
            d.room.building = {};
            this.loading = true;
            this.roomService.getRoom(d.room.id).subscribe((room) => {
              this.loading = false;
              d.room.name = room.name;
              if (room.building.id) {
                d.room.building.id = room.building.id;
                this.loading = true;
                this.buildingService.getBuilding(room.building.id).subscribe((building) => {
                  this.loading = false;
                  d.room.building = building;
                });
              }
            });
          } else {
            // if (isNullOrUndefined(d.room.building.name)) {
            //   this.loading = true;
            //   this.roomService.getBuildingById(d.room.building.id).subscribe(building => {
            //     this.loading = false;
            //     d.room.building.name = building.name;
            //   });
            // }
          }
        }
      });
      this.studentUnions = data;
      this.studentUnions.sort((a, b) => a.name.localeCompare(b.name));
    });
  }

  getStudentUnionById(id: number) {
    this.loading = true;
    this.studentUnionService.getPoiStudentUnion1(id).subscribe((data) => {
      this.loading = false;
      this.studentUnions[0] = data;
    });
  }

  getStudentUnionByName(searchParam: string) {
    this.loading = true;
    this.studentUnionService.getPoiStudentUnionsPage1(null, searchParam).subscribe((data) => {
      this.loading = false;
      this.studentUnions = data.content as any;
    });
  }

  refreshSearch(studentUnion: GetPoiStudentUnionDto) {
    this.setStudentUnion(studentUnion);
    this.getStudentUnion(this.searchparam);
  }

  setStudentUnion(studentUnion: GetPoiStudentUnionDto) {
    this.studentUnion = studentUnion;
  }

  onDelete(id: number, name: string) {
    this.dialog
      .askForConfirmation({
        message: 'Bist du dir sicher, dass du ' + name + ' löschen willst? ',
        dialogOptions: ['CANCEL', 'CONFIRM']
      })
      .pipe(filter((result) => result == 'CONFIRM'))
      .subscribe(() => {
        this.studentUnionService.deletePoiStudentUnion1(id).subscribe(() => {
          this.toastrService.setToastrSuccess('Erfolgreich!', 'Der Eintrag wurde gelöscht.', { progressBar: true });
          this.getAll();
        });
      });
  }
}
