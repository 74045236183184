import { fromEvent, Subscription } from 'rxjs';
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { ChangeRequestService } from './shared/change-requests.service';
import { changeRequestTypes } from './shared/change-requests';
import { StudentUnionService } from '../student-union/shared/student-union.service';
import { GeneralService } from '../general/shared/general.service';
import { isNullOrUndefined } from 'util';
import {
  generalChangeRequestWhitelist,
  officeChangeRequestWhitelist,
  personChangeRequestWhitelist,
  studentUnionChangeRequestWhitelist
} from './change-request-whitelist';
import { environment } from '../../environments/environment';
import {
  BuildingV1Service,
  ChangeRequestsV1Service,
  GetChangeRequestDto,
  PersonsV1Service,
  POIV1Service,
  PutPersonDto,
  PutPoiGeneralDto,
  PutPoiOfficeDto,
  PutPoiStudentUnionDto
} from '../../_generated/hka-app-service';
import { ChangeRequest } from './shared/change-request';
import { ConfirmationDialogService } from '../confirmation-dialog/confirmation-dialog.service';
import { filter } from 'rxjs/operators';
import TypeEnum = GetChangeRequestDto.TypeEnum;

@Component({
  selector: 'app-cr-overview',
  templateUrl: './change-requests.component.html',
  styleUrls: ['./change-requests.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CrOverviewComponent implements OnInit, OnDestroy {
  pageTitle = 'Offene Change-Requests';
  validationImageBaseUrl = environment.url + '/api/changerequests/v1/image/';
  changeRequests: Array<ChangeRequest>;
  errorMessage: any;
  closedTicketMessage: any;
  subscription: Subscription;
  public loading = false;

  constructor(
    private changerequestservice: ChangeRequestService,
    private readonly changeRequestsService: ChangeRequestsV1Service,
    private poiService: POIV1Service,
    private personService: PersonsV1Service,
    private officeService: BuildingV1Service,
    private studentunionService: StudentUnionService,
    private studentUnionService: POIV1Service,
    private generalService: GeneralService,
    private router: Router,
    private dialog: ConfirmationDialogService
  ) {
    this.changeRequestsService.configuration.selectHeaderAccept(['json']);
    this.studentUnionService.configuration.selectHeaderAccept(['json']);
  }

  ngOnInit() {
    this.loading = true;
    this.changeRequestsService.getAllChangeRequests1().subscribe(
      (data) => {
        this.receiveChangeRequests(data);
      },
      (error) => (this.errorMessage = error as any)
    );
    this.subscription = fromEvent(document, 'scroll').subscribe((e) => {
      if (!isNullOrUndefined(e['path'])) {
        if (e['path'][0].body.scrollTop > 300 || e['path'][0].documentElement.scrollTop > 300) {
          document.getElementById('goTopBtn').style.display = 'block';
        } else {
          document.getElementById('goTopBtn').style.display = 'none';
        }
      }
    });
  }

  onSelect(cr: ChangeRequest) {
    const path = `/changerequest/${cr.id}`;
    this.router.navigate([path]);
  }

  /**
   * Manche Change-Requests beinhalten komplexe Objekte. Ein Beispiel hierfür ist die Kombi Raum und Gebäude.
   * Da wir jedoch bei Change-Requests nur Strings vorliegen haben, können diese nicht automatisch übernommen werden.
   * @param cr der Change-Request, um den es geht
   */
  canChangeRequestBeAppliedAutomatically(cr: ChangeRequest): boolean {
    switch (cr.type) {
      case changeRequestTypes.person:
        return cr.changes?.every((change) => personChangeRequestWhitelist.some((atr) => atr === change[0]));
      case changeRequestTypes.office:
        return cr.changes?.every((change) => officeChangeRequestWhitelist.some((atr) => atr === change[0]));
      case changeRequestTypes.studentUnion:
        return cr.changes?.every((change) => studentUnionChangeRequestWhitelist.some((atr) => atr === change[0]));
      case changeRequestTypes.general:
        return cr.changes?.every((change) => generalChangeRequestWhitelist.some((atr) => atr === change[0]));
      default:
        return false;
    }
  }

  onChangeType(type: TypeEnum | 'ALL') {
    this.loading = true;
    if (type === 'ALL') {
      this.changeRequestsService.getAllChangeRequests1().subscribe(
        (data) => {
          this.receiveChangeRequests(data);
        },
        (error) => (this.errorMessage = error as any)
      );
    } else {
      this.changeRequestsService.getChangeRequestsByType1(type).subscribe(
        (data) => {
          this.receiveChangeRequests(data);
        },
        (error) => (this.errorMessage = error as any)
      );
    }
  }

  onDelete(cr: ChangeRequest) {
    this.dialog
      .askForConfirmation({
        message: 'Möchten Sie die den Change Request wirklich löschen?',
        dialogOptions: ['CANCEL', 'CONFIRM']
      })
      .pipe(filter((result) => result == 'CONFIRM'))
      .subscribe(() => {
        this.loading = true;
        this.changeRequestsService.deleteChangeRequest(cr.id).subscribe(
          (data) => {
            const index = this.changeRequests.indexOf(cr, 0);
            if (index > -1) {
              this.changeRequests.splice(index, 1);
            }
            this.loading = false;
            this.closedTicketMessage = data;
          },
          (error) => {
            this.loading = false;
            this.errorMessage = error as any;
          }
        );
        this.router.navigateByUrl('/changerequests');
      });
  }

  onEdit(cr: ChangeRequest) {
    const path = `/changerequests/edit/${cr.id}`;
    this.router.navigate([path]);
  }

  onUpdate(cr: ChangeRequest) {
    switch (cr.type) {
      case changeRequestTypes.person:
        this.personService.getPerson(cr.objectId).subscribe((person) => {
          cr.changes.forEach((change) => {
            person[change[0]] = change[1].after;
          });
          const putPersonDto: PutPersonDto = {
            ...person,
            email: person.email || '',
            firstName: person.firstName,
            lastName: person.lastName,
            room: {
              ...person.room,
              building: null
            }
          };
          this.personService.updatePerson(cr.objectId, putPersonDto).subscribe(() => {
            this.onDelete(cr);
          });
        });
        break;
      case changeRequestTypes.office:
        this.poiService.getPoiOffice1(cr.objectId).subscribe((office) => {
          cr.changes.forEach((change) => {
            office[change[0]] = change[1].after;
          });
          const putOfficeDto: PutPoiOfficeDto = {
            ...office,
            room: {
              ...office.room,
              building: null
            }
          };
          this.poiService.updatePoiOffice1(cr.objectId, putOfficeDto).subscribe(() => {
            this.onDelete(cr);
          });
        });
        break;
      case changeRequestTypes.studentUnion:
        this.poiService.getPoiStudentUnion1(cr.objectId).subscribe((student) => {
          cr.changes.forEach((change) => {
            student[change[0]] = change[1].after;
          });
          const putStudentUnion: PutPoiStudentUnionDto = {
            ...student,
            room: {
              ...student.room,
              building: null
            }
          };
          this.poiService.updatePoiStudentUnion1(cr.objectId, putStudentUnion).subscribe(() => {
            this.onDelete(cr);
          });
        });
        break;
      case changeRequestTypes.general:
        this.poiService.getPoiGeneral1(cr.objectId).subscribe((general) => {
          cr.changes.forEach((change) => {
            general[change[0]] = change[1].after;
          });
          const putGeneral: PutPoiGeneralDto = {
            ...general
          };
          this.poiService.updatePoiGeneral1(cr.objectId, putGeneral).subscribe(() => {
            this.onDelete(cr);
          });
        });
        break;
      default:
        break;
    }
  }

  parseComment(comment: string): any[] {
    try {
      const parsed = JSON.parse(comment);
      const changes = [];
      parsed.forEach((change: any) => {
        const attributeName = Object.keys(change)[0];
        const values = change[attributeName];
        changes.push({
          name: attributeName,
          before: values.before || '(Leer)',
          after: values.after || '(Leer)'
        });
      });
      return changes;
    } catch (e) {
      console.error('Error while parsing comment:', comment, e);
      return [];
    }
  }

  toggleImage(id) {
    document.getElementById('img_' + id).classList.toggle('showImg');
    document.getElementById('expand_' + id).classList.toggle('showImg');
    document.getElementById('collapse_' + id).classList.toggle('showImg');
  }

  receiveChangeRequests(data: Array<GetChangeRequestDto>) {
    this.loading = false;
    this.changeRequests = data.map((cr) => {
      return this.identifyCr(cr);
    });
  }

  identifyCr(cr: GetChangeRequestDto): ChangeRequest {
    const parsed: ChangeRequest = cr;
    switch (cr.type) {
      case changeRequestTypes.person:
        this.personService.getAllPersons1().subscribe((data) => {
          data.forEach((person) => {
            if (person.id === cr.objectId) {
              parsed.label = person.academicDegree + ' ' + person.firstName + ' ' + person.lastName;
            }
          });
        });
        break;
      default:
        break;
    }
    return parsed;
  }

  topFunction() {
    document.body.scrollTop = 0; // for safari
    document.documentElement.scrollTop = 0; // for chrome, firefox, IE, opera
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  typeOf(value: any) {
    return typeof value;
  }

  isNullOrUndefined(value: any) {
    return isNullOrUndefined(value);
  }

  onManuelEdit(cr: ChangeRequest) {
    switch (cr.type) {
      case changeRequestTypes.person:
        // navigate to person with the id
        this.personService.getPerson(cr.objectId).subscribe((person) => {
          this.router.navigate(['/updateperson/' + cr.objectId, person]).then();
        });
        break;
      case changeRequestTypes.office:
        this.poiService.getPoiOffice1(cr.objectId).subscribe((office) => {
          this.router.navigate(['/updateoffice/' + cr.objectId, office]).then();
        });
        break;
      case changeRequestTypes.studentUnion:
        this.poiService.getPoiStudentUnion1(cr.objectId).subscribe((student) => {
          this.router.navigate(['/updatestudentunion/' + cr.objectId, student]).then();
        });
        break;
      case changeRequestTypes.general:
        this.poiService.getPoiGeneral1(cr.objectId).subscribe((general) => {
          this.router.navigate(['/updategeneral/' + cr.objectId, general]).then();
        });
        break;
      default:
        break;
    }
  }
}
