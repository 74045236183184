import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-searchform-office',
  templateUrl: './searchform-office.component.html',
  styleUrls: ['./searchform-office.component.scss'],
})
export class SearchformOfficeComponent {
  @Output()
  public search: EventEmitter<string> = new EventEmitter<string>();

  public searchInput: string = '';

  public onSearch(): void {
    this.search.emit(this.searchInput);
  }
}
