<mat-card class="person" *ngIf="person">
  <div class="actionarea">
    <button mat-icon-button color="warn" (click)="onDelete(person)">
      <fa-icon [icon]="['fas', 'trash-alt']"></fa-icon>
    </button>
    <button mat-icon-button color="warn" [routerLink]="['/updateperson', person.id]">
      <mat-icon>edit</mat-icon>
    </button>
  </div>
  <mat-card-header>
    <mat-card-title-group>
      <mat-card-title>{{ person.firstName }} {{ person.lastName }}</mat-card-title>
      <mat-card-subtitle>{{ person.academicDegree }}</mat-card-subtitle>
      <div>
        <img
          [ngClass]="{ 'mat-card-lg-image': isScreenHeightUnder700(), 'mat-card-xl-image': isScreenHeightOver700() }"
          *ngIf="person.imageUrl && person.imageUrl !== ''"
          src="{{ person.imageUrl }}"
          alt="Image not found!"
          onerror="this.onerror=null;this.src='https://www.h-ka.de/typo3temp/assets/_processed_/2/9/csm_profile_dummy_77848f19a4.png';"
        />
        <img
          [ngClass]="{ 'mat-card-lg-image': isScreenHeightUnder700(), 'mat-card-xl-image': isScreenHeightOver700() }"
          *ngIf="!person.imageUrl || person.imageUrl === ''"
          src="https://www.h-ka.de/typo3temp/assets/_processed_/2/9/csm_profile_dummy_77848f19a4.png"
          alt="Image not found!"
        />
      </div>
    </mat-card-title-group>
  </mat-card-header>
  <mat-card-content>
    <table style="border: none">
      <tr *ngIf="!isNullOrUndefined(person.email)">
        <th>Mail:</th>
        <td>{{ person.email }}</td>
      </tr>
      <tr *ngIf="!isNullOrUndefined(person.phone)">
        <th>Phone:</th>
        <td>{{ person.phone }}</td>
      </tr>
      <tr *ngIf="!isNullOrUndefined(person.consultationHour)">
        <th>Sprechzeiten:</th>
        <td>{{ person.consultationHour }}</td>
      </tr>
      <tr *ngIf="!isNullOrUndefined(person.room) && !isNullOrUndefined(person.room.building)">
        <th>Gebäude:</th>
        <td>{{ person.room.building }}</td>
      </tr>
      <tr *ngIf="!isNullOrUndefined(person.room) && !isNullOrUndefined(person.room.id)">
        <th>Raum:</th>
        <td>{{ person.room.name }}</td>
      </tr>
      <tr *ngIf="!isNullOrUndefined(person.remark)">
        <th>Bemerkung:</th>
        <td>{{ person.remark }}</td>
      </tr>
    </table>
  </mat-card-content>
  <div class="footer">
    <small>Letztes Update: {{ person.updatedAt * 1000 | date : 'dd.MM.yyyy HH:mm:ss' }}</small>
  </div>
</mat-card>
<div id="noData" *ngIf="!person">Bitte wählen Sie eine Person aus!</div>
