<div id="officeList" class="col-md-8 offset-md-2">
  <h1>Sekretariate</h1>
  <!--searchbar-->
  <div class="row">
    <app-searchform-office
      (change)="onSearch($event.toString())"
      (input)="onSearch($event.toString())"
      (search)="onSearch($event.toString())"
      class="col-12"
    ></app-searchform-office>
  </div>

  <app-loading-spinner [loading]="loading">
    <!--cards-->
    <div class="row">
      <div *ngFor="let office of displayedOffices | async" class="col-sm-6 mb-2 card-group">
        <mat-card
          class="p-0 w-100 justify-content-between"
          appearance="outlined"
          *ngIf="office"
          style="
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
            height: 100%;
            margin-bottom: 1em;
          "
        >
          <mat-card-header
            class="bg-light pt-2 mb-2 pb-2 border-bottom"
            style="width: 100%; display: flex; flex-wrap: nowrap"
          >
            <mat-card-header-text style="width: 70%">
              <h4 class="text-break">{{ office.name }}</h4>
            </mat-card-header-text>
            <div class="float-right width-30" style="display: flex; flex-wrap: nowrap; gap: 0.5em">
              <button class="btn btn-outline-primary float-right" (click)="onDelete(office.id, office.name)">
                <fa-icon [icon]="['fas', 'trash-alt']"></fa-icon>
              </button>
              <a [routerLink]="['/updateoffice', office.id]" class="btn btn-outline-primary float-right margin-right">
                <fa-icon [icon]="['fas', 'pencil-alt']"></fa-icon>
              </a>
            </div>
          </mat-card-header>
          <mat-card-content class="flex-column p-4 justify-content-between" style="width: 100%">
            <div class="col-12 office">
              <table>
                <tr>
                  <th>Gebäude:</th>
                  <td>{{ office.room?.building?.name ? office.room.building.name : '-' }}</td>
                </tr>
                <tr>
                  <th>Raum:</th>
                  <td>{{ office.room?.name ? office.room.name : '-' }}</td>
                </tr>
                <tr>
                  <th>Öffnungszeiten:</th>
                  <td>{{ office.openingHours ? office.openingHours : '-' }}</td>
                </tr>
                <tr>
                  <th>Telefon:</th>
                  <td>{{ office.phone ? office.phone : '-' }}</td>
                </tr>
                <tr>
                  <th>Email:</th>
                  <td>{{ office.email ? office.email : '-' }}</td>
                </tr>
              </table>
            </div>
          </mat-card-content>
          <mat-card-footer style="width: 90%; margin-bottom: 12px">
            <small>Letztes Update: {{ office.updatedAt * 1000 | date : 'dd.MM.yyyy HH:mm:ss' }}</small>
          </mat-card-footer>
        </mat-card>
      </div>
    </div>
  </app-loading-spinner>
</div>
