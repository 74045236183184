<div id="singleChangeRequest" class="row">
  <div *ngIf="changeRequest" class="col-6">
    <h1 id="pageHeadline">{{pageTitle}}</h1>
    <ul class="changeRequest">
      <li>
        <span><b>Change-Request ID:</b></span> <br>
        <span>{{changeRequest.id}}</span>
      </li>
      <li> <b>Typ: </b> <br> {{changeRequest.type}}</li>
      <li> <b>Kommentar: </b>  <br>{{changeRequest.comment}}</li>
      <li> <b>Hochschul Benutzer:</b> <br> {{changeRequest.hsUser}}</li>
      <li> <b>Erstellt am:</b>  <br>{{changeRequest.updatedAt}} </li>
    </ul>
    <div id="validationImage" *ngIf="changeRequest.hasImage">
      <div> <b> Validierungs Bild: </b> </div>
      <img [src]="validationImgUrl" />
    </div>
    <button id="closeCrBtn" (click)="onDelete(changeRequest)" class="btn btn-success"> Change-Request schliessen </button>
  </div>
  <div  *ngIf="person" class="col-6">
        <app-update-person [person]="person"> </app-update-person>
 </div>
  <div *ngIf="campus" class="col-6">
        <app-update-campus [campus]="campus"> </app-update-campus>
  </div>
</div>
