import { Injectable } from '@angular/core';
import { downloadFile } from 'app/shared/util/downloadFile';
import { SemesterScheduleService } from '../service/semester-schedule.service';

@Injectable()
export class ManageSemesterScheduleUsecase {

  constructor(private backendService: SemesterScheduleService) {
  }

  public async getCurrentFromBackend(): Promise<void> {
    const response = await this.backendService.getCurrentSchedule();
    const text = await new Response(response).text();
    // const textUtf8 = decodeURIComponent(escape(text));
    const blob = new Blob([text], { type: 'text/plain;charset=utf-8' });
    downloadFile(blob, 'current-semester.ics');
  }

  public async uploadCurrentToBackend(iCal): Promise<void> {
    const formData = new FormData();
    formData.append('CreateScheduleCurrentICal', iCal);
    await this.backendService.uploadCurrentSchedule(formData);
  }

  public async uploadNextToBackend(iCal): Promise<void> {
    const formData = new FormData();
    formData.append('CreateScheduleNextICal', iCal);
    return this.backendService.uploadNextSchedule(formData);
  }

  public async getNextFromBackend(): Promise<void> {
    const response = await this.backendService.getNextSchedule();
    const text = await new Response(response).text();
    // const textUtf8 = decodeURIComponent(escape(text));
    const blob = new Blob([text], { type: 'text/plain;charset=utf-8' });
    downloadFile(blob, 'next-semester.ics');
  }
}
