import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { ToastrServiceInt } from '../../shared/util/toastr.service';

@Injectable()
export class TaskPlanService {
  private readonly BACKEND_TASK_PLAN_RESOURCE: string = environment.url + '/api/calendar/ersti/tasks';

  constructor(private httpClient: HttpClient, private toastrService: ToastrServiceInt) {}

  public async getTaskPlan(): Promise<any> {
    return this.httpClient
      .get(this.BACKEND_TASK_PLAN_RESOURCE, {
        responseType: 'blob'
      })
      .toPromise();
  }

  public async uploadTaskPlan(iCal): Promise<any> {
    const token: string = localStorage.getItem('authToken');
    return this.httpClient
      .put<HttpResponse<any>>(this.BACKEND_TASK_PLAN_RESOURCE, iCal, {
        headers: {
          Authorization: 'Bearer ' + token
        }
      })
      .toPromise()
      .then(() => {
        this.toastrService.setToastrSuccess('Success', 'iCal konnte erfolgreich hochgeladen werden.');
      })
      .catch(() => {
        this.toastrService.setToastrError('Error', 'iCal konnte nicht hochgeladen werden.');
      });
  }
}
