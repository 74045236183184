import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { ChangeRequest } from '../shared/change-request';
import {
  BuildingV1Service,
  ChangeRequestsV1Service,
  GetBuildingDto,
  GetPersonDto,
  PersonsV1Service
} from '../../../_generated/hka-app-service';

@Component({
  selector: 'app-change-request',
  templateUrl: './change-request.component.html',
  styleUrls: ['./change-request.component.scss']
})
export class ChangeRequestComponent implements OnInit {
  pageTitle = 'Change-Request';
  id: number;
  changeRequest: ChangeRequest;
  person: GetPersonDto;
  campus: GetBuildingDto;
  errorMessage: any;
  validationImgUrl: string;
  closedTicketMessage: any;

  constructor(
    private changeRequestService: ChangeRequestsV1Service,
    private personService: PersonsV1Service,
    private route: ActivatedRoute,
    private router: Router,
    private poiv1Service: BuildingV1Service
  ) {
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.id = params['id'];
    });

    this.changeRequestService.getChangeRequest(this.id).subscribe(
      data => {
        this.changeRequest = data;
        this.loadValidationImage();
        this.identifyCr();
      },
      error => (this.errorMessage = error?.msg ?? error)
    );
  }

  private loadValidationImage() {
    this.validationImgUrl = environment.url + '/api/changerequests/v1/image/' + this.changeRequest.id + '.png';
  }

  onDelete(cr: ChangeRequest) {
    this.changeRequestService.deleteChangeRequest(cr.id).subscribe(
      data => {
        this.closedTicketMessage = data;
      },
      error => {
        this.errorMessage = error as any;
      }
    );
    this.router.navigateByUrl('/changerequests');
  }

  public identifyCr() {
    switch (this.changeRequest.type) {
      case 'PERSON':
        this.campus = null;
        this.personService.getPerson(this.changeRequest.objectId).subscribe(
          data => {
            this.person = data;
          },
          error => (this.errorMessage = error as any)
        );
        break;
      case 'OFFICE':
      case 'LEARNINGPLACE':
        this.person = null;
        this.poiv1Service.getBuilding(this.changeRequest.objectId).subscribe(
          data => {
            this.campus = data;
          },
          error => (this.errorMessage = error as any)
        );
        break;
      default:
    }
  }
}
