import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from './confirmation-dialog.component';
import { take } from 'rxjs/operators';
import { ConfirmationDialogInput, DialogOption } from './confirmation-dialog.model';
import { Observable } from 'rxjs';

/*
Gibt ein Observable<dialogOption> zurück anhand welcher der gedrückte Button ermittelt werden kann
Beispiel zur Nutzung:
 this.confDialogService
      .askForConfirmation({ dialogOptions: ['CANCEL', 'CONFIRM'], message: 'Test Nachricht' })
      .subscribe((result) => {
        switch (result) {
          case 'CANCEL':
            machWasOnCancel();
            break;
          case 'CONFIRM':
            machWasOnConfirm();
            break;

          default:
            break;
        }
      });
**/
@Injectable({
  providedIn: 'root',
})
export class ConfirmationDialogService {
  constructor(private dialog: MatDialog) {}

  askForConfirmation(dialogConfig: ConfirmationDialogInput): Observable<DialogOption> {
    return this.dialog
      .open(ConfirmationDialogComponent, {
        maxWidth: '450px',
        data: dialogConfig,
        disableClose: true,
      })
      .afterClosed()
      .pipe(take(1));
  }
}
