<div class="searchRow">
  <a mat-stroked-button class="btn btn-danger mb-4" role="button" (click)="onNewCourse()">
    <mat-icon>add</mat-icon> Neu
  </a>
  <mat-form-field appearance="outline" style="width: 95%">
    <mat-label>
      <mat-label>Studiengänge durchsuchen</mat-label>
    </mat-label>
    <input matInput [(ngModel)]="searchInput" placeholder="Name oder ID eintragen" (keyup)="onSearch()" />
    <button mat-icon-button color="primary" (click)="onSearch()" matSuffix>
      <mat-icon>search</mat-icon>
    </button>
  </mat-form-field>
</div>
